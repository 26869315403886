import React from "react";
import {ListGroup, Card, Container, Row, Col,Button} from 'react-bootstrap';
import './lightstyle.css';
import { useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';


type AddCount = {
    count: any,
    species: any,
    gender: any,
    comment: any, 
    sampleTotalCount: number,
}

class PreviousSingleFishSample extends React.Component<any, AddCount>{

    count: any;
    fishSampleIndex: number;
    speciesCodes: any[];
    localStorageCollection: any[];
    dataExists: boolean;
    countCollection: any[];

    sampleCount: number;
    sampleStructure: number;
    sampleWeight: number;
    currSampleSettings: any;

    constructor(props: any) {
        super(props);

        
        this.dataExists = false;
        this.speciesCodes = [];
        this.countCollection = [];
        this.sampleCount = 0;
        this.sampleStructure = 0;
        this.sampleWeight = 0;
        this.currSampleSettings = "";
        this.fishSampleIndex = JSON.parse(localStorage.getItem('editFishSampleIndex') || '{}');
        if (localStorage.getItem('editFishSampleIndex')) {
            this.count = this.fishSampleIndex;
        } else {
            this.count = 0;
        }
        this.localStorageCollection = JSON.parse(localStorage.getItem('fishSample') || '{}');
        
        if (localStorage.getItem('fishSample')) {  
            this.currSampleSettings = this.localStorageCollection[this.fishSampleIndex].settings;
            this.speciesCodes = this.localStorageCollection[this.fishSampleIndex].speciesData;
            if(this.speciesCodes.length > 0){
                this.dataExists = true;
            }
            let i = 0;
            for(i; i < this.speciesCodes.length; i++){
                let localIndex = this.count + "," + this.speciesCodes[i];
                let countsFromLocalStorage = JSON.parse(localStorage.getItem(localIndex) || '{}');
               
                this.countCollection = [...this.countCollection, {id: countsFromLocalStorage.code, 
                    totalCount: countsFromLocalStorage.totalCount, totalWeightCount: countsFromLocalStorage.totalWeightCount,
                    totalStructres: countsFromLocalStorage.totalStructuresCount}];
            }
        }

        this.state = {
            count: '',
            species: '',
            gender: 'n/a',
            comment: '',
            sampleTotalCount: this.sampleCount,
        }

    }  


    render() {
        return( 
            <div className="genStatsWrapper">
            <Card className="cardDivider">
            <Container fluid>
            <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "15px" }}></Row>
                <Row>
                   <Col>
                   <BackButton/>
                   </Col>
                    <Col lg="auto" style={{ paddingBottom: "15px" }}>
                    <ListGroup.Item className="listPrevSamplesTitle">
                    <h1>Previous Fish Sample</h1>
                    </ListGroup.Item>                       
                    </Col>
                    <Col></Col>
                </Row>
            <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "10px" }}></Row>
                {/* <Row> */}
                <>
                 <ListGroup horizontal>
                     <ListGroup.Item action className="editPrevSamplesMainTitle">
                         {this.currSampleSettings.date.slice(5, 7) + "/" + this.currSampleSettings.date.slice(8, 10) + "/" + this.currSampleSettings.date.slice(0, 4)}
                     </ListGroup.Item>
                     <ListGroup.Item action className="editPrevSamplesMainTitle">
                         {this.currSampleSettings.method}
                     </ListGroup.Item>
                     <ListGroup.Item action  className="editPrevSamplesMainTitle">
                         {this.currSampleSettings.waterbodyCode}
                     </ListGroup.Item>
                     <ListGroup.Item action className="editPrevSamplesMainTitle">
                         {this.currSampleSettings.station}
                     </ListGroup.Item>
                 </ListGroup>
        </>
                <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "5px" }}></Row>
                <Card className="editPrevCardDividerList">   
                <ListGroup style={{ textAlign: "center", verticalAlign: "middle"}}  horizontal className="editPrevSampleFixHeightTitle">
                            <ListGroup.Item className="editPrevSamplesListBigTitle">
                                Species
                            </ListGroup.Item>
                            <ListGroup.Item className="editPrevSamplesListTitle">
                                Count
                            </ListGroup.Item>
                            <ListGroup.Item action className="editPrevSamplesListTitle">
                                Length Class
                            </ListGroup.Item>
                            <ListGroup.Item action className="editPrevSamplesListTitle">
                                Length Weight
                            </ListGroup.Item>
                        </ListGroup>
                       
              
             
                <div>  
                    {this.dataExists ?                
                            <ul>
                                {!(!Array.isArray(this.countCollection) || !this.countCollection)
                                    &&
                                    this.countCollection.map(
                                        (count) =>
                                            <li key={count.id}>
                                                <EditLengthWeight count={count} index={this.count}/>
                                                {/* <ListGroup horizontal >
                                                    <ListGroup.Item className="editPrevSamplesListBig">                   
                                                        {count.id}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="editPrevSamplesList">
                                                        {count.totalCount}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item action as="button" className="editPrevSamplesListButton">
                                                        Edit
                                                    </ListGroup.Item>
                                                    <ListGroup.Item action as="button" className="editPrevSamplesListButton"> 
                                                        Edit
                                                    </ListGroup.Item>
                                                </ListGroup> */}
                                            </li>
                                    )
                                }
                            </ul> 
                            : 
                            <ListGroup horizontal>
                                  <ListGroup.Item className="listPrevSamples">
                                    There are currently no samples.
                               </ListGroup.Item>
                            </ListGroup> }

                        </div>
                        </Card>
                    
                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "5px" }}></Row>
                    
                </Container>

          

            {/* <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "15px" }}></Row> */}
        </Card>
               
    </div>

            );
        }
}

export function EditLengthWeight(props: any){
    let navigate = useNavigate();
    let count = props.count;
    let sampleFishCode = props.index + "," + count.id;
   // let editIndex = props.index;
    function handleLengthWeightClick() {  
        localStorage.setItem("editFishCode", JSON.stringify(sampleFishCode));     
        navigate("/editPrevLengthWeight");
      }

      function handleLengthClick() {
         localStorage.setItem("editFishCode", JSON.stringify(sampleFishCode));
         navigate("/editLengthsAndAddFish");
       }
     return (
         <ListGroup horizontal >
             <ListGroup.Item className="editPrevSamplesListBig">
                 {count.id}
             </ListGroup.Item>
             <ListGroup.Item className="editPrevSamplesList">
                 {count.totalCount}
             </ListGroup.Item>
             <ListGroup.Item action as="button" onClick={handleLengthClick} className="editPrevSamplesListButton">
                 Edit
             </ListGroup.Item>
             <ListGroup.Item action as="button" onClick={handleLengthWeightClick} className="editPrevSamplesListButton">
                 Edit
             </ListGroup.Item>
         </ListGroup>
    );
};

export function BackButton(props: any){
    let navigate = useNavigate();
    function handleClick() {
        navigate("/previousFishSamples");
      }
     return (
    <>
    <Button variant="secondary" className="backButton" onClick={() => handleClick()}>
        Back
    </Button>
    </>
    );
};

export default PreviousSingleFishSample;