import React from "react";
import './dataEntryPage.css';
import { Nav, Table, Modal, CloseButton, Card, Popover, OverlayTrigger, Container, FloatingLabel, Form, Row, Col, Tabs, Tab, ButtonGroup, Button} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './lightstyle.css';
import { useRef, useState } from "react";
import { Html5QrcodeScanner} from "html5-qrcode";
import Select from 'react-select';
import { GetSpeciesCollection } from './localStorageAccess';
import { isEmptyArray } from "formik";

class Length {
    count: number = 0;
    constructor() {
        this.count = 0;
    }
}

//for weight/struct count per waterbody code

class LengthWeight {
    w: number = 0;
    s: number = 0;
}

class FishCount {
    count: number = 0;
    gender: string = "";
    comment: string = "";
    constructor() {
        this.count = 0;
    }
}

class Species {
    index: any;
    setParentEditing: any;
    waterbodyCode: any;
    weightStructCounts: Array<LengthWeight> = [];
    date: any;
    code: any;
    structLimit: number = 5;
    weightLimit: number = 10;
    wrmin: number = 70;
    wrmax: number = 150;
    weights: any[] = [];
    lengths: Array<Length> = [];
    fishcounts: Array<FishCount> = [];
    constructor() {
        let i = 0;
        for (i; i <= 99; i++) {
            this.lengths[i] = new Length();
        }
    }
}

class Lengths {
    lengths: Array<Length> = [];
    constructor() {
        let i = 0;
        for (i; i <= 99; i++) {
            this.lengths[i] = {
                count: 0,
                //weightCount: 0, 
                // ageStructureCount: 0, 
                // weights: []
            };
        }
    }
}

type CurrentSelected = {
    activeTab: any,
    editing: boolean,
    selectedSpeciesCode: any,
    length: Length[],
    speciesCode: any[],
    species: any[]
}

const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        background: "white",
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        borderColor: state.isFocused ? "blue" : "black",
        alignContent: "right",
        margin: "0px 30px 0px 0px",
        length: "100%",
        width: "100%",
        height: "70px",
        color: "white !important",
        fontSize: "25px",
        testDecorationColor: "white",
        boxShadow: state.isFocused ? null : null,

    }),
    value: (base: any) => ({
        ...base,
        color: "white"
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "black",
        fontWeight: 400
    }),
    menu: (base: any, state: any) => ({
        ...base,
        color: state.isSelected ? "white" : "black",
        fontSize: "18px",
        borderRadius: 0,
        marginTop: 0,
        width: "100%",
        margin: "0px 30px 0px 0px",
    }),
    menuList: (base: any, state: any) => ({
        ...base,
        color: state.isSelectd ? "white" : "black",
        margin: "0px 30px",
    })
};

const miscStyle = {
    control: (base: any, state: any) => ({
        ...base,
        background: "white",
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        borderColor: state.isFocused ? "blue" : "black",
        // margin: "0px 30px",
        length: "100%",
        width: "100%",
        height: "100%",
        color: "white !important",
        fontSize: "25px",
        testDecorationColor: "white",
        boxShadow: state.isFocused ? null : null,

    }),
    value: (base: any) => ({
        ...base,
        color: "white"
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "black",
        // fontWeight: 400
    }),
};

class DataEntry extends React.Component<any, CurrentSelected> {


    speciesCollection: Species[] = []; //collection of all species and all counts
    lengths: Length[] = []; //never update this, keep initial values
    fishSampleIndex: any = '';
    count: any;
    fishSampleCollection: any[] = []; //list of species codes for tabs
    localStorageTabs: any[] = []; //store tabs (use so dont lose state on refresh/back)
    localStorageData: any;
    reference: any[] = [];
    editFishSampleIndex: any;
    waterbodyCode: any;
    date: any;
    weightStructCounts: LengthWeight[] = [];
    localStorageSettings: any = '';
    ageStructureLimit: number = 5; //default value
    weightLimit: number = 10; //default value
    wrmax : number = 150; //default value
    wrmin : number = 70; //default value
    theme: any = "light";
    fishcounts: FishCount[] = [];

    constructor(props: any) {
        super(props);

        this.setEditingParent = this.setEditingParent.bind(this);
        this.setActiveTab = this.setActiveTab.bind(this);
        this.fishSampleIndex = JSON.parse(localStorage.getItem('fishSampleIndex') || '{}');
        this.editFishSampleIndex = JSON.parse(localStorage.getItem('editFishSampleIndex') || '{}');
        this.localStorageSettings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');
        if (localStorage.getItem('tabletSettings')) {
            this.ageStructureLimit = this.localStorageSettings.ageStructureLimit;
            this.weightLimit = this.localStorageSettings.weightLimit;
            this.wrmax = this.localStorageSettings.wrmax;
            this.wrmin = this.localStorageSettings.wrmin;
            this.theme = this.localStorageSettings.theme;
        }
        if (localStorage.getItem('editFishSampleIndex')) { //we are currently editing
            this.count = this.editFishSampleIndex;
            this.fishSampleIndex = this.editFishSampleIndex;
        }
        else if (localStorage.getItem('fishSampleIndex')) {
            this.count = this.fishSampleIndex; //if an index exists
        }
        this.waterbodyCode = '';
        this.date = '';
        this.speciesCollection = [];
        let i = 0;
        for (i; i <= 99; i++) {
            this.weightStructCounts[i] = { w: 0, s: 0 };
            this.lengths[i] = {
                count: 0
            };
        }
        this.state = {
            activeTab: '',
            editing: false,
            selectedSpeciesCode: '',
            length: [], //list of counts for the fish that is currently selected
            speciesCode: [], //list of species code 
            species: [] //list of species tabs 
        };
        this.localStorageData = JSON.parse(localStorage.getItem('fishSample') || '{}');
        if (localStorage.getItem('fishSample')) {
            this.waterbodyCode = this.localStorageData[this.fishSampleIndex].settings.waterbodyCode;
            this.fishSampleCollection = this.localStorageData[this.fishSampleIndex].speciesData;
            this.date = this.localStorageData[this.fishSampleIndex].settings.date;
        } 
        //disabled={this.state.editing}
        this.state = {
            activeTab: this.fishSampleCollection.length > 0 ? this.fishSampleCollection[this.fishSampleCollection.length-1] : 'misc',
            editing: false,
            selectedSpeciesCode: '',
            length: this.lengths, //list of counts for the fish that is currently selected
            speciesCode: this.fishSampleCollection, //list of species code 
            species: this.localStorageTabs //list of species tabs 
        };

    }

    setEditingParent = (value: boolean) => {
        this.setState({ editing: value });
    }

    setActiveTab = (value: any) => {
        this.setState({ activeTab: value });
    }

    addFish = (eventKey: any) => {
        if (!this.state.editing) { //only change tabs if not editing
            this.setActiveTab(eventKey);
        }
        if (!this.state.editing && eventKey === "+") {
            NavToAddFish();
        }
    };

    render() {
        return (
            <div className="dataEntryBackground" style={{height: "100%" }}>
                <div className="wrapper" id="fullheight" data-theme={this.theme}>
                    {/* <Container fluid className="dataContainer"> */}
                        <Row className="topStack">
                        {/* <Stack direction="horizontal" gap={3} className="topStack"> */}
                        {/* <Navbar> */}
                            {/* <Container fluid> */}
                            <ButtonGroup className="back-gen-group">
                                <NewButton disabled={this.state.editing} path="/editSampleSettings" title="Back" currentState={this.state} />
                                <NewButton disabled={this.state.editing} path="/generalStats" title="Summary" />
                            </ButtonGroup>
                            <Tab.Container onSelect={this.addFish} 
                                activeKey={this.state.activeTab} id="speciesTabs">
                            <div className="fish-scroll">
                            <Nav variant="tabs">
                                {
                                !(!Array.isArray(this.fishSampleCollection) || !this.fishSampleCollection.length) ?   
                                this.fishSampleCollection.map(
                                    (s, index) => 
                                    <Nav.Item key={index}>
                                        <Nav.Link eventKey={s.toString()}>{s.toString()}</Nav.Link>
                                    </Nav.Item>
                                )
                                : <></>
                                }
                            </Nav>
                            <Nav variant="tabs">                                
                                <Nav.Item>
                                <Nav.Link eventKey={"misc"}>{"Misc"}</Nav.Link>  
                                </Nav.Item>                             
                            </Nav>

                            <Tab.Content>

                                    {!(!Array.isArray(this.fishSampleCollection) || !this.fishSampleCollection.length) ? 
                            this.fishSampleCollection.map(
                                    (s, index) => 
                                    <Tab.Pane key={index} eventKey={s.toString()} title={s.toString()}>
                                      
                                        <AllFishCounts weights={[]} weightStructCounts={this.weightStructCounts} date={this.date}
                                            structLimit={this.ageStructureLimit} weightLimit={this.weightLimit}
                                            wrmin={this.wrmin} wrmax={this.wrmax}
                                            waterbodyCode={this.waterbodyCode} index={this.fishSampleIndex}
                                            setParentEditing={this.setEditingParent}
                                            key={index} lengths={this.lengths} fishcounts={this.fishcounts} code={s.toString()} />
                                    </Tab.Pane>
                                )
                                : <></>
                                }
                            <Tab.Pane eventKey="misc" title="misc">
                                        <AllFishCounts weights={[]} weightStructCounts={this.weightStructCounts} date={this.date}
                                            structLimit={this.ageStructureLimit} weightLimit={this.weightLimit}
                                            wrmin={this.wrmin} wrmax={this.wrmax}
                                            waterbodyCode={this.waterbodyCode} index={this.fishSampleIndex} setParentEditing={this.setEditingParent}
                                            key="misc" lengths={this.lengths} fishcounts={this.fishcounts} code={"misc"} />
                            </Tab.Pane>
                            </Tab.Content>
                            <AddFishButton disabled={this.state.editing} path="/fishSpeciesSelection" title="+" index={this.fishSampleIndex} />
                            </div>
                            </Tab.Container>
                        </Row>
                </div>
             </div>
        )
    }
}

function NavToAddFish() {
    let navigate = useNavigate();

    return (
        <Tabs variant="tabs" onSelect={() => navigate("/fishSpeciesSelection")}>
            <Tab eventKey="+" title="+" onClick={() => navigate("/fishSpeciesSelection")} />
        </Tabs>
    );

};
function NewButton(props: any) {
    let navigate = useNavigate();
    return (
        <Button disabled={props.disabled} className="back-general-button" onClick={() => navigate(props.path)}>
            {props.title}
        </Button>
    );
};
function AddFishButton(props: any) {
    let navigate = useNavigate();
    return (
        <Button disabled={props.disabled} className="add-fish-button" onClick={() => navigate(props.path)}>
            {props.title}
        </Button>
    );
};

type Tags = {
    id: any,
    type: any,
    comment: any,
    initial: any
}

type Struct = {
    id: any,
    purpose: any,
    type: any,
    comment: any,
    qr: any,
}


function AllFishCounts(props: Species) {

    const ref = useRef(null);
    const speciesInfoCollection = GetSpeciesCollection();
    let tagsCollection: Tags[] = []
    let structCollection: Struct[] = []
    //using weights array to keep track of exact length, weight, gender, keywords, comments, etc
    // so each element in weights is a single fish. values are n/a if there aren't any
    //there's a weight array for each length group 
    const [lastSavedFishLen, setLastSavedFishLen] = useState("");
    const [lastSavedFishWeight, setLastSavedFishWeight] = useState("");
    const [editFish, setEditFish] = useState(false);
    const [editFishId, setEditFishId] = useState("n/a");
    const [editFishLength, setEditFishLength] = useState("n/a");
    const [editFishWeight, setEditFishWeight] = useState("n/a");
    const [editFishStruct, setEditFishStruct] = useState([] as any);
    const [deleteFish, setDeleteFish] = useState('' as any);
    const [singleFishLength, setSingleFishLength] = useState("n/a");
    const [singleFishWeight, setSingleFishWeight] = useState("n/a");
    const [SingleFishLengthValid, setSingleFishLengthValid] = useState("n/a");
    const [singleFishComment, setSingleFishComment] = useState("n/a");
    const [singleFishKeyWords, setSingleFishKeyWords] = useState("n/a");
    const [singleFishGender, setSingleFishGender] = useState("n/a");
    const [singleFishTags, setSingleFishTags] = useState(tagsCollection);
    const [singleFishTagsId, setSingleFishTagsId] = useState("n/a");
    const [singleFishTagsType, setSingleFishTagsType] = useState("n/a");
    const [singleFishTagsInitial, setSingleFishTagsInitial] = useState(true);
    const [singleFishTagsComment, setSingleFishTagsComment] = useState("n/a");
    const [singleFishAgeStructure, setSingleFishAgeStructure] = useState("n/a");
    const [singleFishAgeStructureType, setSingleFishAgeStructureType] = useState("Scale");
    const [singleFishStructureComment, setSingleFishStructureComment] = useState("n/a");
    const [singleFishAgeStructureCollection, setSingleFishAgeStructureCollection] = useState(structCollection);
    const [singleFishAgeStructurePurpose, setSingleFishAgeStructurePurpose] = useState("Age Structure");
    const [speciesCode] = useState(props.code);
    const [miscSpecies, setMiscSpecies] = useState('n/a');
    const code = speciesCode.substr(speciesCode.indexOf('-') + 1, speciesCode.length); //species name without the numbers
    const fishSampleIndex = props.index;
    const weightLimit = props.weightLimit;
    const structLimit = props.structLimit;
    const wrmin = props.wrmin;
    const wrmax = props.wrmax;

    const id = fishSampleIndex;

    //year-month-day,waterbodyCode,species
    //this is for the weight and structure count corresponding to the waterbody code. the localstorage index
    const weightStructCount = String(props.date).substring(0, 10) + "," + props.waterbodyCode + "," + speciesCode;

    // const[weightStructCountCollection, setWeightStructCountCollection] = useState([] as any);

    const species: string[] = [];
    if (speciesCode === "misc") {
        const speciesObj = JSON.parse(localStorage.getItem('SpeciesNames') || '{}');
        Object.keys(speciesObj).map((key) => { species.push(key + "-" + speciesObj[key]) });
    }


    ///warnings and validations///

    const [showWeightLarger, setShowWeightLarger] = useState(false);
    const [showLengthValidWarning, setShowLengthValidWarning] = useState(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [showRangeWarning, setShowRangeWarning] = useState(false);
    const [currWr, setCurrWr] = useState(0);

    const localStorageName: string = id.toString() + "," + speciesCode.toString();
    const fishSample = JSON.parse(localStorage.getItem(localStorageName) || '{}');

    let lengthCollection = new Lengths().lengths;
    let wsCollection = props.weightStructCounts;
    let totC = 0;
    let totWeight = 0;
    let totStruct = 0;
    let totFem = 0;
    let totMale = 0;
    let totImmature = 0;
    let totNA = 0;
    let weightFreqCollection: any[] = [];
    let fishCountsCollection: any[] = [];

    //let totAgeStructureCollection : Struct[] = []; // list purpose and comment
    if (localStorage.getItem(localStorageName)) {

        lengthCollection = fishSample.lengths;
        // totAgeStructureCollection = fishSample.ageStructCollection;
        totC = Number(fishSample.totalCount);
        totWeight = Number(fishSample.totalWeightCount);
        totStruct = Number(fishSample.totalStructuresCount);
        totFem = Number(fishSample.totalFemaleCount);
        totMale = Number(fishSample.totalMaleCount);
        totImmature = Number(fishSample.totalImmatureCount);
        totNA = Number(fishSample.totalNACount);
        weightFreqCollection = fishSample.weights;
        fishCountsCollection = fishSample.counts;
    }

    if (localStorage.getItem(weightStructCount)) {
        wsCollection = JSON.parse(localStorage.getItem(weightStructCount) || '{}');
    }
    //this is the collection of weight and structure counts corresponding to the waterbody code
    const [weightStructCountCollection, setWeightStructCountCollection] = useState(wsCollection);
    const [totalCount, setTotalCount] = useState(totC);
    const [totalWeightCount, setTotalWeightCount] = useState(totWeight);
    const [totalStructuresCount, setTotalStructuresCount] = useState(totStruct);
    const [totalFemaleCount, setTotalFemaleCount] = useState(totFem);
    const [totalMaleCount, setTotalMaleCount] = useState(totMale);
    const [totalImmatureCount, setTotalImmatureCount] = useState(totImmature);
    const [totalNACount, setTotalNACount] = useState(totNA);
    //hold length/freq data for species
    const [weights, setWeights] = useState(weightFreqCollection);
    const [length, setLength] = useState(lengthCollection);
    const [fishcounts, setFishCounts] = useState(fishCountsCollection);

    function incrementCount2(counter: any, value: number) {

        let newValue = value + 1;
        const newLengths = [...length];
        let newCount = totalCount + 1;
        // const lengthsToUpdate = newLengths[counter];
        newLengths[counter].count = newValue;

        setLength(newLengths);
        setTotalCount(newCount);

        const dataString = {
            code: speciesCode,
            totalCount: newCount,
            totalWeightCount: totalWeightCount,
            totalStructuresCount: totalStructuresCount,
            totalFemaleCount: totalFemaleCount,
            totalNACount: totalNACount,
            totalMaleCount: totalMaleCount,
            totalImmatureCount: totalImmatureCount,
            lengths: newLengths,
            weights: weights,
            counts: fishcounts
        }

        localStorage.setItem(localStorageName, JSON.stringify(dataString));
    };

    function decrementCount2(counter: any, value: number) {
        let newValue = value;
        if (value !== 0) {
            newValue -= 1;
        }
        const newLengths = [...length];
        // const lengthsToUpdate = newLengths[counter];
        newLengths[counter].count = newValue;
        let newCount = totalCount - 1;

        setLength(newLengths);
        setTotalCount(newCount);
        const dataString = {
            code: speciesCode, totalCount: newCount,
            totalWeightCount: totalWeightCount,
            totalStructuresCount: totalStructuresCount,
            totalFemaleCount: totalFemaleCount,
            totalNACount: totalNACount,
            totalMaleCount: totalMaleCount,
            totalImmatureCount: totalImmatureCount,
            lengths: newLengths,
            weights: weights,
            counts: fishcounts
        }
        localStorage.setItem(localStorageName, JSON.stringify(dataString));
    };

    function setBackground2(counter: number) {
            return 'counterGroup';
    }
    function setPlusHighlight(counter: number) {
        if (weightStructCountCollection[counter].w >= weightLimit) {
            return 'dataplusOrange';
        } else {
            return 'dataplus';
        }
    }
    function setMinusHighlight(counter: number) {
        if (weightStructCountCollection[counter].s >= structLimit) {
            return 'dataminusBlue';
        } else {
            return 'dataminus';
        }
    }

    //function setPlusHighlight(counter: number) {
    //    if (weightStructCountCollection[counter].w >= weightLimit) {
    //        return 'dataplusOrange';
    //    } else if (weightStructCountCollection[counter].s >= structLimit) {
    //        return 'dataplusBlue';
    //    } else {
    //        return 'dataplus';
    //    }
    //    // const buttonBgState = counter >= 3 ? counter >= 5 ? 'dataplusOrange' : 'dataplusBlue' : 'dataplus';
    //    // return buttonBgState;
    //}

    //function setMinusHighlight(counter: number) {
    //    if (weightStructCountCollection[counter].w >= weightLimit) {
    //        return 'dataminusOrange';
    //    } else if (weightStructCountCollection[counter].s >= structLimit) {
    //        return 'dataminusBlue';
    //    } else {
    //        return 'dataminus';
    //    }
    //    //  const buttonBgState = counter >= 3 ? counter >= 5 ? 'dataminusOrange' : 'dataminusBlue' : 'dataminus';
    //    //  return buttonBgState;
    //}

    function onAddButton() {
        if (singleFishTagsId !== 'n/a' || singleFishTagsType !== 'n/a' || singleFishTagsComment !== 'n/a') {
            //const i = tagsCollection.findIndex((obj => obj.id === singleFishTagsId));
            tagsCollection = singleFishTags;

            tagsCollection.push({
                    id: singleFishTagsId, type: singleFishTagsType,
                    comment: singleFishTagsComment, initial: singleFishTagsInitial,
            });

            setSingleFishTagsId('n/a')
            setSingleFishTagsType('n/a')
            setSingleFishTagsComment('n/a')
            setSingleFishTagsInitial(true)
        }

        setSingleFishTags(tagsCollection);
    }
    function onDeleteButton(num: any) {
        const newList = singleFishTags.filter((item, index) => index !== num);
        tagsCollection = newList
        setSingleFishTags(tagsCollection);
    }
    function onChangeTagType(e: any) {
        setSingleFishTagsType(e.value.toString())
    }
    /////////////////////
    //CHECK VALIDATIONS//
    /////////////////////

    function validate() {
        let maxLength = 0;
        let wsIntercept = 0;
        let wsSlope = 0;

        if(speciesCode === 'misc'){
            if (singleFishLength !== "n/a") {
                // let speciesInfoCollection = GetSpeciesCollection();
                 let length = speciesInfoCollection.length;
                 const scode = miscSpecies.split('-');
                 for (let i = 0; i < length; i++) {
                     if (speciesInfoCollection[i].SpeciesCode.toString() === scode[0]) {
                         maxLength = speciesInfoCollection[i].MaxLength;
                         wsIntercept = speciesInfoCollection[i].WsIntercept;
                         wsSlope = speciesInfoCollection[i].WsSlope;
                     }
                 }
             }
        } else {
            if (singleFishLength !== "n/a") {
                // let speciesInfoCollection = GetSpeciesCollection();
                 let length = speciesInfoCollection.length;
                 const scode = speciesCode.split('-');
                 for (let i = 0; i < length; i++) {
                     if (speciesInfoCollection[i].SpeciesCode.toString() === scode[0]) {
                         maxLength = speciesInfoCollection[i].MaxLength;
                         wsIntercept = speciesInfoCollection[i].WsIntercept;
                         wsSlope = speciesInfoCollection[i].WsSlope;
                     }
                 }
             }
        }

        let valid = true;
        if (singleFishLength !== "n/a") {
            if (maxLength !== 0) {
                if (Number(singleFishLength) > maxLength) {
                    valid = false;
                    setSingleFishLengthValid(maxLength.toString());
                    setShowLengthValidWarning(true);
                }

            } 
            
            if (singleFishWeight !== "n/a" && wsSlope !== 0 && wsIntercept !== 0) {
                let r = (wsIntercept + (wsSlope * Math.log10(Number(singleFishLength))));
                let rr = Math.pow(10,r);
                let wr = ((Number(singleFishWeight) / rr) * 100);
                if (wr < wrmin || wr > wrmax) {
                    valid = false;
                    setCurrWr(wr);
                    setShowRangeWarning(true);
                } 
            } 
            
            if(valid) { //passed all tests, submit
                handleSubmit();
            }
        }

    }
    function handleSubmit() {
        if (speciesCode !== "misc") {
            if (singleFishLength !== "n/a") {
                //get length group
                const lengthGroup = Math.floor(Number(singleFishLength) / 10); //this is the index

                const newLengths = [...length];
                const newFishCounts = fishCountsCollection;
               // const lengthsToUpdate = newLengths[lengthGroup];

                let newWeights = weights;
                let newWeightStructCount = weightStructCountCollection;
                let newFemale = totalFemaleCount
                let newMale = totalMaleCount;
                let newImmature = totalImmatureCount;
                let newNA = totalNACount;
                //if (singleFishGender === "Female") {
                //    setTotalFemaleCount(totalFemaleCount + 1);
                //    newFemale++;
                //} else if (singleFishGender === "Male") {
                //    setTotalMaleCount(totalMaleCount + 1);
                //    newMale++;
                //} else if (singleFishGender === "Immature") {
                //    setTotalImmatureCount(totalImmatureCount + 1);
                //    newImmature++;
                //} else {
                //    setTotalNACount(totalNACount + 1);
                //    newNA++;
                //}
                let today = new Date();
                //add weight to list, this list stores info about single fish entries saved after hitting "save fish" button

                if (editFish === true) { //delete the prev entry for the fish we are editing
                    const editLength = Math.floor(Number(editFishLength) / 10); //this is the index

                    newWeights = newWeights.filter((item) => item.id !== editFishId);
                    if (editFishWeight !== 'n/a' && editLength < 100) {

                        newWeightStructCount[editLength].w -= 1;
                    }
                    if (editFishStruct.length > 0 && editLength < 100) {
                        newWeightStructCount[editLength].s -= 1;
                    }
                }

                let newStructCount = totalStructuresCount;
                if (singleFishAgeStructureCollection.length > 0) {
                    newStructCount += 1;
                    if (lengthGroup < 100) {
                        newWeightStructCount[lengthGroup].s += 1;
                    }
                }

                let newCount = totalCount + 1;
                let newWeightCount = totalWeightCount;

                if (singleFishWeight !== "n/a") {
                    setLastSavedFishWeight(singleFishWeight);
                    newWeightCount += 1;
                    if (lengthGroup < 100) {
                        newWeightStructCount[lengthGroup].w += 1;
                    }
                }

                setLastSavedFishLen(singleFishLength);

                newWeights = [
                    {
                        id: today, length: singleFishLength, weight: singleFishWeight, gender: singleFishGender,
                        comment: singleFishComment, keywords: singleFishKeyWords, tags: singleFishTags,
                        structures: singleFishAgeStructureCollection
                    }, ...newWeights];

                //update state and localstorage

                setLength(newLengths);
                setWeights(newWeights);
                setFishCounts(newFishCounts);
                setTotalCount(newCount);
                setTotalWeightCount(newWeightCount);
                setTotalStructuresCount(newStructCount);

                const dataString = {
                    code: speciesCode, totalCount: newCount,
                    totalWeightCount: newWeightCount,
                    totalStructuresCount: newStructCount,
                    totalFemaleCount: newFemale,
                    totalNACount: newNA,
                    totalMaleCount: newMale,
                    totalImmatureCount: newImmature,
                    lengths: newLengths,
                    weights: newWeights,
                    counts: fishcounts
                }
                //123
                localStorage.setItem(localStorageName, JSON.stringify(dataString));
                localStorage.setItem(weightStructCount, JSON.stringify(newWeightStructCount));
                structCollection = [];
                tagsCollection = [];
                setSingleFishTags(tagsCollection);
                setSingleFishAgeStructureCollection(structCollection);
                setWeightStructCountCollection(newWeightStructCount);
                setSingleFishLength('n/a');
                setSingleFishLengthValid('n/a');
                setSingleFishWeight('n/a');
                setSingleFishComment('n/a');
                setSingleFishKeyWords('n/a');
                setSingleFishGender('n/a');
                setSingleFishTagsId('n/a');
                setSingleFishTagsType('n/a');
                setSingleFishTagsComment('n/a');
                setSingleFishAgeStructure('n/a');
                //reset the edit fish
                setEditFish(false);
                setEditFishLength("n/a");

                props.setParentEditing(false);
                setEditFishId("n/a");
            }
        } else{ //misc fish
            let today = new Date();
            let newWeights = weights;
            //add weight to list, this list stores info about single fish entries saved after hitting "save fish" button

            if (editFish === true) { //delete the prev entry for the fish we are editing
              //  const editLength = Math.floor(Number(editFishLength) / 10); //this is the index
                newWeights = newWeights.filter((item) => item.id !== editFishId);           
            }
            newWeights = [
                {
                    id: today, species: miscSpecies, length: singleFishLength, weight: singleFishWeight, gender: singleFishGender, comment: singleFishComment,
                    keywords: singleFishKeyWords, tags: singleFishTags, structures: singleFishAgeStructureCollection
                }, ...newWeights];

            setWeights(newWeights);
            setFishCounts(fishcounts);
            const dataString = {

                weights: newWeights
            }
            localStorage.setItem(localStorageName, JSON.stringify(dataString));
            structCollection = [];
            tagsCollection = [];
            setSingleFishTags(tagsCollection);
            setSingleFishAgeStructureCollection(structCollection);
            //setMiscSpecies('n/a');
            setSingleFishLength('n/a');
            setSingleFishLengthValid('n/a');
            setSingleFishWeight('n/a');
            setSingleFishComment('n/a');
            setSingleFishKeyWords('n/a');
            setSingleFishGender('n/a');
            setSingleFishTagsId('n/a');
            setSingleFishTagsType('n/a');
            setSingleFishTagsComment('n/a');
            setSingleFishAgeStructure('n/a');
            //reset the edit fish
            setEditFish(false);
            setEditFishLength("n/a");

            props.setParentEditing(false);
            setEditFishId("n/a");
        }
    }
    function LengthValidationWarning(props: any) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Data Validation Warning
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Length Exceeds Max Length Validation</h4>
                    <p>
                        Length Entered: {singleFishLength} (g)
                    </p>
                    <p>
                        Max Length: {SingleFishLengthValid} (mm)
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ textAlign: "center", alignSelf: "left" }} className="validationButtonLeft" onClick={props.onHide}>Edit</Button>
                    <Button className="validationButton" onClick={() => saveFromRangeWarning()}>Save Fish</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    function RangeWarning(props: any) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Data Validation Warning
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="grey-background"> 
                    <h4>Weight Evaluation Out of Range</h4>
                    <p>
                        Weight: {singleFishWeight} (g)
                    </p>
                    <p>
                        Length: {singleFishLength} (mm)
                    </p>
                    <p>
                        WrMax: {wrmax} 
                    </p>
                    <p>
                      WrMin: {wrmin}
                    </p>
                    <p>
                        Wr value for current fish: {currWr} 
                    </p>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ textAlign: "center", alignSelf: "left" }} className="validationButtonLeft" onClick={props.onHide}>Edit</Button>
                    <Button variant="secondary" className="validationButton" onClick={() => saveFromRangeWarning()}>Save Fish</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    function DeleteWarning(props: any) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Warning: Are you sure you want to delete this fish?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Fish Details</h4>
                    <p>
                        Species: {deleteFish.species}
                    </p>
                    <p>
                        Weight: {deleteFish.weight} (g)
                    </p>
                    <p>
                        Length: {deleteFish.length} (mm)
                    </p>
                    <p>
                        Comment: {deleteFish.comment}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ textAlign: "center", alignSelf: "left" }} className="validationButtonLeft" onClick={props.onHide}>Back</Button>
                    <Button variant="secondary" className="validationButton" onClick={() => onHitDelete()}>Delete</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    let html5QrCodeScanner: Html5QrcodeScanner;
        const onScanSuccess = (decodedText: any, decodedResult: any) => {
            /* handle success */
            setSingleFishAgeStructure(decodedText);
            html5QrCodeScanner.clear();
        };
        const onScanFailure = (decodedText: any, decodedResult: any) => {
            /* handle Error */
            // console.log(`No Qr Code Scanned`);
        };
        function startQrCodeScan(){
            html5QrCodeScanner = new Html5QrcodeScanner(
                "reader", 
                { fps: 10 , qrbox: {width: 350, height: 350}, rememberLastUsedCamera: true},
                /* verbose= */ false);
            html5QrCodeScanner.render(onScanSuccess, onScanFailure);
        }
        function stopQRCodeScan(){
            if(html5QrCodeScanner !== undefined && html5QrCodeScanner.getState() === 2){
                html5QrCodeScanner.clear();
            }
            
        }
          
    function onAddStruct() {

     // const i = structCollection.findIndex((obj => obj.id === singleFishTagsId));
      if(singleFishAgeStructure !== 'n/a' && singleFishAgeStructure !== ''){
        
        let qrCode = singleFishAgeStructure.replace(/(\r\n|\n|\r)/gm, ""); //get rid of endline characters 
        structCollection = singleFishAgeStructureCollection;
        const index = String(structCollection.length + 1);
        structCollection.push({
                id: index, purpose: singleFishAgeStructurePurpose, type: singleFishAgeStructureType,
                comment: singleFishStructureComment, qr: qrCode
            });
            setSingleFishAgeStructure('n/a'); //QR Codes
            setSingleFishStructureComment('n/a'); 
          //  setSingleFishAgeStructurePurpose('1');
          setSingleFishAgeStructureCollection(structCollection);
        }

        
    }
    function onDeleteStructButton(num: any) {
        const newList = singleFishAgeStructureCollection.filter((item, index) => index !== num);
        structCollection = newList;
        setSingleFishAgeStructureCollection(structCollection);
    }
    function onSelectStructType(props: any) {
        if (props.value !== null) {
            setSingleFishAgeStructureType(props.value);
        }
    }
    function onSelectStructPurpose(props: any) {
        if (props.value !== null) {
            setSingleFishAgeStructurePurpose(props.value);
        }
        
    }

    const structObj = JSON.parse(localStorage.getItem('AgeStructureTypes') || '{}');
    const ageStructType: string[] = [];
    Object.keys(structObj).map((key) => {
        ageStructType.push(structObj[key])
    });

    const purposeObj = JSON.parse(localStorage.getItem('AgeStructurePurpose') || '{}');
    const agePurpose: string[] = [];
    Object.keys(purposeObj).map((key) => {
        agePurpose.push(purposeObj[key])
    });

    const AgeStructurePopover = (

        <Popover id="agepop" className="dataAgeContainerPopOver">
            <Popover.Header as="h3">QR Scanner</Popover.Header>
            <Popover.Body className="dataContainer">
                <div id="reader" className="qr-Code"></div>
                <Form>
                    <Row style={{ paddingBottom: "10px" }}>
                        <Col>
                            <Form.Group as={Row} controlId="formQR">
                                <Col xs={3}>

                                    <>
                                        <Form.Label className="qrLabel" style={{ textAlign: "center", alignSelf: "center" }}>
                                            Purpose:
                                        </Form.Label>
                                        <Select styles={customStyles} onChange={(event:any) => onSelectStructPurpose(event)} placeholder={singleFishAgeStructurePurpose === "n/a" ? "Purpose..." : singleFishAgeStructurePurpose}  options={agePurpose.map((title) => { return { value: title, label: title } })} />
                                    </>
                                    
                                </Col>
                                <Col xs={3}>
                                    <>
                                        <Form.Label className="qrLabel" style={{ textAlign: "center", alignSelf: "center" }}>
                                            Type:
                                        </Form.Label>
                                        <Select styles={customStyles} onChange={(event:any) => onSelectStructType(event)} placeholder={singleFishAgeStructureType === "n/a" ? "Type..." : singleFishAgeStructureType} options={ageStructType.map((title) => { return { value: title, label: title } })} />
                                    </>
                                </Col>
                                {/* <Form.Label className="qrLabel" column style={{ textAlign: "center", alignSelf: "center"}}>
                                    QR Scanner:
                                </Form.Label> */}
                                <Col xs={4}>

                                    <Form.Label className="qrLabel" style={{ textAlign: "center", alignSelf: "center" }}>
                                        QR Code:
                                    </Form.Label>
                                    {/* TODO: 
                                    edit manual input to get rid of end line 
                                    edit form
                                     */}
                                    {/* <Form> */}
                                    <Form.Control
                                        className="qrScannerButton"
                                        as="textarea"
                                        onChange={(event) => setSingleFishAgeStructure(event.target.value)}
                                        placeholder={singleFishAgeStructure === "n/a" ? "Enter QR Manually..." : singleFishAgeStructure}
                                        value={singleFishAgeStructure === "n/a" ? "" : singleFishAgeStructure}
                                    />

                                    {/* </Form> */}
                                </Col>
                                <Col xs={2}>
                                    <Form.Label className="qrLabel" style={{ textAlign: "center", alignSelf: "center" }}>
                                        Scan:
                                    </Form.Label>
                                    <Button id="qrscan" name="qrButton" className="qrScannerButtonScan" variant="secondary" onClick={() => startQrCodeScan()}>
                                        Scan
                                    </Button>
                                </Col>
                            </Form.Group>

                            <Row style={{ paddingBottom: "0px" }}>
                                {/* onChange={this.onChangeComment} */}


                                <Form.Group as={Col} controlId="formComment">
                                    <Form.Label className="qrLabel">Comment:</Form.Label>

                                    <Form.Control
                                        className="qrCommentInput"
                                        as="textarea"
                                        onChange={(event) => setSingleFishStructureComment(event.target.value)}
                                        value={singleFishStructureComment === ('n/a' || null ) ? '' : singleFishStructureComment}
                                    />
                                </Form.Group>
                                {/* <Col xs={2} style={{ textAlign: "center", alignSelf: "center"}}>
                             <Button data-placement="top"  onClick={() => onAddStruct()} className="plusQr altQr">
                                </Button>                      
                           </Col> */}
                            </Row>

                        </Col>
                        <Col xs={2} style={{ textAlign: "center", alignSelf: "center" }}>
                            <Button data-placement="top" onClick={() => onAddStruct()} className="plusQr altQr">
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <hr className="qrLine" />


                <>
                    <div>
                        <ul>
                            {!(!Array.isArray(singleFishAgeStructureCollection) || !singleFishAgeStructureCollection.length)
                                &&
                                singleFishAgeStructureCollection.map(
                                    (struct, index) =>
                                        <li key={index}>
                                            <Row>
                                                <Col>
                                                    <Row style={{ paddingBottom: "5px" }}>
                                                    </Row>
                                                    <Row style={{ paddingBottom: "2px" }}>

                                                        <Form.Group as={Col} controlId="formQRList">
                                                            <Form.Label className="qrLabel">Purpose:</Form.Label>

                                                            <Form.Control
                                                                className="qrPurposeList"
                                                                as="textarea"
                                                                readOnly
                                                                value={struct.purpose === null ? '' : struct.purpose}

                                                            />
                                                        </Form.Group>

                                                        <Form.Group as={Col} controlId="formQRList">
                                                            <Form.Label className="qrLabel">Type:</Form.Label>

                                                            <Form.Control
                                                                className="qrPurposeList"
                                                                as="textarea"
                                                                readOnly
                                                                value={struct.type === null ? '' : struct.type}

                                                            />
                                                        </Form.Group>

                                                        <Form.Group as={Col} controlId="formQRList">
                                                            <Form.Label className="qrLabel">QR:</Form.Label>

                                                            <Form.Control
                                                                className="qrPurposeList"
                                                                as="textarea"
                                                                readOnly
                                                                value={struct.qr}
                                                            />
                                                        </Form.Group>

                                                        {/* <Col style={{ textAlign: "center", alignSelf: "center"}}>
                                                <Button data-placement="top"  onClick={() => onDeleteStructButton(struct.id)} className="minusQr"></Button>
                                                </Col> */}
                                                    </Row>

                                                    <Row style={{ paddingBottom: "2px" }}>
                                                    </Row>
                                                    <Row style={{ paddingBottom: "2px" }}>

                                                        <Form.Group as={Col} controlId="formComment">
                                                            <Form.Label className="qrLabel">Comment:</Form.Label>

                                                            <Form.Control
                                                                className="qrCommentInputList"
                                                                as="textarea"
                                                                readOnly
                                                                value={struct.comment}

                                                            />
                                                        </Form.Group>

                                                    </Row>
                                                </Col>
                                                <Col xs={2} style={{ textAlign: "center", alignSelf: "center" }}>
                                                    <Button data-placement="top" onClick={() => onDeleteStructButton(index)} className="minusQr"></Button>
                                                </Col>
                                            </Row>
                                            <hr className="qrLine" />
                                        </li>
                                )
                            }
                        </ul>
                    </div>
                </>

            </Popover.Body>
        </Popover>
    );


    function onCheckedInitialMark(e: any) {
        setSingleFishTagsInitial(!singleFishTagsInitial);
    }

    const tagsObj = JSON.parse(localStorage.getItem('tagMarkTypes') || '{}');
    const TagTypesList: string[] = [];
    Object.keys(tagsObj).map((key) => {
        TagTypesList.push(tagsObj[key])
    });

    const CommentTagsOtherPopover = (
        <Popover id="otherpop" className="dataOtherContainerPopOver">
            <Popover.Header as="h3">Comments Tags and Other</Popover.Header>
            <Popover.Body>
                <Form.Label className="commentsSize" >Comment:</Form.Label>
                <Form.Control
                    value={singleFishComment === 'n/a' ? "" : singleFishComment}
                    className="commentsTextInput" as="textarea" rows={3} placeholder={"Enter Comments..."} onChange={(event) => setSingleFishComment(event.target.value)} />

                <Form.Label className="commentsSize" >Keywords: (Separated By Commas)</Form.Label>
                <Form.Control
                    value={singleFishKeyWords === 'n/a' ? "" : singleFishKeyWords}
                    className="commentsTextInput" as="textarea" rows={3} placeholder={"male,tags,electrofishing,hashtags"} onChange={(event) => setSingleFishKeyWords(event.target.value)} />

                <Form.Group as={Row} className="commentsSize" controlId="gender" style={{ paddingBottom: "50px", paddingTop: "15px" }}>
                    <Form.Label >Gender:</Form.Label>
                    <Col>
                    <Form.Check 
                        className="commentsRadio"
                        checked={singleFishGender === 'n/a'}
                        type="radio"
                        label="N/A"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                        onChange={() => setSingleFishGender('n/a')}
                    />   
                    </Col>
                    <Col>
                        <Form.Check
                            className="commentsRadio"
                            checked={singleFishGender === 'Immature'}
                            type="radio"
                            label="Immature"
                            name="formHorizontalRadios"
                            id="formHorizontalRadios2"
                            onChange={() => setSingleFishGender('Immature')}
                        />
                    </Col>
                    <Col>            
                    <Form.Check 
                        className="commentsRadio"
                        checked={singleFishGender === 'Male'}
                        type="radio"
                        label="Male"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios2"
                        onChange={() => setSingleFishGender('Male')}
                    />
                    </Col>   
                    <Col>
                    <Form.Check
                        className="commentsRadio"
                        checked={singleFishGender === 'Female'}
                        type="radio"
                        label="Female"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios3"
                        onChange={() => setSingleFishGender('Female')}
                    />
                    </Col>   
                </Form.Group>

                <Card className="cardDivider" >
                    <Row className="g-2">
                        <Form.Label className="commentsSize">Fish Tags/Marks:</Form.Label>
                        <Col>
                            <Form.Control
                                value={singleFishTagsId === 'n/a' ? "" : singleFishTagsId}
                                className="tagsId" as="textarea" rows={3} placeholder={"Tag ID..."} onChange={(event) => setSingleFishTagsId(event.target.value)} />
                        </Col>

                        <Col>

                            <Select styles={customStyles} onChange={(e) => onChangeTagType(e)} placeholder={singleFishTagsType === 'n/a' ? "Choose Fish Tags..." : singleFishTagsType} options={TagTypesList.map((title) => { return { value: title, label: title } })} />

                        </Col>

                        <Col>
                            <div className="labelD">Initial Mark?</div>
                            <input defaultChecked={true} className="checkbox" type="checkbox" id="fname" onClick={onCheckedInitialMark}>
                            </input>
                        </Col>

                        <Row>
                            <Col>
                                <Form.Control
                                    value={singleFishTagsComment === 'n/a' ? "" : singleFishTagsComment}
                                    className="tagsComment" as="textarea" rows={3} placeholder={"Comments..."} onChange={(event) => setSingleFishTagsComment(event.target.value)} />
                            </Col>

                            <Col>
                                <Button data-placement="top" onClick={() => onAddButton()} className="plus alt">
                                </Button>
                            </Col>
                        </Row>
                    </Row>
                    <div>
                        <ul>
                            {
                                singleFishTags.map(
                                    (tag, index) =>
                                        <li key={index}>
                                            <Row style={{ paddingBottom: "10px" }}>
                                                <Col>
                                                    <Button variant="secondary" className="listButtons" disabled>{tag.id}</Button>
                                                </Col>
                                                <Col>
                                                    <Button variant="secondary" className="listButtons" disabled>{tag.type}</Button>
                                                </Col>
                                                <Col>
                                                    <Button variant="secondary" className="listButtons" disabled>{tag.comment}</Button>
                                                </Col>
                                                <Col>
                                                    {/* <Button variant="secondary" className="listValueButton" disabled>{setting.value}</Button> */}
                                                    <Button data-placement="top" onClick={() => onDeleteButton(index)} className="minus"></Button>
                                                </Col>
                                            </Row>
                                        </li>
                                )
                            }
                        </ul>
                    </div>
                </Card>
            </Popover.Body>
        </Popover>
    );

    const PreviousFishSamplePopup = (
        <Popover id="otherpop" className="prevFishContainerPopOver">
            <Popover.Header as="h3">Previous Length-Weight Data</Popover.Header>
            {/* <Popover.Body> */}
            <GetPreviousFishSamples />
            {/* </Popover.Body> */}
        </Popover>
    );

    function GetPreviousFishSamples() {
        let prevLengths: any[] = [];
        let i = 0;

        let dataExists = weights.length > 0 ? true : false;

        return (
            <div className="genStatsWrapper">
                <Card className="cardDivider">
                    <Container fluid>
                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "15px" }}></Row>
                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "5px" }}></Row>
                        <Card className="editPrevCardDividerList">
                            <Table bordered className="fishTable">
                                <thead>
                                    <tr>
                                        <th scope="col">Length</th>
                                        <th scope="col">Weight</th>
                                        <th scope="col">Structures</th>
                                        <th scope="col">Comment</th>
                                        <th scope="col">Edit</th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                </thead>
                                {dataExists ?
                                    <tbody>
                                        {!(!Array.isArray(weights) || !weights.length)
                                            &&
                                            weights.map(
                                                (singleFish, index) =>
                                                    <tr key={index}>
                                                        <td>{singleFish.length}</td>
                                                        <td>{singleFish.weight}</td>
                                                        <td>{singleFish.structures.length}</td>
                                                        <td> {singleFish.comment}</td>
                                                        <td className="fishTableButton" >
                                                            <Button variant="secondary" className="fishTableEdit" onClick={() => onHitEdit(singleFish)}> Edit </Button>
                                                        </td>
                                                        <td className="fishTableButton">
                                                            <CloseButton onClick={() => initialOnDelete(singleFish)} ></CloseButton>
                                                        </td>
                                                    </tr>
                                            )
                                        }
                                    </tbody>
                                    :
                                    <tbody>
                                        <tr>
                                            <td colSpan={6}>There is no Length-Weight data for this species.</td>
                                        </tr>
                                    </tbody>
                                }
                            </Table>
                        </Card>
                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "5px" }}></Row>
                    </Container>
                </Card>

            </div>

        );
    }

    function initialOnDelete(fish: any) {
        setDeleteFish(fish);
        setShowDeleteWarning(true);
    }
    //TODO: FINISH AND ADD POPUP
    function onHitDelete() {
        if (speciesCode !== 'misc') {
            setShowDeleteWarning(false);
            const lengthGroup = Math.floor(Number(deleteFish.length) / 10); //this is the index
            const newLengths = [...length];
            let newWeightStructCount = weightStructCountCollection;
            let newWeights = weights;
            //const lengthsToUpdate = newLengths[lengthGroup];   
            // newLengths[lengthGroup].weights = newLengths[lengthGroup].weights.filter((item) => item.id !== deleteFish.id);
            newWeights = newWeights.filter((item: any) => item.id !== deleteFish.id);
            // newLengths[lengthGroup].weightCount = newLengths[lengthGroup].weightCount - 1;
            //newLengths[lengthGroup].ageStructureCount = newLengths[lengthGroup].ageStructureCount - deleteFish.structures.length;
            // const newCount = totalCount - 1;
            let newWeightCount = totalWeightCount;
            let newStructCount = totalStructuresCount;

            //  let newWeightStructCount = weightStructCountCollection;
            if (deleteFish.weight !== 'n/a') {
                //   newWeightStructCount.w -= 1;
                newWeightStructCount[lengthGroup].w -= 1;
                //  newLengths[lengthGroup].weightCount = newLengths[lengthGroup].weightCount - 1;
                newWeightCount -= 1;
            }
            if (deleteFish.structures.length > 0) {
                //   newWeightStructCount.s -= 1;
                newWeightStructCount[lengthGroup].s -= 1;
                //  newLengths[lengthGroup].ageStructureCount = newLengths[lengthGroup].ageStructureCount - 1;
                newStructCount -= 1;
            }

            if (deleteFish.gender === "Female") {
                setTotalFemaleCount(totalFemaleCount - 1);
            } else if (deleteFish.gender === "Male") {
                setTotalMaleCount(totalMaleCount - 1);
            } else if (deleteFish.gender === "Immature") {
                setTotalImmatureCount(totalImmatureCount - 1);
            } else {
                setTotalNACount(totalNACount - 1);
            }

            let newCount = totalCount - 1;
            setLength(newLengths);
            setWeights(newWeights);
            setTotalCount(totalCount - 1);
            setTotalWeightCount(newWeightCount);
            setTotalStructuresCount(newStructCount);
            setWeightStructCountCollection(newWeightStructCount);
            // setWeightStructCountCollection(newWeightStructCount);

            const dataString = {
                code: speciesCode, totalCount: newCount,
                totalWeightCount: newWeightCount,
                totalStructuresCount: newStructCount,
                totalFemaleCount: totalFemaleCount,
                totalNACount: totalNACount,
                totalImmatureCount: totalImmatureCount,
                totalMaleCount: totalMaleCount,
                lengths: newLengths,
                weights: newWeights,
                counts: fishcounts
            }

            localStorage.setItem(localStorageName, JSON.stringify(dataString));
            localStorage.setItem(weightStructCount, JSON.stringify(newWeightStructCount)); //save new weight struct count to localStorage
            setDeleteFish('');

        } else { //misc fish

            setShowDeleteWarning(false);
            let newWeights = weights;
            newWeights = newWeights.filter((item: any) => item.id !== deleteFish.id);

            setWeights(newWeights);
            setFishCounts(fishcounts);
            const dataString = {

                weights: newWeights
            }
            localStorage.setItem(localStorageName, JSON.stringify(dataString));
            setDeleteFish('');
        }
    }

    function onHitEdit(fish: any) {
        if (speciesCode === 'misc') {
            setMiscSpecies(fish.species);
        }
        setEditFish(true);
        setDeleteFish(fish);
        // setEd(true);
        props.setParentEditing(true);
        setEditFishId(fish.id);
        setEditFishLength(fish.length);
        setEditFishWeight(fish.weight);
        setEditFishStruct(fish.structures);
        setSingleFishWeight(fish.weight);
        setSingleFishLength(fish.length);
        setSingleFishComment(fish.comment);
        setSingleFishGender(fish.gender);
        setSingleFishKeyWords(fish.keywords);
        setSingleFishTags(fish.tags);
        setSingleFishAgeStructureCollection(fish.structures);
        tagsCollection = fish.tags;
        structCollection = fish.structures;

        // this only gets rid of the total Counts
        let newWeightCount = totalWeightCount;
        if (fish.weight !== 'n/a') {
            newWeightCount -= 1;

        }
        let newStructCount = totalStructuresCount;
        if (singleFishAgeStructureCollection.length > 0) {
            newStructCount -= 1;
        }
     
        if (fish.gender === 'Female') {
            setTotalFemaleCount(totalFemaleCount - 1);
        } else if (fish.gender === 'Male') {
            setTotalMaleCount(totalMaleCount - 1);
        } else if (fish.gender === 'Immature') {
            setTotalImmatureCount(totalImmatureCount - 1)
        } else {
            setTotalNACount(totalNACount - 1);
        }

        //update state to fake delete

        //  setLength(newLengths);
        setTotalCount(totalCount - 1);
        setTotalWeightCount(newWeightCount);
        setTotalStructuresCount(newStructCount);
    }

    function updateMiscSpecies(props: any) {
        if (props.value !== null) {
            setMiscSpecies(props.value);
        }
    }

    function saveFromWarning() {
        handleSubmit();
        setShowWeightLarger(false);
        setShowLengthValidWarning(false);
    }

    function saveFromRangeWarning() {
        handleSubmit();
        setShowRangeWarning(false);
        setShowWeightLarger(false);
    }

    const tabletSettings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');

    return (
        // <div>     
        <Container fluid className="dataContainerTab" id="datacontain" data-theme={tabletSettings.theme}>
            <LengthValidationWarning show={showLengthValidWarning}
                onHide={() => setShowLengthValidWarning(false)}
                backdrop="static"
            />
            <DeleteWarning
                show={showDeleteWarning}
                onHide={() => setShowDeleteWarning(false)}
                backdrop="static"
            />
            <RangeWarning
                show={showRangeWarning}
                onHide={() => setShowRangeWarning(false)}
                backdrop="static"
            />
            {speciesCode !== "misc" ?
                <Row className="countButtonRow">
                    <div className="entry">
                        {/* <input className="text-input" type="text" id="fname" defaultValue="Exact Length: "></input> */}
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Length: (mm)"
                            className="label-input"
                            style={{ padding: 'unset' }}
                        >
                            <Form.Control
                                value={singleFishLength === "n/a" ? "" : singleFishLength}
                                inputMode="decimal"
                                enterKeyHint="done"
                                type="number"
                                className="text-input" pattern="[0-9]*" 
                                as="input" placeholder=" " 
                                onChange={(event) => setSingleFishLength(event.target.value)} 
                               
                                />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput2"
                            label="Weight: (g)"
                            className="label-input"
                        >
                            <Form.Control
                                value={singleFishWeight === "n/a" ? "" : singleFishWeight}
                                inputMode="decimal"
                                enterKeyHint="done"
                                type="number"
                                className="text-input" pattern="[0-9]*" as="input" placeholder=" " onChange={(event) => setSingleFishWeight(event.target.value)} />
                        </FloatingLabel>
                        <OverlayTrigger container={ref} onExit={stopQRCodeScan} rootClose trigger="click" placement="right" overlay={AgeStructurePopover}>
                            <Button className="entry-button">
                                Age Structure
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger container={ref} rootClose trigger="click" placement="right" overlay={CommentTagsOtherPopover}>
                            <Button className="entry-button">
                                Comments, Tags, and Other
                            </Button>
                        </OverlayTrigger>
                        <Button className="entry-button" onClick={() => validate()}>
                            Save Fish
                        </Button>
                        <OverlayTrigger container={ref} rootClose trigger="click" placement="right" overlay={PreviousFishSamplePopup}>
                            <Button className="prev-button">
                                Previous Fish
                            </Button>
                        </OverlayTrigger>
                    </div>
                    <div className="button-grid" ref={ref}>

                        <ul className="fixHeight">
                            <Col className="butCol">
                                {
                                    length.slice(0, 10).map(
                                        (length, i) =>

                                            <li key={i} className="fixHeight">
                                                {/*length.count*/}
                                                <ButtonGroup className={setBackground2(i)} title="count" >
                                                    <div className="textOverlay">{i === 0 ? "000" : "0" + (i * 10)}</div>
                                                    <div className="textOverlayCount">{length.count}</div>
                                                    <div className="textOverlayPlus">+</div>
                                                    <div className="textOverlayMinus">-</div>
                                                    <Button onClick={() => decrementCount2(i, length.count)} className={setMinusHighlight(i)}></Button>
                                                    <Button onClick={() => incrementCount2(i, length.count)} className={setPlusHighlight(i)}></Button>
                                                </ButtonGroup>
                                                {/* <div style={{ paddingBottom: "5px" }}></div> */}
                                            </li>


                                    )
                                }
                            </Col>
                        </ul>


                        <ul className="fixHeight">
                            <Col className="butCol">
                                {
                                    length.slice(10, 20).map(
                                        (length, i) =>

                                            <li key={i} className="fixHeight">
                                                <ButtonGroup className={setBackground2(i + 10)} title="count" >
                                                    <div className="textOverlay">{i + 10 + "0"}</div>
                                                    <div className="textOverlayCount">{length.count}</div>
                                                    <div className="textOverlayPlus">+</div>
                                                    <div className="textOverlayMinus">-</div>
                                                    <Button style={{ verticalAlign: "middle"}} onClick={() => decrementCount2((i + 10), length.count)} className={setMinusHighlight(i + 10)}></Button>
                                                    <Button onClick={() => incrementCount2((i + 10), length.count)} className={setPlusHighlight(i + 10)}></Button>
                                                </ButtonGroup>
                                            </li>
                                    )
                                }
                            </Col>
                        </ul>


                        <ul className="fixHeight">
                            <Col className="butCol">
                                {
                                    length.slice(20, 30).map(
                                        (length, i) =>

                                            <li key={i} className="fixHeight">
                                                <ButtonGroup className={setBackground2(i + 20)} title="count" >
                                                    <div className="textOverlay">{(i + 20) + "0"}</div>
                                                    <div className="textOverlayCount">{length.count}</div>
                                                    <div className="textOverlayPlus">+</div>
                                                    <div className="textOverlayMinus">-</div>
                                                    <Button onClick={() => decrementCount2((i + 20), length.count)} className={setMinusHighlight(i + 20)}></Button>
                                                    <Button onClick={() => incrementCount2((i + 20), length.count)} className={setPlusHighlight(i + 20)}></Button>
                                                </ButtonGroup>

                                            </li>
                                    )
                                }
                            </Col>
                        </ul>


                        <ul className="fixHeight">
                            <Col className="butCol">
                                {
                                    length.slice(30, 40).map(
                                        (length, i) =>

                                            <li key={i} className="fixHeight">
                                                <ButtonGroup className={setBackground2(i + 30)} title="count" >
                                                    <div className="textOverlay">{(i + 30) + "0"}</div>
                                                    <div className="textOverlayCount">{length.count}</div>
                                                    <div className="textOverlayPlus">+</div>
                                                    <div className="textOverlayMinus">-</div>
                                                    <Button onClick={() => decrementCount2((i + 30), length.count)} className={setMinusHighlight(i + 30)}></Button>
                                                    <Button onClick={() => incrementCount2((i + 30), length.count)} className={setPlusHighlight(i + 30)}></Button>
                                                </ButtonGroup>

                                            </li>
                                    )
                                }
                            </Col>
                        </ul>


                        <ul className="fixHeight">
                            <Col className="butCol">
                                {
                                    length.slice(40, 50).map(
                                        (length, i) =>

                                            <li key={i} className="fixHeight">
                                                <ButtonGroup className={setBackground2(i + 40)} title="count" >
                                                    <div className="textOverlay">{(i + 40) + "0"}</div>
                                                    <div className="textOverlayCount">{length.count}</div>
                                                    <div className="textOverlayPlus">+</div>
                                                    <div className="textOverlayMinus">-</div>
                                                    <Button onClick={() => decrementCount2((i + 40), length.count)} className={setMinusHighlight(i + 40)}></Button>
                                                    <Button onClick={() => incrementCount2((i + 40), length.count)} className={setPlusHighlight(i + 40)}></Button>
                                                </ButtonGroup>

                                            </li>
                                    )
                                }
                            </Col>
                        </ul>


                        <ul className="fixHeight">
                            <Col className="butCol">
                                {
                                    length.slice(50, 60).map(
                                        (length, i) =>

                                            <li key={i} className="fixHeight">
                                                <ButtonGroup className={setBackground2(i + 50)} title="count" >
                                                    <div className="textOverlay">{(i + 50) + "0"}</div>
                                                    <div className="textOverlayCount">{length.count}</div>
                                                    <div className="textOverlayPlus">+</div>
                                                    <div className="textOverlayMinus">-</div>
                                                    <Button onClick={() => decrementCount2((i + 50), length.count)} className={setMinusHighlight(i + 50)}></Button>
                                                    <Button onClick={() => incrementCount2((i + 50), length.count)} className={setPlusHighlight(i + 50)}></Button>
                                                </ButtonGroup>

                                            </li>
                                    )
                                }
                            </Col>
                        </ul>


                        <ul className="fixHeight">
                            <Col className="butCol">
                                {
                                    length.slice(60, 70).map(
                                        (length, i) =>

                                            <li key={i} className="fixHeight">
                                                <ButtonGroup className={setBackground2(i + 60)} title="count" >
                                                    <div className="textOverlay">{(i + 60) + "0"}</div>
                                                    <div className="textOverlayCount">{length.count}</div>
                                                    <div className="textOverlayPlus">+</div>
                                                    <div className="textOverlayMinus">-</div>
                                                    <Button onClick={() => decrementCount2((i + 60), length.count)} className={setMinusHighlight(i + 60)}></Button>
                                                    <Button onClick={() => incrementCount2((i + 60), length.count)} className={setPlusHighlight(i + 60)}></Button>
                                                </ButtonGroup>

                                            </li>
                                    )
                                }
                            </Col>
                        </ul>


                        <ul className="fixHeight">
                            <Col className="butCol">
                                {
                                    length.slice(70, 80).map(
                                        (length, i) =>

                                            <li key={i} className="fixHeight">
                                                <ButtonGroup className={setBackground2(i + 70)} title="count" >
                                                    <div className="textOverlay">{(i + 70) + "0"}</div>
                                                    <div className="textOverlayCount">{length.count}</div>
                                                    <div className="textOverlayPlus">+</div>
                                                    <div className="textOverlayMinus">-</div>
                                                    <Button onClick={() => decrementCount2((i + 70), length.count)} className={setMinusHighlight(i + 70)}></Button>
                                                    <Button onClick={() => incrementCount2((i + 70), length.count)} className={setPlusHighlight(i + 70)}></Button>
                                                </ButtonGroup>

                                            </li>
                                    )
                                }
                            </Col>
                        </ul>


                        <ul className="fixHeight">
                            <Col className="butCol">
                                {
                                    length.slice(80, 90).map(
                                        (length, i) =>

                                            <li key={i} className="fixHeight">
                                                <ButtonGroup className={setBackground2(i + 80)} title="count" >
                                                    <div className="textOverlay">{(i + 80) + "0"}</div>
                                                    <div className="textOverlayCount">{length.count}</div>
                                                    <div className="textOverlayPlus">+</div>
                                                    <div className="textOverlayMinus">-</div>
                                                    <Button onClick={() => decrementCount2((i + 80), length.count)} className={setMinusHighlight(i + 80)}></Button>
                                                    <Button onClick={() => incrementCount2((i + 80), length.count)} className={setPlusHighlight(i + 80)}></Button>
                                                </ButtonGroup>

                                            </li>
                                    )
                                }
                            </Col>
                        </ul>


                        <ul className="fixHeight">
                            <Col className="butCol">
                                {
                                    length.slice(90, 100).map(
                                        (length, i) =>

                                            <li key={i} className="fixHeight">
                                                <ButtonGroup className={setBackground2(i + 90)} title="count" >
                                                    <div className="textOverlay">{(i + 90) + "0"}</div>
                                                    <div className="textOverlayCount">{length.count}</div>
                                                    <div className="textOverlayPlus">+</div>
                                                    <div className="textOverlayMinus">-</div>
                                                    <Button onClick={() => decrementCount2((i + 90), length.count)} className={setMinusHighlight(i + 90)}></Button>
                                                    <Button onClick={() => incrementCount2((i + 90), length.count)} className={setPlusHighlight(i + 90)}></Button>
                                                </ButtonGroup>

                                            </li>
                                    )
                                }
                            </Col>
                        </ul>

                    </div>
                </Row>
                :
                <>
                    <Row className="countButtonRow">
                        <div className="entry">
                            {/* options={species.map((title) => { return { value: title, label: title } })}  defaultValue={miscSpecies} */}
                            <Select styles={miscStyle} className="misc-input"
                                value={miscSpecies === 'n/a' ? "Select Species..." : { value: miscSpecies, label: miscSpecies }}
                                onChange={(event: any) => updateMiscSpecies(event)}
                                placeholder={miscSpecies === 'n/a' ? "Select Species..." : miscSpecies}
                                options={species.map((title) => { return { value: title, label: title } })} />
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Length: (mm)"
                                className="text-input"
                            >
                                <Form.Control
                                    value={singleFishLength === "n/a" ? "" : singleFishLength}
                                    inputMode="decimal"
                                    enterKeyHint="done"
                                    type="number"
                                    className="text-input" pattern="[0-9]*" as="input" placeholder=" " onChange={(event) => setSingleFishLength(event.target.value)} />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput2"
                                label="Weight: (g)"
                                className="text-input"
                            >
                                <Form.Control
                                    value={singleFishWeight === "n/a" ? "" : singleFishWeight}
                                    inputMode="decimal"
                                    enterKeyHint="done"
                                    type="number"
                                    className="text-input" pattern="[0-9]*" as="input" placeholder=" " onChange={(event) => setSingleFishWeight(event.target.value)} />
                            </FloatingLabel>
                            <OverlayTrigger container={ref} rootClose trigger="click" placement="right" overlay={AgeStructurePopover}>
                                <Button className="entry-button">
                                    Age Structure
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger container={ref} rootClose trigger="click" placement="right" overlay={CommentTagsOtherPopover}>
                                <Button className="entry-button">
                                    Comments, Tags, and Other
                                </Button>
                            </OverlayTrigger>
                            <Button className="entry-button" onClick={() => validate()}>
                                Save Fish
                            </Button>
                        </div>
                        <div className="button-grid" ref={ref}>
                            <div className="genStatsWrapper">
                                <Card className="cardDivider">
                                    <Container fluid>
                                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "15px" }}></Row>
                                        {/* <Row> */}
                                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "5px" }}></Row>
                                        <Card className="editPrevCardDividerList">
                                            <Table bordered className="fishTable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Species</th>
                                                        <th scope="col">Length</th>
                                                        <th scope="col">Weight</th>
                                                        <th scope="col">Structures</th>
                                                        <th scope="col">Comment</th>
                                                        <th scope="col">Edit</th>
                                                        <th scope="col">Delete</th>
                                                    </tr>
                                                </thead>
                                                {weights.length > 0 ?
                                                    <tbody>
                                                        {!(!Array.isArray(weights) || !weights) &&
                                                            weights.map(
                                                                (singleFish, index) =>
                                                                    <tr key={index}>
                                                                        <td>{singleFish.species}</td>
                                                                        <td>{singleFish.length}</td>
                                                                        <td>{singleFish.weight}</td>
                                                                        <td>{singleFish.structures.length}</td>
                                                                        <td> {singleFish.comment}</td>
                                                                        <td className="fishTableButton" >
                                                                            <Button variant="secondary" className="fishTableEdit" onClick={() => onHitEdit(singleFish)}> Edit </Button>
                                                                        </td>
                                                                        <td className="fishTableButton">
                                                                            <CloseButton onClick={() => initialOnDelete(singleFish)} ></CloseButton>
                                                                        </td>
                                                                    </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                    :
                                                    <tbody>
                                                        <tr key="noFish">
                                                            <td colSpan={7}>There is no Length-Weight data for this species.</td>
                                                        </tr>
                                                    </tbody>
                                                }
                                            </Table>
                                        </Card>
                                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "5px" }}></Row>
                                    </Container>
                                </Card>
                            </div>
                        </div>
                    </Row>
                </>
            }

        </Container>
    )
};

export default DataEntry;