import React, { useState } from "react";
import {Col, ListGroup, Card, Container, Row ,Button} from 'react-bootstrap';
import './lightstyle.css';
import { useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

type WeightLengthList = {
    weightList: any[],
 
}

class EditPrevLengthWeight extends React.Component<any, WeightLengthList>{

    count: any;
    fishSampleIndex: number;
    
    localStorageCollection: any[];
    dataExists: boolean;
    weightLengthCollection: any[];
    weights: any[];

    sampleCount: number;
    sampleStructure: number;
    sampleWeight: number;
    currSampleSettings: any;
    currFishCode: any;
    species: any;

    constructor(props: any) {
        super(props);        
        this.dataExists = false;
        this.weights = [];
        this.weightLengthCollection = [];
        this.sampleCount = 0;
        this.sampleStructure = 0;
        this.sampleWeight = 0;
        this.currSampleSettings = "";
        this.currFishCode = "";
        this.species = "";

        this.fishSampleIndex = JSON.parse(localStorage.getItem('editFishSampleIndex') || '{}');
        if (localStorage.getItem('editFishSampleIndex')) {
            this.count = this.fishSampleIndex;
        } else {
            this.count = 0;
        }
        if (localStorage.getItem("editFishCode")) {
            this.currFishCode = JSON.parse(localStorage.getItem("editFishCode") || '{}');
        } else {
            this.count = 0;
        }
        this.localStorageCollection = JSON.parse(localStorage.getItem('fishSample') || '{}');
        
        if (localStorage.getItem('fishSample')) {  
            this.currSampleSettings = this.localStorageCollection[this.fishSampleIndex].settings;
            let countsFromLocalStorage = JSON.parse(localStorage.getItem(this.currFishCode) || '{}');
            if(localStorage.getItem(this.currFishCode)){
                this.dataExists = true;
                //all lengths data for one specific fish
                this.species = countsFromLocalStorage.code;
                this.weightLengthCollection = countsFromLocalStorage.lengths;
            }

            let i = 0;
            for(i; i < this.weightLengthCollection.length; i++){
                if(this.weightLengthCollection[i].weights.length > 0){ //if there are weights
                    let j = 0;
                    for(j; j < this.weightLengthCollection[i].weights.length; j++){
                        let w = this.weightLengthCollection[i].weights[j];
 
                        this.weights = [...this.weights, {id: j, date: w.id, weight: w.weight, length: w.length, structures: w.structures, comment: w.comment}];
                    }
                    
                }
            }
            this.weights = this.weights.sort((a: any,b:any ) => {
                return new Date(a.date).getTime() - 
                    new Date(b.date).getTime()
            }).reverse();
            console.log(this.weights);
            this.state = {
                weightList: this.weights,
            }
        }

      

    } 
     
    


    render() {
        return( 
            <div className="genStatsWrapper">
            <Card className="cardDivider">
            <Container fluid>
            <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "15px" }}></Row>
                <Row>
                   <Col>
                   <BackButton/>
                   </Col>
                    <Col lg="auto" style={{ paddingBottom: "15px" }}>
                    <ListGroup.Item className="listPrevSamplesTitle">
                    <h1>{this.species} Length Weight Data</h1>
                    </ListGroup.Item>                       
                    </Col>
                    <Col></Col>
                </Row>
            <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "10px" }}></Row>
                {/* <Row> */}
                <>
                 <ListGroup horizontal>
                     <ListGroup.Item action className="editPrevSamplesMainTitle">
                         {this.currSampleSettings.date.slice(5, 7) + "/" + this.currSampleSettings.date.slice(8, 10) + "/" + this.currSampleSettings.date.slice(0, 4)}
                     </ListGroup.Item>
                     <ListGroup.Item action className="editPrevSamplesMainTitle">
                         {this.currSampleSettings.method}
                     </ListGroup.Item>
                     <ListGroup.Item action  className="editPrevSamplesMainTitle">
                         {this.currSampleSettings.waterbodyCode}
                     </ListGroup.Item>
                     <ListGroup.Item action className="editPrevSamplesMainTitle">
                         {this.currSampleSettings.station}
                     </ListGroup.Item>
                 </ListGroup>
        </>
                <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "5px" }}></Row>
                <Card className="editPrevCardDividerList">  
                <ListGroup.Item className="editPrevSamplesListBig">
                    Species: {this.species}
                </ListGroup.Item> 
                <ListGroup style={{ textAlign: "center", verticalAlign: "middle"}}  horizontal className="editPrevSampleFixHeightTitle">
                            {/* <ListGroup.Item className="editPrevSamplesListBigTitle">
                                Species
                            </ListGroup.Item> */}
                            <ListGroup.Item className="editPrevSamplesListTitleSmall">
                                Length
                            </ListGroup.Item>
                            <ListGroup.Item action className="editPrevSamplesListTitleSmall">
                                Weight
                            </ListGroup.Item>
                            <ListGroup.Item action className="editPrevSamplesListTitle">
                                Structure
                            </ListGroup.Item>
                            <ListGroup.Item action className="editPrevSamplesListTitle">
                                Other
                            </ListGroup.Item>
                            <ListGroup.Item style={{display: "flex",justifyContent: "center",alignItems: "center"}} className="editButtonList">
                             <Button style={{display: "flex",justifyContent: "center",alignItems: "center"}} className="editButton">
                                    Edit
                                 </Button>
                            </ListGroup.Item>
                        </ListGroup>
                       
              
             
                <div>  
                    {this.dataExists ?                
                            <ul>
                                        {!(!Array.isArray(this.state.weightList) || !this.state.weightList)
                                            &&
                                    this.state.weightList.map(
                                        (weight) =>
                                            <li key={weight.id}>
                                                <>
                                                <ListItem localStorage={this.currFishCode} weight={weight}/>
                                                </>
                                            </li>
                                    )
                                }
                            </ul> 
                            : 
                            <ListGroup horizontal>
                                  <ListGroup.Item className="listPrevSamples">
                                    There are currently no samples.
                               </ListGroup.Item>
                            </ListGroup> }

                        </div>
                        </Card>                   
                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "5px" }}></Row>                   
                </Container>
        </Card>
               
    </div>

            );
        }
}

export function ListItem(props: any){
    //const localStorageIndex = props.localStorage; //where to save edits in localStorage
  //  const fishCode = props.fishCode; //species code
    //id: 
    const [singleFish] = useState(props.weight); //one data entry
    //collection of all data entries for single species
    //set this after saving edits
    //const [weightCollection, setweightCollection] = useState(props.weightCollection); 
    
    //tells us what button should say
    //const [edit, setEditStatus] = useState(false);
    
   
 
    return (
        <>
            <ListGroup horizontal >
              
                <ListGroup.Item className="editPrevSamplesListSmall">
                    <input type="text" name="weigthList" className="editPrevSamplesListInput" 
                    // onChange={(event) => setSingleFishLength(event.target.value)}
                    value={singleFish.length}>
                    </input>
                </ListGroup.Item>
                <ListGroup.Item active className="editPrevSamplesListSmall">
                    {singleFish.weight}
                </ListGroup.Item>
                <ListGroup.Item active as="button" className="editPrevSamplesList">
                    {singleFish.structures.length}
                </ListGroup.Item>
                <ListGroup.Item active as="button" className="editPrevSamplesList">
                    {singleFish.comment}
                </ListGroup.Item>
                <ListGroup.Item style={{display: "flex",justifyContent: "center",alignItems: "center"}} className="editButtonList">
                      <Button variant="secondary" style={{display: "flex",justifyContent: "center",alignItems: "center"}} className="editButton2">
                              Edit
                      </Button>
                     </ListGroup.Item>
                
            </ListGroup>
            
        </>
   );

};

export function EditLengthWeight(props: any){
    let count = props.count;
   // let editIndex = props.index;
    /*
    function handleLengthWeightClick() {       
        navigate("/prevFishSample");
      }
      function handleLengthClick() {
         
         navigate("/prevFishSample");
       }
       */
     return (
         <ListGroup horizontal >
             <ListGroup.Item className="editPrevSamplesListBig">
                 {count.id}
             </ListGroup.Item>
             <ListGroup.Item className="editPrevSamplesList">
                 {count.totalCount}
             </ListGroup.Item>
             <ListGroup.Item action as="button" className="editPrevSamplesListButton">
                 Edit
             </ListGroup.Item>
             <ListGroup.Item action as="button" className="editPrevSamplesListButton">
                 Edit
             </ListGroup.Item>
         </ListGroup>
        //  onClick={this.myfunction} 
    );
};

export function BackButton(props: any){
    let navigate = useNavigate();
    function handleClick() {
        navigate("/prevFishSample");
      }
     return (
    <>
    <Button variant="secondary" className="backButton" onClick={() => handleClick()}>
        Back
    </Button>
    </>
    );
};

export default EditPrevLengthWeight;
