import React, { useState } from "react";
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import './lightstyle.css';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { strict } from "assert";


const changeHandler = (event: any) => {
    //let navigate = useNavigate();
    //read text file
    var fr = new FileReader();
    var myText: string | ArrayBuffer | null = "";

    fr.onload = function () {
        myText = fr.result;
        if (myText != null) {
            localStorage.clear();
            //load into local storage
            var myText2: string = myText.toString();
            var data = JSON.parse(JSON.parse(myText2));
            Object.keys(data).forEach(function (k) {
                localStorage.setItem(k, data[k]);
            });
            //navigate(".\LandingPage");
            alert('Previous Data And Application State Has Been Imported.');
        }
    }
    if (window.confirm('Are You Sure You Wish To Import This File?  This Will DELETE ALL CURRENT DATA ON THIS DEVICE!')) {
        fr.readAsText(event.target.files[0]);
    }
    
};

class LoadFilePage extends React.Component<any> {

    componentDidMount() {
        const tabletSettings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');
        return tabletSettings.theme;
    };

    render() {
        return (
            <div className="center-col">
                <Card className="cardDividerPage" data-theme={this.componentDidMount()}>
                    <Container fluid="md" className="pageContainer">
                        <Row style={{ textAlign: "left", alignSelf: "center", paddingBottom: "35px" }}></Row>
                        <Row>
                            <Col>
                                <BackButton />
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "left", alignSelf: "left", paddingBottom: "30px", marginTop: "35px" }}>
                            <Col>
                                <h1>FinCatchDE Local Storage Import</h1>
                                <p>This will import data taken from local storage on a tablet or device running FinCatchDE.  This function is meant to be used in the event that uploading data to the server fails and data was downloaded to protect against data loss and/or to transfer to a different device or location.</p>
                                <div style={{ margin: "25px 0px 25px 0px", padding: "15px", backgroundColor: "yellow", border: "solid", borderWidth: "2px", borderColor: "red" }}>
                                    <span style={{ fontWeight: "bold", color: "darkred" }}><h2>Warning: </h2> This will delete all data currently stored on this device!</span>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "left", alignSelf: "left", paddingBottom: "35px", paddingTop: "35px" }}>
                            <Col>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <input type="file" name="file" onChange={changeHandler} />
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </Card>
            </div>
        );
    }
}

        export function BackButton(props: any) {
            let navigate = useNavigate();
            function handleClick() {
                navigate("/tabletSettings");
            }
            return (
                <>
                    <Button className="backButton" onClick={() => handleClick()}>
                        Back
                    </Button>
                </>
            );
        };


    export default LoadFilePage;


