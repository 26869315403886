import React from "react";
import { ButtonGroup, Container, Row, Col, Button} from 'react-bootstrap';
import './lightstyle.css';
import { useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";


class CommentTagsOther extends React.Component<any> {
    render() {
        return(
            <div className="center-col">

            <Container fluid="md">
                <Row>
                    <Col></Col>
                    <Col style={{ paddingBottom: "55px" }}>
                        <h1 style={{ textAlign: "center", alignSelf: "center" }}>Comment/Tags/Other</h1>
                    </Col>
                    <Col></Col>
                </Row>
                </Container>
            <>
        
        <ButtonGroup className="counterGroup" title="count" >
        <div className="textOverlay">000</div>
        <div className="textOverlayCount">0</div>
            <Button className="dataminus">-</Button>
            <Button className="dataplus">+</Button>      
        </ButtonGroup>      
        
        

        
        <div>
        <ButtonGroup className="counterGroupBlue" title="count" >
        <div className="textOverlay">000</div>
        <div className="textOverlayCount">3</div>
            <Button className="dataminus">-</Button>
            <Button className="dataplus">+</Button>      
        </ButtonGroup>      
        </div>
        

        
        <div>
        <ButtonGroup className="counterGroupOrange" title="count" >
        <div className="textOverlay">000</div>
        <div className="textOverlayCount">3</div>
            <Button className="dataminus">-</Button>
            <Button className="dataplus">+</Button>      
        </ButtonGroup>      
        </div>
        </>
               
        
                </div>

            );
        }
    }

    export function NavButton(props: any){
        let navigate = useNavigate();
        function handleClick() {
            navigate(props.path);
          }
         return (
        <>
        <Button variant="secondary" className="submitButton" onClick={() => handleClick()}>
            Start Sample
        </Button>

        <ButtonGroup>
            <Button variant="secondary">-</Button>
            <Button variant="secondary">+</Button>
        </ButtonGroup>
        </>

        );
    };



  
    export default CommentTagsOther;