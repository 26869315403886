import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GetSampleCollection } from './localStorageAccess';
import { Navigate, useNavigate } from 'react-router-dom';
import SampleSettings from './sampleSettings';
import Loading from './loading';
import { useAuth0, withAuth0 } from "@auth0/auth0-react";
import { Button } from 'react-bootstrap';

type DatabaseAcess = {
    //speciesSamplesResponse: any,
    //speciesFailed: boolean,
    //FishLengthsSamples: any,
    //FishLengthsFailed: boolean,
    //speciesGearSettingsSamples: any,
    //speciesGearsFailed: boolean,
    //FishCountSamples: any,
    //FishCountsFailed: boolean,
    //SingleFishSamples: any,
    //SingleFishSamplesFailed: boolean,
    //FishStructureSamples: any,
    //FishStructuresFailed: boolean,
    //FishTagsSamples: any,
    //FishTagsFailed: boolean,
    AllSamplesDone: boolean,
    CatchError: boolean,
    //ErrorSamples: any,
    //ErrorCount: any,
    CancelSample: boolean,
    SuccessfullSamples: any[]
}

type ErrorResponses = {
    Index: any,
    Survey: any,
    Response: any,
    Data: any
}

class accessSamplesDatabase extends React.Component<any, DatabaseAcess>{

    sampleErrors: any[] = [];
    sampleErrorsData: any[] = [];
    failures: ErrorResponses[] = [];
    count: any = 0;
    fishSample: any = [];
    sampleUID: any = "";
    token: any;
    index: any = 0;
    myCollection: any[] = [];

    constructor(props: any) {
        super(props)

        this.state = {
            //speciesSamplesResponse: {},
            //speciesFailed: false,
            //FishLengthsSamples: {},
            //FishLengthsFailed: false,
            //speciesGearSettingsSamples: {},
            //speciesGearsFailed: false,
            //FishCountSamples: {},
            //FishCountsFailed: false,
            //SingleFishSamples: {},
            //SingleFishSamplesFailed: false,
            //FishStructureSamples: {},
            //FishStructuresFailed: false,
            //FishTagsSamples: {},
            //FishTagsFailed: false,
            AllSamplesDone: false,
            CatchError: false,
            //ErrorSamples: [],
            //ErrorCount: 0,
            CancelSample: false,
            SuccessfullSamples: []
        }
    }

    componentDidMount() {
        this.submitSpeciesSamples();
    }


    async submitSpeciesSamples() {

        this.token = null;
        try {
            const { getAccessTokenSilently } = this.props.auth0;
            this.token = await getAccessTokenSilently();

            let samplesCollection = GetSampleCollection();
            let length = samplesCollection.length;
            if (length !== 0) {
                for (let i = 0; i < length; i++) {
                    this.index = i;
                    this.setState({
                        CancelSample: false
                    });
                    let sampleSettings = samplesCollection[i].fishSampleSettings;
                    const ownerObj = JSON.parse(localStorage.getItem('OwnersID') || '{}');
                    let ownerGuid = "";
                    for (var prop in ownerObj) {
                        if (ownerObj.hasOwnProperty(prop)) {
                            if (ownerObj[prop] === samplesCollection[i].fishSampleSettings.owner) {
                                ownerGuid = prop;
                            }
                        }
                    }

                    const wc = sampleSettings.waterbodyCode.split('-');
                    let code = wc[0];

                    const mc = sampleSettings.method.split('-');
                    let methodCode = mc[0];

                    const data = new FormData();
                    data.append('WaterbodyCode', code);
                    data.append('WaterbodyDescription', sampleSettings.waterbodyDescript);
                    data.append('MethodCode', methodCode);
                    data.append('SampleDate', sampleSettings.date);
                    data.append('SampleEndTime', sampleSettings.endTime);
                    data.append('SampleDuration', sampleSettings.duration);
                    data.append('Effort', sampleSettings.effort)
                    data.append('StationCode', sampleSettings.station);
                    data.append('Cpue', sampleSettings.useCPUE);
                    data.append('Keywords', sampleSettings.keywords);
                    data.append('Comments', sampleSettings.comments);
                    data.append('Owner', ownerGuid);
                    data.append('Validated', '0');
                    //data.append('Fake', '0');

                    this.submitSamples(data, sampleSettings);


                    if (this.state.CancelSample != true) {
                        //save sample settings
                        let gsLength = sampleSettings.electricSettingsCollection.length;
                        if (gsLength !== 0) {

                            //let surveyID = "";
                            for (let gsi = 0; gsi < gsLength; gsi++) {
                                //let surveyID = this.sampleUID;
                                const data2 = new FormData();
                                let sc = sampleSettings.electricSettingsCollection[gsi].id;
                                let settingCode = sc.split('-')[0];
                                data2.append('SettingId', this.sampleUID);
                                data2.append('SettingCode', settingCode);
                                data2.append('Value', sampleSettings.electricSettingsCollection[gsi].value);
                                this.submitGearSettings(data2, sampleSettings);
                            }
                        }
                    }

                    //store sample's fish
                    let fishSampleSpecies = samplesCollection[i].fishSampleSpecies;
                    if (fishSampleSpecies.length !== 0) {
                        for (let fs = 0; fs < fishSampleSpecies.length; fs++) {


                            //storing length frequency
                            let lengthFreq = fishSampleSpecies[fs].lengths;
                            for (let i = 0; i < lengthFreq.length; i++) {
                                if (this.state.CancelSample != true) {

                                    let count = lengthFreq[i].count;
                                    if (count !== 0) {

                                        let sc = fishSampleSpecies[fs].code;
                                        let speciesCode = sc.split('-')[0];
                                        //let surveyID = JSON.parse(localStorage.getItem('SpeciesSampleUID') || '{}');
                                        //let surveyID = this.sampleUID;
                                        let lengthGroup = i * 10;
                                        const lgData = new FormData();
                                        lgData.append('SampleUid', this.sampleUID.toString());
                                        lgData.append('SpeciesCode', speciesCode);
                                        lgData.append('GenderCode', '1');
                                        lgData.append('Group', lengthGroup.toString());
                                        lgData.append('LengthCount', count);
                                        this.submitLengthFreq(lgData, sampleSettings);
                                    }
                                }
                            }

                            //storing fish counts
                            let counts = fishSampleSpecies[fs].counts;
                            for (let i = 0; i < counts.length; i++) {
                                if (this.state.CancelSample != true) {

                                    let count = counts[i].count;
                                    if (count !== 0) {
                                        let sc = fishSampleSpecies[fs].code;
                                        let speciesCode = sc.split('-')[0];
                                        let genderCode = 1;
                                        if (counts[i].gender === "Male") {
                                            genderCode = 3;
                                        } else if (counts[i].gender === "Female") {
                                            genderCode = 4;
                                        } else if (counts[i].gender === "Immature") {
                                            genderCode = 2;
                                        } else {
                                            genderCode = 1;
                                        }
                                        const lgData = new FormData();
                                        lgData.append('SampleUid', this.sampleUID.toString());
                                        lgData.append('SpeciesCode', speciesCode);
                                        lgData.append('GenderCode', genderCode.toString());
                                        lgData.append('Count', counts[i].count);
                                        lgData.append('Comments', counts[i].comment);
                                        this.submitFishCounts(lgData, sampleSettings);
                                    }
                                }
                            }
                            

                            if (this.state.CancelSample != true) {

                                let speciesLengths = fishSampleSpecies[fs].weights;
                                
                                    let sc1 = fishSampleSpecies[fs].code;
                                let speciesCode = sc1.split('-')[0];
                                //following code saves fish counts under original "summation counter" architecture
                                    ////storing fish count -- Gender Unknown
                                    //if (fishSampleSpecies[fs].totalNACount !== 0) {

                                    //    //let surveyID = JSON.parse(localStorage.getItem('SpeciesSampleUID') || '{}');
                                    //    //let surveyID = this.sampleUID;
                                    //    const dataCount = new FormData;
                                    //    dataCount.append('SampleUid', this.sampleUID.toString());
                                    //    dataCount.append('SpeciesCode', speciesCode);
                                    //    dataCount.append('GenderCode', '1');
                                    //    dataCount.append('Count', fishSampleSpecies[fs].totalNACount);
                                    //    dataCount.append('Comments', 'comment'); //no comments stored
                                    //    this.submitFishCounts(dataCount, sampleSettings);
                                    //}
                                    //if (fishSampleSpecies[fs].totalFemaleCount !== 0) {
                                    //    //let surveyID = JSON.parse(localStorage.getItem('SpeciesSampleUID') || '{}');
                                    //    //let surveyID = this.sampleUID;
                                    //    const dataCount2 = new FormData;
                                    //    dataCount2.append('SampleUid', this.sampleUID.toString());
                                    //    dataCount2.append('SpeciesCode', speciesCode);
                                    //    dataCount2.append('GenderCode', '4');
                                    //    dataCount2.append('Count', fishSampleSpecies[fs].totalFemaleCount);
                                    //    dataCount2.append('Comments', 'comment'); //no comments stored
                                    //    this.submitFishCounts(dataCount2, sampleSettings);
                                    //}
                                    //if (fishSampleSpecies[fs].totalMaleCount !== 0) {
                                    //    //let surveyID = JSON.parse(localStorage.getItem('SpeciesSampleUID') || '{}');
                                    //    //let surveyID = this.sampleUID;
                                    //    const dataCount3 = new FormData();
                                    //    dataCount3.append('SampleUid', this.sampleUID.toString());
                                    //    dataCount3.append('SpeciesCode', speciesCode);
                                    //    dataCount3.append('GenderCode', '3');
                                    //    dataCount3.append('Count', fishSampleSpecies[fs].totalMaleCount);
                                    //    dataCount3.append('Comments', 'comment'); //no comments stored
                                    //    this.submitFishCounts(dataCount3, sampleSettings);
                                    //}
                                    //if (fishSampleSpecies[fs].totalImmatureCount !== 0) {
                                    //    //let surveyID = JSON.parse(localStorage.getItem('SpeciesSampleUID') || '{}');
                                    //    //let surveyID = this.sampleUID;
                                    //    const dataCount3 = new FormData();
                                    //    dataCount3.append('SampleUid', this.sampleUID.toString());
                                    //    dataCount3.append('SpeciesCode', speciesCode);
                                    //    dataCount3.append('GenderCode', '2');
                                    //    dataCount3.append('Count', fishSampleSpecies[fs].totalImmatureCount);
                                    //    dataCount3.append('Comments', 'comment'); //no comments stored
                                    //    this.submitFishCounts(dataCount3, sampleSettings);
                                    //}
if (speciesLengths.length !== 0) {
                                    //start of storing individual fish
                                    for (let lw = 0; lw < speciesLengths.length; lw++) {
                                        const data3 = new FormData();
                                        let genderCode = 1;
                                        //let surveyID = JSON.parse(localStorage.getItem('SpeciesSampleUID') || '{}');
                                        //let surveyID = this.sampleUID;
                                        data3.append('SamUid', this.sampleUID.toString());
                                        data3.append('SpeciesCode', speciesCode);
                                        data3.append('Length', speciesLengths[lw].length);
                                        data3.append('Weight', speciesLengths[lw].weigth);
                                        if (speciesLengths[lw].gender === "male") {
                                            genderCode = 3;
                                        } else if (speciesLengths[lw].gender === "female") {
                                            genderCode = 4;
                                        } else if (speciesLengths[lw].gender === "immature") {
                                            genderCode = 2;
                                        } else {
                                            genderCode = 1;
                                        }
                                        data3.append('GenderCode', genderCode.toString()) //need to get GenderCode
                                        data3.append('Comments', speciesLengths[lw].comment);
                                        this.submitFishSample(data3, sampleSettings);
                                        this.fishSample = Object.fromEntries(data.entries());


                                        //adding fish sample structures
                                        let weightStructs = speciesLengths[lw].structures;
                                        if (speciesLengths[lw].structures.length !== 0) {
                                            for (let ws = 0; ws < speciesLengths[lw].structures.length; ws++) {
                                                const purposeObj = JSON.parse(localStorage.getItem('AgeStructurePurpose') || '{}');
                                                let purposeID = "";
                                                for (var prop in purposeObj) {
                                                    if (purposeObj.hasOwnProperty(prop)) {
                                                        if (purposeObj[prop] === weightStructs[ws].purpose) {
                                                            purposeID = prop;
                                                        }
                                                    }
                                                }
                                                let fishSampleUID = JSON.parse(localStorage.getItem('PostFishSampleUID') || '{}');

                                                const data4 = new FormData();
                                                data4.append('FshUid', fishSampleUID.toString());
                                                data4.append('LinkPurposeCode', purposeID);
                                                data4.append('LinkCode', weightStructs[ws].qr);
                                                //data4.append('Type', weightStructs[ws].type);
                                                this.submitFishLink(data4, sampleSettings);

                                            }
                                        }


                                        //Adding Fish Tags
                                        //Guid FshUid, Guid TmUid, string TagId, string Comments
                                        let fishTags = speciesLengths[lw].tags;
                                        if (speciesLengths[lw].tags.length !== 0) {

                                            for (let ft = 0; ft < speciesLengths[lw].tags.length; ft++) {
                                                //tagMarkTypes
                                                let fishSampleUID = JSON.parse(localStorage.getItem('PostFishSampleUID') || '{}');

                                                const tagsObj = JSON.parse(localStorage.getItem('tagMarkTypes') || '{}');
                                                let tagsID = "";
                                                for (var prop in tagsObj) {
                                                    if (tagsObj.hasOwnProperty(prop)) {
                                                        if (tagsObj[prop] === fishTags[ft].type) {
                                                            tagsID = prop;
                                                        }
                                                    }
                                                }
                                                //localStorage.setItem('TagsUID', tagsID);
                                                const data5 = new FormData();
                                                data5.append('FshUid', fishSampleUID.toString());
                                                data5.append('TmUid', tagsID.toString());
                                                data5.append('TagId', fishTags[ft].id);
                                                data5.append('Comments', fishTags[ft].comment);
                                                this.submitFishTags(data5, sampleSettings);
                                            }
                                        }

                                    }
                                }
                            }

                            ////2022-04-25,4-North Platte Hatchery,12-Chestnut Lamprey
                            //let wc = sampleSettings.waterbodyCode;
                            //let date = sampleSettings.date;
                            //let sc = fishSampleSpecies[fs].code;
                            //let wsKey = date + ',' + wc + ',' + fishSampleSpecies[fs].code;
                            //localStorage.removeItem(wsKey);
                            //let key = this.index.toString() + ',' + sc;
                            ////check for success --> if not (do not push to database)
                            //localStorage.removeItem(key);
                        }

                    }

                    if (!this.state.CancelSample) {
                        this.state.SuccessfullSamples.push(this.index)
                    }


                    //end of fish samples
                    //}
                }

                //Remove data for successful pushes, first do species before indexs change, then do samples
                //let samplesCollection = GetSampleCollection();
                if (this.state.SuccessfullSamples.length > 0) {


                    let myCollection = JSON.parse(localStorage.getItem('fishSample') || '{}');
                    let myCollectionNew = myCollection;
                    myCollectionNew = {};
                    var numberOfSamples = Object.keys(myCollection).length;
                    var newIndex = 0;
                    for (var mySampleIndex = 0; mySampleIndex < numberOfSamples; mySampleIndex++) {
                        if (this.state.SuccessfullSamples.includes(mySampleIndex)) {
                            let tmpSampleCopy = myCollection[mySampleIndex];
                            //remove spp and w:s
                            //2022-04-25,4-North Platte Hatchery,12-Chestnut Lamprey
                            let wc = tmpSampleCopy.settings.waterbodyCode;
                            let date = tmpSampleCopy.settings.date;
                            for (var mySppCodeIndex = 0; mySppCodeIndex < tmpSampleCopy.speciesData.length; mySppCodeIndex++) {
                                let sc = tmpSampleCopy.speciesData[mySppCodeIndex];
                                let wsKey = date + ',' + wc + ',' + sc;
                                localStorage.removeItem(wsKey);
                                let key = mySampleIndex + ',' + sc;
                                localStorage.removeItem(key);
                            }
                        } else {
                            //update sample index
                            let tmpSampleCopy = myCollection[mySampleIndex];
                            //update index for spp
                            let wc = tmpSampleCopy.settings.waterbodyCode;
                            let date = tmpSampleCopy.settings.date;
                            for (var mySppCodeIndex = 0; mySppCodeIndex < tmpSampleCopy.speciesData.length; mySppCodeIndex++) {
                                let sc = tmpSampleCopy.speciesData[mySppCodeIndex];
                                let key = mySampleIndex + ',' + sc;
                                let oldData = localStorage.getItem(key) || '{}';
                                localStorage.removeItem(key);
                                localStorage.setItem(newIndex.toString() + ',' + sc, oldData);
                            }
                            //increment index
                            newIndex = newIndex + 1;
                        }
                    }

                    var newIndex = 0;
                    for (var mySampleIndex = 0; mySampleIndex < numberOfSamples; mySampleIndex++) {
                        if (this.state.SuccessfullSamples.includes(mySampleIndex)) {
                            //remove sample
                            //myCollection.splice(mySampleIndex , 1);

                            //delete myCollection[mySampleIndex];
                        } else {
                            //update sample index
                            myCollection[mySampleIndex].settings.id = newIndex;
                            myCollectionNew[newIndex] = myCollection[mySampleIndex];
                            //delete myCollection[mySampleIndex];


                            //increment index
                            newIndex = newIndex + 1;
                        }
                    }
                    if (Object.keys(myCollectionNew).length > 0) {
                        localStorage.setItem("fishSample", JSON.stringify(myCollectionNew));
                    }
                    else {
                        localStorage.removeItem("fishSample");
                    }
                    localStorage.setItem("fishSampleIndex", JSON.stringify(Object.keys(myCollectionNew).length - 1));
                }

                //localStorage.setItem('SampleErrors', JSON.stringify(totalErrors));
                //localStorage.setItem('Error Count', JSON.stringify(this.count));
                //localStorage.removeItem('fishSample');
                //localStorage.removeItem('fishSampleIndex');
                //localStorage.removeItem('SpeciesSampleUID');
                //localStorage.removeItem('Fish Count Response');
                //localStorage.removeItem('Fish Sample Structures Response');
                //localStorage.removeItem('Fish Sample Tags Response');
                localStorage.removeItem('PostFishSampleUID');
                //localStorage.removeItem('SpeciesGearSetting Response');
                //localStorage.removeItem('Fish Lengths Response');

                this.setState({
                    AllSamplesDone: true,
                })
            }

        }
        catch (e) {
            console.log(e);
            //alert("Authentication failed. Check if you are logged in.\n\n" + e);
            this.setState({
                CatchError: true
            });
            //let navigate = useNavigate();
            //navigate("/previousFishSamples");
        }
    }

    submitSamples(formData: FormData, sampleSettings) {
        const data = formData;
        const xhr = new XMLHttpRequest();
        xhr.open('post', 'api/PostSampleFish', false);
        xhr.setRequestHeader('Authorization', 'bearer ' + this.token);
        xhr.send(data);
        if (xhr.status === 200) {
            //this.setState({
            //    speciesSamplesResponse: JSON.parse(xhr.responseText),
            //});
            this.sampleUID = JSON.parse(xhr.responseText);
            //localStorage.setItem("SpeciesSampleUID", xhr.responseText)
        } else {
            const value1 = xhr.responseText;
            this.sampleErrors.push(value1);
            const value2 = JSON.stringify(Object.fromEntries(data));
            this.sampleErrorsData.push(value2);
            this.count = this.count + 1;
            this.failures.push({ Index: this.index, Survey: JSON.stringify(sampleSettings), Response: value1, Data: value2 })
            this.setState({
                CancelSample: true
            });
        }
    }

    submitGearSettings(formData: FormData, sampleSettings) {
        const data2 = formData;
        const xhr = new XMLHttpRequest();
        xhr.open('post', 'api/PostSampleFishGear', false);
        xhr.setRequestHeader('Authorization', 'bearer ' + this.token);
        xhr.send(data2);
        if (xhr.status === 200) {
            //this.setState({
            //    speciesGearSettingsSamples: JSON.parse(xhr.responseText)
            //});
        } else {
            const value1 = xhr.responseText;
            this.sampleErrors.push(value1);
            const value2 = JSON.stringify(Object.fromEntries(data2));
            this.sampleErrorsData.push(value2);
            this.count = this.count + 1;
            this.failures.push({ Index: this.index, Survey: JSON.stringify(sampleSettings), Response: value1, Data: value2 })
            this.setState({
                CancelSample: true
            });
        }
    }

    submitLengthFreq(formData: FormData, sampleSettings) {
        const data = formData;
        const xhr = new XMLHttpRequest();
        xhr.open('post', 'api/PostFishLengFreq', false);
        xhr.setRequestHeader('Authorization', 'bearer ' + this.token);
        xhr.send(data);
        if (xhr.status === 200) {
            //this.setState({
            //    FishLengthsSamples: JSON.parse(xhr.responseText)
            //});

            //localStorage.setItem("Fish Lengths Response", xhr.responseText);
        } else {
            const value1 = xhr.responseText;
            this.sampleErrors.push(value1);
            const value2 = JSON.stringify(Object.fromEntries(data));
            this.sampleErrorsData.push(value2);
            this.count = this.count + 1;
            this.failures.push({ Index: this.index, Survey: JSON.stringify(sampleSettings), Response: value1, Data: value2 })
            this.setState({
                CancelSample: true
            });
        }
        //if (JSON.parse(xhr.response) !== "success") {
        //    const value1 = xhr.responseText;
        //    this.sampleErrors.push(value1);
        //    const value2 = JSON.stringify(Object.fromEntries(data));
        //    this.sampleErrorsData.push(value2);
        //    this.count = this.count + 1;
        //    this.failures.push({ Index: this.index, Survey: value2, Response: value1, Data: value2 })
        //    this.setState({
        //        CancelSample: true
        //    });
        //}
    }

    submitFishCounts(formData: FormData, sampleSettings) {
        const dataCount = formData;
        const xhr = new XMLHttpRequest();
        xhr.open('post', 'api/PostFishCount', false);
        xhr.setRequestHeader('Authorization', 'bearer ' + this.token);
        xhr.send(dataCount);

        if (xhr.status === 200) {
            //this.setState({
            //    FishCountSamples: JSON.parse(xhr.responseText)
            //});

            //localStorage.setItem("Fish Count Response", xhr.responseText);
        } else {
            const value1 = xhr.responseText;
            this.sampleErrors.push(value1);
            const value2 = JSON.stringify(Object.fromEntries(dataCount));
            this.sampleErrorsData.push(value2);
            this.count = this.count + 1;
            this.failures.push({ Index: this.index, Survey: JSON.stringify(sampleSettings), Response: value1, Data: value2 })
            this.setState({
                CancelSample: true
            });
        }
        //if (JSON.parse(xhr.response) !== "success") {
        //    const value1 = xhr.responseText;
        //    this.sampleErrors.push(value1);
        //    const value2 = JSON.stringify(Object.fromEntries(dataCount));
        //    this.sampleErrorsData.push(value2);
        //    this.count = this.count + 1;
        //    this.failures.push({ Index: this.index, Survey: value2, Response: value1, Data: value2 })
        //    this.setState({
        //        CancelSample: true
        //    });
    }

    submitFishSample(formData: FormData, sampleSettings) {
        const data3 = formData;
        const xhr = new XMLHttpRequest();
        xhr.open('post', 'api/PostFish', false);
        xhr.setRequestHeader('Authorization', 'bearer ' + this.token);

        xhr.send(data3);

        if (xhr.status === 200) {
            //this.setState({
            //    SingleFishSamples: JSON.parse(xhr.responseText)
            //});

            localStorage.setItem("PostFishSampleUID", xhr.responseText);
        } else {
            const value1 = xhr.responseText;
            this.sampleErrors.push(value1);
            const value2 = JSON.stringify(Object.fromEntries(data3));
            this.sampleErrorsData.push(value2);
            this.count = this.count + 1;
            this.failures.push({ Index: this.index, Survey: JSON.stringify(sampleSettings), Response: value1, Data: value2 })
            this.setState({
                CancelSample: true
            });
        }

    }

    submitFishLink(formData: FormData, sampleSettings) {
        const data = formData;
        const xhr = new XMLHttpRequest();
        xhr.open('post', 'api/PostLink', false);
        xhr.setRequestHeader('Authorization', 'bearer ' + this.token);
        xhr.send(data);

        if (xhr.status === 200) {
            //this.setState({
            //    FishStructureSamples: JSON.parse(xhr.responseText)
            //});

            //localStorage.setItem("Fish Sample Structures Response", xhr.responseText);
        } else {
            const value1 = xhr.responseText;
            this.sampleErrors.push(value1);
            const value2 = JSON.stringify(Object.fromEntries(data));
            this.sampleErrorsData.push(value2);
            this.count = this.count + 1;
            this.failures.push({ Index: this.index, Survey: JSON.stringify(sampleSettings), Response: value1, Data: value2 })
            this.setState({
                CancelSample: true
            });
        }
        //if (JSON.parse(xhr.response) !== "success") {
        //    const value1 = xhr.responseText;
        //    this.sampleErrors.push(value1);
        //    const value2 = JSON.stringify(Object.fromEntries(data));
        //    this.sampleErrorsData.push(value2);
        //    this.count = this.count + 1;
        //    this.failures.push({ Index: this.index, Survey: value2, Response: value1, Data: value2 })
        //    this.setState({
        //        CancelSample: true
        //    });
        //}
    }

    submitFishTags(formData: FormData, sampleSettings) {
        const data5 = formData;
        const xhr = new XMLHttpRequest();
        xhr.open('post', 'api/PostFishTagMark', false);
        xhr.setRequestHeader('Authorization', 'bearer ' + this.token)
        xhr.send(data5);

        if (xhr.status === 200) {
            //this.setState({
            //    FishTagsSamples: JSON.parse(xhr.responseText)
            //});

            //localStorage.setItem("Fish Sample Tags Response", xhr.responseText);
            //} else if (JSON.parse(xhr.response) !== "success") {
            //    const value1 = xhr.responseText;
            //    this.sampleErrors.push(value1);
            //    const value2 = JSON.stringify(Object.fromEntries(data5));
            //    this.sampleErrorsData.push(value2);
            //    this.count = this.count + 1;
            //    this.failures.push({ Index: this.index, Survey: value2, Response: value1, Data: value2 });
            //    this.setState({
            //        CancelSample: true
            //    });

        } else {
            const value1 = xhr.responseText;
            this.sampleErrors.push(value1);
            const value2 = JSON.stringify(Object.fromEntries(data5));
            this.sampleErrorsData.push(value2);
            this.count = this.count + 1;
            this.failures.push({ Index: this.index, Survey: JSON.stringify(sampleSettings), Response: value1, Data: value2 });
            this.setState({
                CancelSample: true
            });
        }
    }

    render() {
        var myDate = new Date();
        var online = navigator.onLine;
        if (online) {
            fetch('https://www.google.com/', {
                mode: 'no-cors',
            }).catch(() => {
                online = false;
            })
        }
        if (!online)
            return (<Navigate to="/landingPage" />);

        if (!this.state.AllSamplesDone) {
            return <Loading />
        }
        else {
            if (this.count > 0) {
                return (
                    <div>
                        <div style={{ margin: "30px 10px 10px 10px", display: "flex", justifyContent: "space-between" }}><div><h2>There were {this.count} errors when uploading fish samples</h2></div><div><BackButton /></div></div>
                        <div style={{ border: "solid", margin: "10px", padding: "10px", backgroundColor: "gainsboro" }}>
                            <ul>
                                {this.failures.map(
                                    (data) =>
                                        <li key="{data.Index}">
                                            <div>
                                                <h2>Device Sample: {data.Index}</h2><br />
                                                <div style={{ marginBottom: "25px", backgroundColor: "white" }}>
                                                    <button type="button" className="collapsible">Survey</button>
                                                    <div style={{ height: "150px", overflowY: "auto", overflowX: "clip", width: "100%", padding: "10px" }}>
                                                        {data.Survey}
                                                    </div>
                                                    <button type="button" className="collapsible">Problem Data</button>
                                                    <div style={{ height: "150px", overflowY: "auto", overflowX: "clip", width: "100%", padding: "10px" }}>
                                                        {data.Data}
                                                    </div>
                                                    <button type="button" className="collapsible">Error Response</button>
                                                    <div style={{ height: "200px", overflowY: "auto", overflowX: "clip", width: "100%", padding: "10px" }}>
                                                        {data.Response}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                )}
                            </ul>
                        </div>
                    </div>
                );
            } else if (this.state.CatchError) {
                return (
                    <div>
                        <div style={{ margin: "30px 10px 10px 10px", display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <h2>Error Encountered.  Please Make Sure You Are Logged In.</h2>
                            </div>
                            <div>
                                <BackButton />
                            </div>
                        </div>
                    </div>
                )
            } else {
                alert("All Samples have been pushed Successfully");
                localStorage.setItem('lastFishSamplesPush', myDate.toDateString() + " " + myDate.toTimeString());
                return (<Navigate to="/landingPage" />);
            }
        }
    }
};


export function BackButton(props: any) {
    let navigate = useNavigate();
    function handleClick() {
        navigate("/previousFishSamples");
    }
    return (
        <>
            <Button className="backButton" onClick={() => handleClick()}>
                Back
            </Button>
        </>
    );
};

export default withAuth0(accessSamplesDatabase);


