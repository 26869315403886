import React from "react";
import { Table, ListGroup, Card, Container, Row, Col, Button } from 'react-bootstrap';
import './lightstyle.css';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { GetWaterQualityCollections } from './localStorageAccess';
import "react-datepicker/dist/react-datepicker.css";
import { useAuth0 } from "@auth0/auth0-react";


type WaterSampleSettings = {
    id: any,
    date: any,
    owner: any,
    station: any,
    waterbodyCode: any,
    waterbodyDescript: string,
    waterQualityComment: string,
    useCPUE: any,
    newParam: any,
    newValue: any,
    waterCollection: ParamValue[]
};

type ParamValue = {
    id: any,
    value: any
}

class PreviousWaterSamples extends React.Component {
    localStorageCollection: any;
    waterQualityCollection: WaterSampleSettings[] = []
    count: any;
    waterQualityIndex: any;
    lastData: any;
    dataExists: boolean;
    //testing
    waterQualityCollectionTest: any;
    constructor(props: any) {
        super(props);

        this.dataExists = false;
        this.waterQualityIndex = JSON.parse(localStorage.getItem('waterQualityIndex') || '{}');
        if (localStorage.getItem('waterQualityIndex')) {
            this.count = parseInt(this.waterQualityIndex);
        } else {
            this.waterQualityIndex = 0;
            this.count = 0;
        }
        this.localStorageCollection = JSON.parse(localStorage.getItem('waterQualitySettings') || '{}');
        if (localStorage.getItem('waterQualitySettings')) {
            this.dataExists = true;

            let i = 0;
            for (i; i <= this.count; i++) {
                this.waterQualityCollection[i] = this.localStorageCollection[i];
            }
        }
        this.waterQualityCollectionTest = GetWaterQualityCollections();
        localStorage.setItem("Testing", JSON.stringify(this.waterQualityCollectionTest[1]));

    }

    componentDidMount() {
        const tabletSettings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');
        return tabletSettings.theme;
    }


    render() {
        return (
            <div className="center-col">
                <Card className="cardDividerPage" data-theme={this.componentDidMount()}>
                    <Container fluid="md" className="pageContainer">
                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "15px" }}></Row>
                        <Row>
                            <Col>
                                <BackButton />
                            </Col>
                            <Col lg="auto" style={{ paddingBottom: "55px" }}>
                                <ListGroup.Item className="listPrevSamplesTitle">
                                    <h1>Previous Water Quality Inputs</h1>
                                </ListGroup.Item>


                            </Col>
                            <Col></Col>
                        </Row>
                        {/* <ListGroup horizontal>
                                <ListGroup.Item className="listPrevSamplesSubTitle">
                                    Date
                                </ListGroup.Item>
                                <ListGroup.Item className="listPrevSamplesSubTitle">
                                    Method
                                </ListGroup.Item>
                                <ListGroup.Item className="listPrevSamplesSubTitle">
                                    WBC
                                </ListGroup.Item>
                                <ListGroup.Item className="listPrevSamplesSubTitle">
                                    Station
                                </ListGroup.Item>
                            </ListGroup> */}
                        <Card className="cardDividerList">
                            <Table bordered className="prevTable">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Waterbody</th>
                                        <th scope="col">Station</th>
                                        <th scope="col">Edit</th>
                                    </tr>
                                </thead>

                                {this.dataExists ?
                                    <tbody>
                                        {!(!Array.isArray(this.waterQualityCollection) || !this.waterQualityCollection) &&
                                            this.waterQualityCollection.map(
                                                (water) =>
                                                    <tr key={water.id}>
                                                        <td> {water.date.slice(5, 7) + "/" + water.date.slice(8, 10) + "/" + water.date.slice(0, 4)}</td>
                                                        <td> {water.waterbodyCode}</td>
                                                        <td>{water.station}</td>
                                                        <td className="prevTableButton" >
                                                            <EditButton index={water.id} />
                                                        </td>
                                                    </tr>

                                            )
                                        }
                                    </tbody>
                                    :
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>There are currently no samples.</td>
                                        </tr>
                                    </tbody>
                                }


                            </Table>
                        </Card>
                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "35px" }}></Row>
                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "35px" }}>
                            <Col></Col>
                            <Col>
                                <SubmitToDatabase />
                            </Col>
                            <Col></Col>
                        </Row>

                    </Container>
                </Card>
            </div>

        );
    }
}

export function SubmitToDatabase(props: any) {
    let navigate = useNavigate();
    const { loginWithRedirect } = useAuth0();
    async function handleClick() {
        await loginWithRedirect({
            redirectUri: 'https://fincatchde.fishstaff.info/accessDatabase'
            //redirectUri: 'https://localhost:44320/accessDatabase'
        });
    }
    //function download(filename: string, text: string | number | boolean) {
    //    var element = document.createElement('a');
    //    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    //    element.setAttribute('download', filename);

    //    element.style.display = 'none';
    //    document.body.appendChild(element);

    //    element.click();

    //    document.body.removeChild(element);
    //}
    //async function handleClickExport() {
    //    navigator.clipboard.writeText(JSON.stringify(JSON.stringify(localStorage)));
    //    download("FinCatchDE_Download", JSON.stringify(JSON.stringify(localStorage)));
    //}
    //async function handleClickClear() {
    //    var result = window.confirm("Are You Sure You Want To Clear The Local Storage? All Data On The Device Will Be Removed.");
    //    if (result) {
    //        var result2 = window.confirm("Are You Really Sure? This CANNOT BE UNDONE");
    //        if (result2) {
    //            await localStorage.clear();
    //            navigate("/");
    //        }
    //    }
    //}
    //async function handleClickImport(f) {
    //var data = JSON.parse(JSON.parse(await navigator.clipboard.readText()));
    //Object.keys(data).forEach(function (k) {
    //    localStorage.setItem(k, data[k]);
    //});

    //};
    return (
            <div style={{ display: "flex" }}>
            <Button style={{ textAlign: "center", alignSelf: "center", marginRight: "15px" }} variant="secondary" className="dataSubmitButton" onClick={() => handleClick()}>
                Submit To Database
                </Button>
                {/*<Button style={{ textAlign: "center", alignSelf: "center", marginRight: "15px" }} variant="secondary" className="dataSubmitButton" onClick={() => handleClickExport()}>*/}
                {/*    Export To File*/}
                {/*</Button>*/}
                {/*<Button style={{ textAlign: "center", alignSelf: "center", marginRight: "15px" }} variant="secondary" className="dataSubmitButton" onClick={() => handleClickClear()}>*/}
                {/*    Clear Local Storage*/}
                {/*</Button>*/}
        </div>
    );

};

export async function Login() {
    const { loginWithRedirect, handleRedirectCallback } = useAuth0();
    await loginWithRedirect();
}

export function BackButton(props: any) {
    let navigate = useNavigate();
    function handleClick() {
        navigate("/");
    }
    return (
        <>
            <Button variant="secondary" className="backButton" onClick={() => handleClick()}>
                Back
            </Button>
        </>
    );
};

export function EditButton(props: any) {
    let navigate = useNavigate();
    let editIndex = props.index;
    function handleClick() {
        localStorage.setItem('editWaterQualityIndex', JSON.stringify(editIndex));
        navigate("/waterQualityInput");
    }
    return (
        <>
            <Button className="prevTableEdit" onClick={handleClick}> Edit </Button>
        </>
    );
};

export default PreviousWaterSamples;