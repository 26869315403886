import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GetWaterQualityCollections } from './localStorageAccess';
import { Navigate, useNavigate } from 'react-router-dom';
import SampleSettings from './sampleSettings';
import Loading from './loading';
import { useAuth0, withAuth0 } from "@auth0/auth0-react";
import { Button } from 'react-bootstrap';

type DatabaseAcess = {
    //waterQualitySamples: {},
    //waterQualityMeasuresSamples: {},
    //waterResultsDone: boolean,
    //waterMeasuresExist: boolean,
    //waterMeasuresResultsDone: boolean,
    //waterMeasuresFailed: boolean,
    //waterSampelsFailed: boolean,
    AllSamplesDone: boolean,
    CancelSample: boolean,
    SuccessfullSamples: any[],
    CatchError: boolean
}

type ErrorResponses = {
    Index: any,
    Survey: any,
    Response: any,
    Data: any
}

class accessDatabase extends React.Component<any, DatabaseAcess>{

    sampleErrors: any[] = [];
    sampleErrorsData: any[] = [];
    failures: ErrorResponses[] = [];
    count: any = 0;
    token: any;
    index: any = 0;
    myCollection: any[] = [];
    sampleUID: any = "";


    constructor(props: any) {
        super(props)
        this.state = {
            //waterQualitySamples: {},
            //waterQualityMeasuresSamples: {},
            //waterResultsDone: false,
            //waterMeasuresExist: false,
            //waterMeasuresResultsDone: false,
            //waterSampelsFailed: false,
            //waterMeasuresFailed: false,
            AllSamplesDone: false,
            CancelSample: false,
            SuccessfullSamples: [],
            CatchError: false
        }
    }
    componentDidMount() {
        this.submitWaterQuality();
    }

    async submitWaterQuality() {

        var token = null;
        try {
            const { getAccessTokenSilently } = this.props.auth0;
            token = await getAccessTokenSilently();

            let waterQualityCollection = GetWaterQualityCollections();
            let length = waterQualityCollection.length;
            if (length !== 0) {
                for (let i = 0; i < length; i++) {
                    this.index = i;
                    this.setState({
                        CancelSample: false
                    });
                    const ownerObj = JSON.parse(localStorage.getItem('OwnersID') || '{}');
                    let ownerGuid = "";
                    for (var prop in ownerObj) {
                        if (ownerObj.hasOwnProperty(prop)) {
                            if (ownerObj[prop] === waterQualityCollection[i].owner) {
                                ownerGuid = prop;
                            }
                        }
                    }

                    const wc = waterQualityCollection[i].waterbodyCode.split('-');
                    let code = wc[0];

                    const data = new FormData();
                    data.append('Survey', "null");
                    data.append('WaterBodyCode', code);
                    data.append('Description', waterQualityCollection[i].waterbodyDescript);
                    data.append('Date', waterQualityCollection[i].date);
                    data.append('StationCode', waterQualityCollection[i].station);
                    data.append('Comments', waterQualityCollection[i].comments);
                    data.append('Keywords', waterQualityCollection[i].keywords);
                    data.append('Owner', ownerGuid);

                    const xhr = new XMLHttpRequest();
                    xhr.open('post', 'api/PostWaterQ', false);
                    xhr.setRequestHeader('Authorization', 'bearer ' + token);
                    xhr.onload = () => this.handleLoadWqSamplesDone(xhr, data, waterQualityCollection[i]);
                    xhr.send(data);

                    if (this.state.CancelSample != true) {

                        let wqCollectionlength = waterQualityCollection[i].waterCollection.length;
                        if (wqCollectionlength !== 0) {
                            //this.setState({
                            //    waterMeasuresExist: true,
                            //})
                            //await new Promise(f => setTimeout(f, 1000));

                            for (let j = 0; j < wqCollectionlength; j++) {

                                //let surveyUID = JSON.parse(localStorage.getItem("sampleUID") || '{}');
                                const paramObj = JSON.parse(localStorage.getItem('WQParameters') || '{}');
                                let paramID = "";
                                for (var param in paramObj) {
                                    if (paramObj[param] === waterQualityCollection[i].waterCollection[j].id) {
                                        paramID = param;
                                    }
                                }
                                const data2 = new FormData();
                                data2.append('Foreign', this.sampleUID.toString());
                                data2.append('Depth', waterQualityCollection[i].waterCollection[j].depth);
                                data2.append('Parameter', paramID);
                                data2.append('Value', waterQualityCollection[i].waterCollection[j].value);
                                data2.append('Comments', waterQualityCollection[i].waterCollection[j].comment);
                                const xhr2 = new XMLHttpRequest();
                                xhr2.open('post', 'api/PostSampleWqMeasure', false);
                                xhr2.setRequestHeader('Authorization', 'bearer ' + token);
                                xhr2.onload = () => this.handleLoadWqMeasuresDone(xhr2, data, waterQualityCollection[i]);
                                xhr2.send(data2);
                            }
                        }
                    }

                    if (!this.state.CancelSample) {
                        this.state.SuccessfullSamples.push(this.index)
                    }
                    //end of wq samples

                }
                //Remove data for successful pushes
                if (this.state.SuccessfullSamples.length > 0) {

                    let myCollection = JSON.parse(localStorage.getItem('waterQualitySettings') || '{}');
                    let myCollectionNew = Object.assign({}, myCollection);
                    myCollectionNew = {};
                    var numberOfSamples = Object.keys(myCollection).length;
                    var newIndex = 0;
                    for (var mySampleIndex = 0; mySampleIndex < numberOfSamples; mySampleIndex++) {
                        if (this.state.SuccessfullSamples.includes(mySampleIndex)) {
                        } else {
                            myCollection[mySampleIndex].id = newIndex;
                            myCollectionNew[newIndex] = myCollection[mySampleIndex];
                            //increment index
                            newIndex = newIndex + 1;
                        }
                    }
                    localStorage.setItem("waterQualitySettings", JSON.stringify(myCollectionNew));
                    localStorage.setItem("waterQualityIndex", JSON.stringify(Object.keys(myCollectionNew).length - 1));
                }

                this.setState({
                    AllSamplesDone: true,
                })
            }
        }

        catch (e) {
            console.log(e);
            this.setState({
                CatchError: true
            });
        }
    }
    handleLoadWqSamplesDone = (e: XMLHttpRequest, data, sample) => {
        if (e.status === 200) {
            this.sampleUID = JSON.parse(e.responseText);
        }
        else {
            const value1 = e.responseText;
            this.sampleErrors.push(value1);
            const value2 = JSON.stringify(Object.fromEntries(data));
            this.sampleErrorsData.push(value2);
            this.count = this.count + 1;
            this.failures.push({ Index: this.index, Survey: JSON.stringify(sample), Response: value1, Data: value2 })
            this.setState({
                CancelSample: true
            });
        }
    }

    handleLoadWqMeasuresDone = (e: XMLHttpRequest, data, sample) => {
        if (e.status === 200) {
            }
        else {
            const value1 = e.responseText;
            this.sampleErrors.push(value1);
            const value2 = JSON.stringify(Object.fromEntries(data));
            this.sampleErrorsData.push(value2);
            this.count = this.count + 1;
            this.failures.push({ Index: this.index, Survey: JSON.stringify(sample), Response: value1, Data: value2 })
            this.setState({
                CancelSample: true
            });
        }

    }

    render() {
        var myDate = new Date();
        var online = navigator.onLine;
        if (online) {
            fetch('https://www.google.com/', {
                mode: 'no-cors',
            }).catch(() => {
                online = false;
            })
        }
        if (!online)
            return (<Navigate to="/landingPage" />);

        if (!this.state.AllSamplesDone) {
            return <Loading />
        }
        else {
            if (this.count > 0) {
                return (
                    <div>
                        <div style={{ margin: "30px 10px 10px 10px", display: "flex", justifyContent: "space-between" }}><div><h2>There were {this.count} errors when uploading fish samples</h2></div><div><BackButton /></div></div>
                        <div style={{ border: "solid", margin: "10px", padding: "10px", backgroundColor: "gainsboro" }}>
                            <ul>
                                {this.failures.map(
                                    (data) =>
                                        <li key="{data.Index}">
                                            <div>
                                                <h2>Device Sample: {data.Index}</h2><br />
                                                <div style={{ marginBottom: "25px", backgroundColor: "white" }}>
                                                    <button type="button" className="collapsible">Survey</button>
                                                    <div style={{ height: "150px", overflowY: "auto", overflowX: "clip", width: "100%", padding: "10px" }}>
                                                        {data.Survey}
                                                    </div>
                                                    <button type="button" className="collapsible">Problem Data</button>
                                                    <div style={{ height: "150px", overflowY: "auto", overflowX: "clip", width: "100%", padding: "10px" }}>
                                                        {data.Data}
                                                    </div>
                                                    <button type="button" className="collapsible">Error Response</button>
                                                    <div style={{ height: "200px", overflowY: "auto", overflowX: "clip", width: "100%", padding: "10px" }}>
                                                        {data.Response}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                )}
                            </ul>
                        </div>
                    </div>
                );
            } else if (this.state.CatchError) {
                return (
                    <div>
                        <div style={{ margin: "30px 10px 10px 10px", display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <h2>Error Encountered.  Please Make Sure You Are Logged In.</h2>
                            </div>
                            <div>
                                <BackButton />
                            </div>
                        </div>
                    </div>
                )
            } else {
                alert("All Samples have been pushed Successfully");
                localStorage.setItem('lastWaterQualitySamplesPush', myDate.toDateString() + " " + myDate.toTimeString());
                return (<Navigate to="/landingPage" />);
            }
        }
    }

};

export function BackButton(props: any) {
    let navigate = useNavigate();
    function handleClick() {
        navigate("/previousWaterSamples");
    }
    return (
        <>
            <Button className="backButton" onClick={() => handleClick()}>
                Back
            </Button>
        </>
    );
};

export default withAuth0(accessDatabase);
