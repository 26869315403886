import React from "react";
import { Container, Card, Row, Col, Button, ListGroup} from 'react-bootstrap';
import { useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './lightstyle.css';
import Select from 'react-select';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { FALSE } from "sass";


// const Button = styled.button
//     `
//   background-color: lightgrey;
//   font-size: 30px;
//   color: black;
//   height: 150px;
//   width: 375px;
//   margin: 0px 30px;
//   border-radius: 5px;
//   outline: 0;
//   cursor: pointer;
// `;


type fishSelection = {
    fishCodeAndName: any,
    speciesSelection: any[],
    selectedOption: any,
    validCode: boolean,
    notDuplicate: boolean
}

const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        background: "var(--button) !important",
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        borderColor: state.isFocused ? "blue" : "black",
        height: "70px",
        width: "100%",
        color: "var(--text-primary) !important",
        fontSize: "30px",
        testDecorationColor: "white",
        whiteSpace: "nowrap",
        overflow: "hidden",
        boxShadow: state.isFocused ? null : null,

        "&:hover": {
            borderColor: state.isFocused ? "white" : "black"
        }

    }),
    value: (base: any) => ({
        ...base,
        color: "var(--text-primary) !important",
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "var(--text-primary) !important",
        fontWeight: 400
    }),
    menu: (base: any, state: any) => ({
        ...base,
        color: state.isSelected ? "white" : "black",
        fontSize: "30px",
        borderRadius: 0,
        marginTop: 0
    }),
    menuList: (base: any, state: any) => ({
        ...base,
        color: state.isSelectd ? "white" : "black",
        padding: 0
    })
};

const customStylesInvalid = {
    control: (base: any, state: any) => ({
        ...base,
        background: "var(--button) !important",
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        borderWidth: "2px",
        borderColor: state.isFocused ? "blue" : "red",
        margin: "0px 30px",
        length: "80%",
        width: "90%",
        height: "70px",
        color: "var(--text-primary) !important",
        fontSize: "30px",
        testDecorationColor: "white",
        boxShadow: state.isFocused ? null : null,

    }),
    input: (base: any) => ({
        ...base,
        color: "var(--text-primary)"
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        color: "var(--text-primary) !important",
        fontSize: "30px",
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "var(--text-primary)",
        fontWeight: 400
    }),
    menu: (base: any, state: any) => ({
        ...base,
        color: state.isSelected ? "white" : "black",
        fontSize: "30px",
        borderRadius: 0,
        marginTop: 0,
        width: "90%",
        margin: "0px 0px",
    }),
    menuList: (base: any, state: any) => ({
        ...base,
        color: state.isSelectd ? "white" : "black",
        margin: "0px 0px",
    })
};

const tabletSettings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');

class FishSpeciesSelection extends React.Component<any, fishSelection> {

    fishSpecies: any; //use to get cached data from database in local storage
   fishSelectionCollection: fishSelection[];
   fishSelectionIndex: any;
    count: any;
    editFishIndex: any;
    fishCollection: any[];
    currentSpecies: any[];
    hideBack: boolean = false;
    

    constructor(props:any){
        super(props);

        this.setValidCode = this.setValidCode.bind(this);
        this.setValidDup = this.setValidDup.bind(this);

        this.fishSelectionIndex = JSON.parse(localStorage.getItem('fishSampleIndex') || '{}');
        this.editFishIndex = JSON.parse(localStorage.getItem('editFishSampleIndex') || '{}');
        if(localStorage.getItem("editFishSampleIndex")){
            this.fishSelectionIndex = this.editFishIndex;
            this.count = this.editFishIndex;
        }
        else if (localStorage.getItem('fishSampleIndex')) {
            this.count = this.fishSelectionIndex; //if an index exists
        } else {
            this.count = 0;
        }
        this.fishCollection = JSON.parse(localStorage.getItem('fishSample') || '{}');
        if (localStorage.getItem('fishSample')) {
            this.currentSpecies = this.fishCollection[this.count].speciesData;
            if (this.currentSpecies.length === 0) { this.hideBack = true};
        } else {
            this.currentSpecies = [];
        }

        // if(localStorage.getItem('fishSelectionIndex')){
        //     this.count = this.fishSelectionIndex;
        // }  else{
        //     this.count = 1;
        //     this.fishSelectionIndex = 1;
        // }

        const speciesObj = JSON.parse(localStorage.getItem('SpeciesNames') || '{}');
        const species: string[] = [];
        Object.keys(speciesObj).map((key) => { species.push(key + "-" + speciesObj[key]) });

        //console.log(speciesObj);
 
        this.state = {
            fishCodeAndName: '',
            speciesSelection: species,
            selectedOption: '',
            validCode: true,
            notDuplicate: true,
        }

       this.fishSelectionCollection = JSON.parse(localStorage.getItem('fishSelection') || '{}');
    }


    handleChange = (selectedOption: any) => {
        this.setState({selectedOption})
        this.setState({
            fishCodeAndName: selectedOption.value.toString(),
        })
    }

    setValidCode(v: boolean){
        this.setState({
            validCode: v
        })
    }

    setValidDup(v: boolean){
        this.setState({
            notDuplicate: v
        })
    }

    componentDidMount() {
        const tabletSettings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');
        return tabletSettings.theme;
    }

    render() {
        return (
            <div className="genStatsWrapper" data-theme={this.componentDidMount()}>
                <Card className="cardDivider">
                    <Container className="fluid=md">
                        <Row style={{textAlign: "center" ,alignSelf:"center", paddingBottom:"15px"}}></Row>
                    <Row>
                            <Col>
                                {this.hideBack ? "" :
                                    <BackButton/>
                                }                                    
                        </Col>
                        <Col lg="auto" style={{ paddingBottom: "55px" }}>
                        <ListGroup.Item className="genStatsTitle">
                        <h1>Fish Species Selection</h1>
                        </ListGroup.Item>
                        </Col>
                        <Col></Col>
                    </Row>

                    <Row style={{ paddingBottom: "10px" }}>
                        <Col></Col>

                        <Col xs={7} >
                            <Select styles={(this.state.validCode && this.state.notDuplicate) === true ? customStyles : customStylesInvalid} onChange={this.handleChange}  placeholder={"Select Species..."} options={this.state.speciesSelection.map((title) => { return { value: title, label: title } })} />
                            {this.state.validCode ? <></>  :
                            <label className="invalidLabel"> Provide a Species Code!
                            </label> }
                            {this.state.notDuplicate ? <></>  :
                            <label className="invalidLabel"> This species has already been added!
                            </label> }
                            </Col>
                        <Col>
                            <AddSpeciesButton validDup={this.setValidDup} validCode={this.setValidCode} state={this.state.fishCodeAndName} species={this.currentSpecies} fishCollection={this.fishCollection} index={this.count} collection={this.fishSelectionCollection}></AddSpeciesButton>
                        </Col>
                    </Row>
                    </Container>
                </Card>
            </div>
        );
    }
}




export function AddSpeciesButton(props: any){
    let navigate = useNavigate();
    const num = props.index;
    const fishSelectionCollection: any[] = props.fishCollection;
    function handleSubmit(){
        if(props.state === ''){
            props.validCode(false);
        } else if(props.species.length !== 0 && props.species.find((element: any) => element === props.state) !== undefined){
            props.validDup(false);
        }
        else {
            const data = [...props.species, props.state];
            fishSelectionCollection[num].speciesData = data;
            localStorage.setItem('fishSample', JSON.stringify(fishSelectionCollection));
            //', JSON.stringify(fishSelectionCollection));
             navigate("/dataEntryPage");
        }
    }
    return(
        <Button bsPrefix="entry-button2" onClick={() => handleSubmit()}>
        Add Species
    </Button>
    )
}


export function BackButton(props: any){
    let navigate = useNavigate();
    function handleClick() {
                navigate("/dataEntryPage");
      }
     return (
    <>
        <Button variant="secondary" className="backButton" onClick={() => handleClick()}>
        Back
    </Button>
    </>
    );
};

export default FishSpeciesSelection;
