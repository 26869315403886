import React from "react";
import { Table, ListGroup, Card, Container, Row, Col, Button } from 'react-bootstrap';
import './lightstyle.css';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import { useAuth0 } from "@auth0/auth0-react";


type FishSampleSettings = {
    id: any,
    date: any,
    owner: string,
    method: string,
    station: string,
    waterbodyCode: string,
    waterbodyDescript: string,
    keywords: string,
    comments: string,
    electricSetting: string,
    electricValue: string,
    electricSettingsCollection: gearSettings[],
    useCPUE: string
};

type gearSettings = {
    id: string,
    value: string
}


class PreviousFishSamples extends React.Component<any, FishSampleSettings> {
    settingsData: any;
    count: number;
    fishSampleIndex: number;
    fishSampleDataCollection: FishSampleSettings[];
    localStorageCollection: any[];
    dataExists: boolean;

    constructor(props: any) {
        super(props);
        this.dataExists = false;
        this.fishSampleDataCollection = [];
        this.fishSampleIndex = JSON.parse(localStorage.getItem('fishSampleIndex') || '{}');
        if (localStorage.getItem('fishSampleIndex')) {
            this.count = this.fishSampleIndex;
        } else {
            this.count = 0;
        }
        this.localStorageCollection = JSON.parse(localStorage.getItem('fishSample') || '{}');
        if (localStorage.getItem('fishSample')) {
            this.dataExists = true;

            let i = 0;
            for (i; i <= this.count; i++) {
                this.fishSampleDataCollection[i] = this.localStorageCollection[i].settings;
            }
        }
    }

    componentDidMount() {
        const tabletSettings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');
        return tabletSettings.theme;
    }

    render() {
        return (
            <div className="center-col">
                <Card className="cardDividerPage" data-theme={this.componentDidMount()}>
                    <Container fluid="md" className="pageContainer">
                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "15px" }}></Row>
                        <Row>
                            <Col>
                                <BackButton />
                            </Col>
                            <Col lg="auto" style={{ paddingBottom: "55px" }}>
                                <ListGroup.Item className="listPrevSamplesTitle">
                                    <h1>Previous Fish Samples</h1>
                                </ListGroup.Item>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Card className="cardDividerList">
                            <Table bordered className="prevTable">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Method</th>
                                        <th scope="col">Waterbody</th>
                                        <th scope="col">Station</th>
                                        <th scope="col">Edit</th>
                                    </tr>
                                </thead>
                                {this.dataExists ?
                                    <tbody>

                                        {!(!Array.isArray(this.fishSampleDataCollection) || !this.fishSampleDataCollection)
                                            &&
                                            this.fishSampleDataCollection.map(
                                                (fish) =>

                                                    <tr key={fish.id}>
                                                        <td> {fish.date.slice(5, 7) + "/" + fish.date.slice(8, 10) + "/" + fish.date.slice(0, 4)}</td>
                                                        <td>{fish.method}</td>
                                                        <td> {fish.waterbodyCode}</td>
                                                        <td>{fish.station}</td>
                                                        <td className="prevTableButton" >
                                                            <FishSampleList fish={fish} index={fish.id} />
                                                        </td>
                                                    </tr>
                                            )
                                        }
                                    </tbody>
                                    :
                                    <tbody>
                                        <tr>
                                            <td colSpan={5}>There are currently no samples.</td>
                                        </tr>
                                    </tbody>
                                }
                            </Table>
                        </Card>
                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "30px" }}></Row>
                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "35px" }}>
                            <Col></Col>
                            <Col>
                                <SubmitToDatabase />
                            </Col>
                            <Col></Col>
                        </Row>

                    </Container>
                </Card>
            </div>

        );
    }
}

export function SubmitToDatabase(props: any) {
    let navigate = useNavigate();
    const { loginWithRedirect } = useAuth0();
    async function handleClick() {
        await loginWithRedirect({
            redirectUri: 'https://fincatchde.fishstaff.info/accessSamplesDatabase'
            //redirectUri: 'https://localhost:44320/accessSamplesDatabase'
        });
    }
    //function download(filename: string, text: string | number | boolean) {
    //    var element = document.createElement('a');
    //    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    //    element.setAttribute('download', filename);

    //    element.style.display = 'none';
    //    document.body.appendChild(element);

    //    element.click();

    //    document.body.removeChild(element);
    //}
    //async function handleClickExport() {
    //    navigator.clipboard.writeText(JSON.stringify(JSON.stringify(localStorage)));
    //    download("FinCatchDE_Download", JSON.stringify(JSON.stringify(localStorage)));
    //}
    //async function handleClickClear() {
    //    var result = window.confirm("Are You Sure You Want To Clear The Local Storage? All Data On The Device Will Be Removed.");
    //    if (result) {
    //        var result2 = window.confirm("Are You Really Sure? This CANNOT BE UNDONE");
    //        if (result2) {
    //            await localStorage.clear();
    //            navigate("/");
    //        }
    //    }
    //}
    //async function handleClickImport(f) {
        //var data = JSON.parse(JSON.parse(await navigator.clipboard.readText()));
        //Object.keys(data).forEach(function (k) {
        //    localStorage.setItem(k, data[k]);
        //});

    //};
    return (
        <div style={{ display: "flex"}}>
            <Button style={{ textAlign: "center", alignSelf: "center", marginRight: "15px" }} variant="secondary" className="dataSubmitButton" onClick={() => handleClick()}>
                Submit To Database
            </Button>
            {/*<Button style={{ textAlign: "center", alignSelf: "center", marginRight: "15px" }} variant="secondary" className="dataSubmitButton" onClick={() => handleClickExport()}>*/}
            {/*    Export To File*/}
            {/*</Button>*/}
            {/*<Button style={{ textAlign: "center", alignSelf: "center", marginRight: "15px" }} variant="secondary" className="dataSubmitButton" onClick={() => handleClickClear()}>*/}
            {/*    Clear Local Storage*/}
            {/*</Button>*/} 
        </div>
    );
};

export function BackButton(props: any) {
    let navigate = useNavigate();
    function handleClick() {
        navigate("/");
    }
    return (
        <>
            <Button className="backButton" onClick={() => handleClick()}>
                Back
            </Button>
        </>
    );
};

export function FishSampleList(props: any) {
    let navigate = useNavigate();
    let fish = props.fish;
    let editIndex = props.index;
    function handleClick() {

        localStorage.setItem('editFishSampleIndex', JSON.stringify(editIndex));

        navigate("/dataEntryPage");
    }
    return (
        <>
            <Button variant="secondary" className="prevTableEdit" onClick={handleClick}> Edit </Button>
        </>
    );
};

export default PreviousFishSamples;