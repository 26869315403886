import React, { useState } from "react";
import { Stack, InputGroup, Card, Container, Row, Col, Form, Button } from 'react-bootstrap';
import './lightstyle.css';
import {useNavigate} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

export function SettingList(props: any) {
    return (
        <>
            <Row style={{ paddingBottom: "30px" }}>
                <Col>
                    <Button variant="secondary" className="listButtons" disabled>{props.id}</Button>
                </Col>
                <Col>
                    <Button variant="secondary" className="listValueButton" disabled>{props.value}</Button>
                    <Button className="minusButton" variant="secondary">-</Button>
                </Col>
            </Row>
        </>
    )
}


type FishSampleSettings = {
    id: any,
    date: any,
    owner: string,
    method: string,
    station: string,
    waterbodyCode: string,
    waterbodyDescript: string,
    keywords: string,
    comments: string,
    electricSetting: string,
    electricValue: string,
    electricSettingsCollection: gearSettings[],
    useCPUE: boolean,
    effort: any,
    duration: any,
    endTime: any,
};


type gearSettings = {
    id: string,
    value: string
}

type SingleSample = {
    settings: FishSampleSettings,
    speciesData: any
}

/*
type SampleInfo = {
    waterbodyCode: any,
    owner: any,
}
*/

const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        background: "var(--button) !important",
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        borderColor: state.isFocused ? "blue" : "black",
        padding: "0px 0px 0px 0px",
        margin: "0px 30px",
        length: "80%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "90%",
        height: "70px",
        color: "var(--text-primary) !important",
        fontSize: "30px",
        testDecorationColor: "white",
        boxShadow: state.isFocused ? null : null,

    }),
    input: (base: any) => ({
        ...base,
        color: "var(--text-primary) !important"
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        color: "var(--text-primary) !important",
        fontSize: "30px",
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "var(--text-primary) !important",
        fontWeight: 400
    }),
    menu: (base: any, state: any) => ({
        ...base,
        color: state.isSelected ? "white" : "black",
        fontSize: "30px",
        borderRadius: 0,
        marginTop: 0,
        width: "90%",
        margin: "0px 0px",
    }),
    menuList: (base: any, state: any) => ({
        ...base,
        color: state.isSelectd ? "white" : "black",
        margin: "0px 0px",
    })
};

const customStylesInvalid = {
    control: (base: any, state: any) => ({
        ...base,
        background: "var(--button) !important",
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        borderWidth: "2px",
        borderColor: state.isFocused ? "blue" : "red",
        margin: "0px 30px",
        length: "80%",
        width: "90%",
        height: "70px",
        color: "var(--text-primary) !important",
        fontSize: "30px",
        testDecorationColor: "white",
        boxShadow: state.isFocused ? null : null,

    }),
    input: (base: any) => ({
        ...base,
        color: "var(--text-primary) !important"
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        color: "var(--text-primary) !important",
        fontSize: "30px",
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "var(--text-primary) !important",
        fontWeight: 400
    }),
    menu: (base: any, state: any) => ({
        ...base,
        color: state.isSelected ? "white" : "black",
        fontSize: "30px",
        borderRadius: 0,
        marginTop: 0,
        width: "90%",
        margin: "0px 0px",
    }),
    menuList: (base: any, state: any) => ({
        ...base,
        color: state.isSelectd ? "white" : "black",
        margin: "0px 0px",
    })
};


class SampleSettings extends React.Component<any, FishSampleSettings> {

    settingsData: any;
    count: number;
    fishSampleIndex: number;
    fishSampleDataCollection: SingleSample[];
    ownersList: any [];
    waterCodesList: any[];
    methodsList: any[];
    gearsList: any[];
    durationInput: any;
    currDate:any;

    constructor(props: any) {
        super(props);
       // this.currDate = new Date().toString();
        //information for dropdowns
        const ownersObj = JSON.parse(localStorage.getItem('OwnersNames') || '{}');
        const owners: string[] = [];
        Object.keys(ownersObj).map((key) => {
            owners.push(ownersObj[key] + " " + key);
        });
        const watersObj = JSON.parse(localStorage.getItem('WaterCodes') || '{}');
        const waters: string[] = [];
        Object.keys(watersObj).map((key) => {
            waters.push(key + "-" + watersObj[key]);
        });
        const methodsObj = JSON.parse(localStorage.getItem('Methods&Inputs') || '{}');
        const methods: string[] = [];
        Object.keys(methodsObj).map((key) => {
            methods.push(key);
        });
        const gearsObj = JSON.parse(localStorage.getItem('GearSettingsCodes') || '{}');
        const gears: string[] = [];
        Object.keys(gearsObj).map((key) => {
            gears.push(key + '-' + gearsObj[key]);
        })
        let date: Date = new Date();
        //months are 0 indexed
        let month = date.getMonth() < 9 ? "0" + String(date.getMonth()+1) : String(date.getMonth()+1);
        let day = date.getDate() < 10 ? "0" + String(date.getDate()) : String(date.getDate());
        this.currDate = String(date.getFullYear()) + "-" + month + "-" + day;
        this.ownersList = owners;
        this.waterCodesList = waters;
        this.methodsList = methods;
        this.gearsList = gears;
        this.durationInput = "";
        
        this.fishSampleIndex = JSON.parse(localStorage.getItem('fishSampleIndex') || '{}');
        if (localStorage.getItem('fishSampleIndex')) {
            this.count = this.fishSampleIndex + 1;
        } else {
            this.fishSampleIndex = 0;
            this.count = 0;
        }
        this.fishSampleDataCollection = JSON.parse(localStorage.getItem('fishSample') || '{}');
        if (localStorage.getItem('fishSample')) {
            this.settingsData = this.fishSampleDataCollection[this.fishSampleIndex].settings;
            this.state = {
                id: this.count,
                date: this.currDate,
                owner: this.settingsData.owner,
                method: this.settingsData.method,
                station: '',
                waterbodyCode: this.settingsData.waterbodyCode,
                waterbodyDescript: '',
                keywords: '',
                comments: '',
                electricSetting: '',
                electricValue: '',
                electricSettingsCollection: [],
                useCPUE: this.settingsData.useCPUE,
                effort: '1',
                duration: '',
                endTime: ''
            }
        } else {
            this.state = {
                id: this.count,
                date: this.currDate,
                owner: '',
                method: '',
                station: '',
                waterbodyCode: '',
                waterbodyDescript: '',
                keywords: '',
                comments: '',
                electricSetting: '',
                electricValue: '',
                electricSettingsCollection: [],
                useCPUE: true,
                effort: '1',
                duration: '',
                endTime: ''
            };
        }
        
    }


    componentDidMount() {
        const tabletSettings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');
        return tabletSettings.theme;
    }

    render() {
        return (
            <div className="center-col" data-theme={this.componentDidMount()}>
                
                 <Container fluid="md">
                <Row style={{ paddingBottom: "20px" }}></Row>

                    <Stack gap={2} direction="horizontal" className="topRow" > 
                         <InputGroup>
                            <BackButton />
                            <div className="pageTitle">Sample Metadata</div>
                        </InputGroup>
                    </Stack>
                    <FormExample state={this.state} index={this.count} collection={this.fishSampleDataCollection}></FormExample>
                    </Container>
            </div>
        );
    }
}

export function ValidateForm(props: any){
    const [validDate, setValidDate] = useState(false);
    const [validOwner, setValidOwner] = useState(false);
    const [validStation, setValidStaion] = useState(false);
    const [validWaterbodyCode, setValidWaterbodyCode] = useState(false);
    

}

export function ButtonSubmit(props: any){
    let navigate = useNavigate();
    const data = props.state;
    const num = props.index;
    const s: SingleSample = { settings: data, speciesData: '' };
    const collection: SingleSample[] = props.collection;
    function handleClick() {
        collection[num] = s;
        localStorage.setItem('fishSampleIndex', JSON.stringify(num));
       // localStorage.setItem('fishSampleIndex', JSON.stringify(num));
        localStorage.setItem('fishSample', JSON.stringify(collection));
        navigate("/fishSpeciesSelection");
      }
     return (
    <>
    <Button variant="secondary" className="submitButton" onClick={() => handleClick()}>
        Start Sample
    </Button>
    </>
    );
};

export function BackButton(props: any){
    let navigate = useNavigate();
    function handleClick() {
        navigate("/");
      }
     return (
    <>
        <Button variant="secondary" className="backButton" onClick={() => handleClick()}>
        Back
    </Button>
    </>
    );
};

function FormExample(props: any) {
    let navigate = useNavigate();
    const data = props.state;
    const num = props.index;
    const collection: SingleSample[] = props.collection;
    const [id] = useState(data.id);
    const [sampleData, setSampleData] = useState(data as FishSampleSettings);
    const [date, setDate] = useState(data.date);
    const [owner, setOwner] = useState(data.owner);
    const [method, setMethod] = useState(data.method);
    const [effort, setEffort] = useState(data.effort);
    const [duration, setDuration] = useState(data.duration);
    const [endTime, setEndtime] = useState(data.endTime);
    const [station, setStation] = useState(data.station);
    const [waterbodyCode, setWaterbodyCode] = useState(data.waterbodyCode);
    const [waterbodyDescript, setWaterbodyDescript] = useState(data.waterbodyDescript);
    const [keywords, setKeywords] = useState(data.keywords);
    const [comments, setComments] = useState(data.comments);
    const [useCPUE, setUseCPUE] = useState(true);
    const [electricSettingsCollection, setElectricSettingsCollection] = useState(data.electricSettingsCollection);
    const [electricSetting, setElectricSetting] = useState(data.electricSetting);
    const [electricValue, setElectricValue] = useState(data.electricValue);

    const [validated, setValidated] = useState(false);
    const [validOwner, setValidOwner] = useState(true);
    const [validMethod, setValidMethod] = useState(true);
    const [validWaterbodyCode, setValidWaterbodyCode] = useState(true);
    const [durationInput, setDurationInput] = useState("");
    const ownersObj = JSON.parse(localStorage.getItem('OwnersNames') || '{}');
    const owners: string[] = [];
    Object.keys(ownersObj).map((key) => {
        owners.push(ownersObj[key] + " " + key);
    });
    const watersObj = JSON.parse(localStorage.getItem('WaterCodes') || '{}');
    const waters: string[] = [];
    Object.keys(watersObj).map((key) => {
        waters.push(key + "-" + watersObj[key]);
    });
    const methodsObj = JSON.parse(localStorage.getItem('Methods&Inputs') || '{}');
    const methods: string[] = [];
    Object.keys(methodsObj).map((key) => {
        methods.push(key);
    });
    const gearsObj = JSON.parse(localStorage.getItem('GearSettingsCodes') || '{}');
    const gears: string[] = [];
    Object.keys(gearsObj).map((key) => {
        gears.push(key + "-" + gearsObj[key]);
    })
    // ownersList = owners;
    // waterCodesList = waters;
    // this.methodsList = methods;
    // this.gearsList = gears;

    function onDateChange(e: any) {
        sampleData.date = e.value;
        setSampleData(sampleData);
    }

    function onChangeOwner(e: any) {
        setOwner(e.value);
    }

    function onChangeMethod (selected: any) {
        setMethod(selected.value);
        Object.keys(methodsObj).forEach(key => {
            if (key === selected.value) {
                setDurationInput(methodsObj[key].toString())
            }
        })      
    }

    function onChangeWaterCode (selected: any) {
        setWaterbodyCode(selected.value);
    }

    function onChangeElectricSetting(e: any) {
        setElectricSetting(e.value);
    }

    function onChangeElectricValue(e: any) {
        e.preventDefault(); 
        const value = e.target.value;
       // if (!isNaN(+value)) {
            setSampleData(sampleData);
      //  }
    }

    function onCheckedboxValue(e: any) {
        setUseCPUE(e.target.checked);
    }

    function onDeleteButton(id: any) {
        const newList = electricSettingsCollection.filter((item : gearSettings) => item.id !== id);
        setElectricSettingsCollection(newList);
    }

    function onAddButton(e: any) {

        if (electricSetting !== '' && electricValue !== '') {
            const i = electricSettingsCollection.findIndex((obj : gearSettings) => obj.id === electricSetting);
            if (i >= 0) {
                    let newList = [
                        ...electricSettingsCollection.slice(0, i),
                        {
                            ...electricSettingsCollection[i],
                            value: electricValue,
                        },
                        ...electricSettingsCollection.slice(i + 1)
                    ]
                    setElectricSettingsCollection(newList);
            } else {
                let newList = [...electricSettingsCollection, { id: electricSetting, value: electricValue }];
                setElectricSettingsCollection(newList);
            }

            setElectricSetting('');
            setElectricValue('');
          
        }

    }

    const handleSubmit = (event : any) => {
        const form = event.currentTarget;
        let validO:boolean = false;
        let validW:boolean = false;
        let validM:boolean = false;
        if(owner === ""){
            setValidOwner(false);
        } else {
            setValidOwner(true);
            validO = true;
        }
        if(waterbodyCode === ""){
            setValidWaterbodyCode(false);
        } else {
            setValidWaterbodyCode(true);
            validW = true;
        }
        if(method === ""){
            setValidMethod(false);
        } else {
            setValidMethod(true);
            validM = true;
        }
        if (form.checkValidity() === false || !(validO && validW && validM)) {
          event.preventDefault();
          event.stopPropagation();
        }        
        else if (form.checkValidity() === true && (validO && validW && validM)) {
            const newSettings = {
                id: id,
                date: date,
                owner: owner,
                method: method,
                station: station,
                waterbodyCode: waterbodyCode,
                waterbodyDescript: waterbodyDescript,
                keywords: keywords,
                comments: comments,
                electricSetting: electricSetting,
                electricValue: electricValue,
                electricSettingsCollection: electricSettingsCollection,
                useCPUE: useCPUE,
                effort: effort,
                duration: duration,
                endTime: endTime,
            }
            const s: SingleSample = { settings: newSettings, speciesData: '' };
            collection[num] = s;
            localStorage.setItem('fishSampleIndex', JSON.stringify(num));
               // localStorage.setItem('fishSampleIndex', JSON.stringify(num));
            localStorage.setItem('fishSample', JSON.stringify(collection));
            navigate("/fishSpeciesSelection");
    
        
      }
      setValidated(true);
    };
  
    return (
        <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row style={{ paddingTop:"55px", paddingBottom: "35px" }}>               
          <Form.Group as={Col} controlId="validationDate">
            <Form.Label className="labelD">Date *</Form.Label>
            <input
             // value="2017-06-01"
              type="date"
              className="numberOnlyText"
            value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="validateOwner">
            <Form.Label className="labelD">Owner *</Form.Label>
            <Select styles={validOwner ? customStyles : customStylesInvalid} onChange={(e) => onChangeOwner(e)}  
            placeholder={owner === "" ? "Choose Owner...": owner} 
            options={owners.map((title : any) => { return { value: title, label: title } })} />
            {validOwner ? <></>  :
               <Form.Label className="invalidLabel" > Provide an owner!
               </Form.Label> 
            }
          </Form.Group>
        </Row>

        <Row style={{ paddingBottom: "35px" }}>
          <Form.Group as={Col} controlId="validateMethod">
            <Form.Label className="labelD">Method *</Form.Label>
            <Select styles={validMethod ? customStyles : customStylesInvalid} onChange={(e) => onChangeMethod(e)} 
            placeholder={method === "" ? "Choose Method..." : method} 
            options={methods.map((title : any) => { return { value: title, label: title } })} />
                {validMethod ? <></>  :
               <Form.Label className="invalidLabel" > Provide a method!
               </Form.Label> 
            }
          </Form.Group>

          <Form.Group as={Col} controlId="effort">
            <Form.Label className="labelD">Effort</Form.Label>
            <Form.Control as="input" type="number" 
            inputMode="numeric" enterKeyHint="done" name="effortValue" 
            className="numberOnlyText" pattern="[0-9]*"
             onChange={(e) => setEffort(e.target.value)} 
             value={effort}/>
          </Form.Group>
        </Row>

        <Row style={{ paddingBottom: "35px" }}>
        <Form.Group as={Col} controlId="valid">
            <Form.Label className="labelD">{"Duration " + durationInput}</Form.Label>
            <Form.Control type="number" inputMode="numeric" 
            enterKeyHint="done" name="durationValue" pattern="[0-9]*" 
            className="numberOnlyText" onChange={(e) => setDuration(e.target.value)} value={duration}/>
          </Form.Group>

          <Form.Group as={Col} controlId="validationCustom05">
            <Form.Label className="labelD">End Time</Form.Label>
            <Form.Control type="time" inputMode="numeric" enterKeyHint="done" 
            name="endtimeValue" pattern="[0-9]*" className="numberOnlyText"  
            onChange={(e) => setEndtime(e.target.value)} value={endTime} placeholder="End time:"/>
          </Form.Group>
        </Row>

        <Row style={{ paddingBottom: "35px" }}>
       
        <Form.Group  as={Col} controlId="valid">
            <Form.Label className="labelD">Station *</Form.Label>
          
            <Form.Control required inputMode="numeric"  type="number" 
            enterKeyHint="done" name="stationValue" className="numberOnlyText" 
            pattern="[0-9]*" onChange={(e) => setStation(e.target.value)} value={station} />
       <Form.Control.Feedback className="invalidLabel" type="invalid"> Provide a station number!
            </Form.Control.Feedback> 
          </Form.Group>
          <Form.Group as={Col} controlId="validStation">
            <Form.Label className="labelD">Waterbody Code *</Form.Label>
            <Select styles={validWaterbodyCode ? customStyles : customStylesInvalid} onChange={onChangeWaterCode} 
            // value={waterbodyCode}
           placeholder={waterbodyCode === "" ? "Choose Waterbody..." : waterbodyCode} 
        //    placeholder={"Choose Waterbody..."} 
            options={waters.map((title : any) => { return { value: title, label: title } })} />
                {validWaterbodyCode ? <></>  :
               <Form.Label className="invalidLabel"> Provide a waterbody code!
               </Form.Label> 
            }
          </Form.Group>
        </Row>

        <Row style={{ paddingBottom: "35px" }}>
            <Form.Group as={Col} controlId="waterDescription">
            <Form.Label className="labelD">Waterbody Description</Form.Label>
            <Form.Control 
            className="textSettings" value={waterbodyDescript}
                    placeholder={"Enter Waterbody Description..."} onChange={(e) => setWaterbodyDescript(e.target.value)}
                     as="textarea" rows={3} />
            </Form.Group>
        </Row>

        <Row style={{ paddingBottom: "35px" }}>
            <Form.Group as={Col} controlId="keywordsText">
                <Form.Label className="labelD">Keywords</Form.Label>
                <Form.Control className="textSettings" value={keywords} onChange={(e) => setKeywords(e.target.value)} placeholder={"Enter Keywords..."} as="textarea" rows={3} />
            </Form.Group>
            <Form.Group as={Col} controlId="commentsText">
                <Form.Label className="labelD">Comments</Form.Label>
                <Form.Control className="textSettings" value={comments} onChange={(e) => setComments(e.target.value)} placeholder={"Enter Comments..."} as="textarea" rows={3} />
            </Form.Group>
        </Row>
                    <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "35px" }}>
                        <label className="labelCenter">Use For CPUE:</label>
                        <input defaultChecked={true} onClick={onCheckedboxValue} className="checkbox" type="checkbox" id="fname">
                        </input>
                    </Row>
                    <Card className="cardDivider" >
                        <Row >
                            <Col></Col>
                            <Col >
                                <h1 style={{ textAlign: "center", alignSelf: "center", paddingBottom: "35px" }}>Gear Settings</h1>
                            </Col>
                            <Col></Col>
                        </Row>
                  
                    <Row style={{ paddingBottom: "10px" }}>
                        <Form.Group as={Col} controlId="keywordsText">
                        <Form.Label className="labelD">Settings</Form.Label>
                            <Select styles={customStyles} onChange={(onChangeElectricSetting)} placeholder={"Choose Gear Setting..."} options={gears.map((title : any) => { return { value: title, label: title } })} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="keywordsText">
                        <Form.Label className="labelDValueWPlus">Value</Form.Label>
                        <Stack gap={2} direction="horizontal"> 
                                <input type="number" inputMode="numeric" enterKeyHint="done" name="settingsParamValue" className="paramValueWPlus" onChange={(event) => setElectricValue(event.target.value)} value={electricValue} placeholder="Value: "></input>
                                <Button data-placement="top" onClick={(e) => onAddButton(e)} className="plus alt">
                                </Button>
                                </Stack>
                        </Form.Group>
                       
                       
                    </Row>

                        <div>
                            <ul>
                                {
                                    electricSettingsCollection.map(
                                        (setting : gearSettings) =>
                                            <li key={setting.id}>
                                                <Row style={{ paddingBottom: "10px" }}>
                                                <Form.Group as={Col}>
                                                    <Form.Control as="textarea" readOnly className="listButtons" value={setting.id}/>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Stack gap={2} direction="horizontal"> 
                                                        <input readOnly className="paramValueWPlus" value={setting.value}></input>
                                                        <Button data-placement="top" onClick={() => onDeleteButton(setting.id)} className="minus"></Button>
                                                    </Stack>
                                                    </Form.Group>
                                                </Row>
                                            </li>
                                    )
                                }
                            </ul>
                        </div>
                    </Card>
                <Row style={{ paddingBottom: "35px" }}></Row>
                <Row >
                <Col></Col>
                <Col style={{ textAlign: "center", alignSelf: "center"}}>
                    <Button variant="secondary" className="submitButton" type="submit">
                        Start Sample
                    </Button>
                    {/* <div>
                        <ButtonSubmit state={this.state} index={this.count} collection={this.fishSampleDataCollection}></ButtonSubmit>
                    </div> */}
                    </Col>
                    <Col></Col>
                    </Row>
                    <Row style={{ paddingBottom: "50px" }}></Row>
         {/* <Button type="submit">Submit form</Button> */}
      </Form>
      </>
    );
  };



export default SampleSettings;