import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import './lightstyle.css';
import { useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';

type tabletSettings = {
    ageStructureLimit: any,
    weightLimit: any,
    wrmin: any,
    wrmax: any,
    theme: any
};

class tabletSettingsPage extends React.Component<any, tabletSettings>  {

    tabletSettings: tabletSettings;

    constructor(props: any) {
        super(props);

        this.onChangeAgeStructureLimit = this.onChangeAgeStructureLimit.bind(this);
        this.onChangeWeightLimit = this.onChangeWeightLimit.bind(this);
        this.onChangeWrMax = this.onChangeWrMax.bind(this);
        this.onChangeWrMin = this.onChangeWrMin.bind(this);
        this.switchTheme = this.switchTheme.bind(this);

        this.tabletSettings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');
        if (localStorage.getItem('tabletSettings')) {
            this.state = {
                ageStructureLimit: this.tabletSettings.ageStructureLimit,
                weightLimit: this.tabletSettings.weightLimit,
                wrmin: this.tabletSettings.wrmin,
                wrmax: this.tabletSettings.wrmax,
                theme: this.tabletSettings.theme
            }
        } else {
            this.state = {
                ageStructureLimit: '5',
                weightLimit: '10',
                wrmin: '70',
                wrmax: '150',
                theme: 'light'
            };
        }
    }

    onChangeAgeStructureLimit(e: any) {
        console.log(e)
        this.setState({
            ageStructureLimit: e.target.value
        })
    }

    onChangeWeightLimit(e: any) {
        this.setState({
            weightLimit: e.target.value
        })
    }

    onChangeWrMin(e: any) {
        this.setState({
            wrmin: e.target.value
        })
    }

    onChangeWrMax(e: any) {
        this.setState({
            wrmax: e.target.value
        })
    }

    switchTheme() {
        const newTheme = this.state.theme === 'light' ? 'dark' : 'light'
        this.setState({
            theme: newTheme
        })
    }

    render() {
        return (
            <div className="center-col" data-theme={this.state.theme}>
                <Container fluid="md">
                    <Row style={{ paddingBottom: "20px" }}></Row>
                    <Row>
                        <Col>
                            <BackButton />
                        </Col>
                        <Col style={{ paddingBottom: "55px" }}>
                            <h1 style={{ textAlign: "center", alignSelf: "center" }}>Tablet Settings</h1>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <label className="labelD">Age Structure Limit</label>
                        </Col>
                        <Col>
                            <label className="labelD">Weight Limit</label>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: "30px" }}>
                        <Col>
                            <input type="number" name="agestructurelimit" className="ageStructureThreshold" pattern="[0-9]*" onChange={this.onChangeAgeStructureLimit} value={this.state.ageStructureLimit} placeholder="Age Structure Limit:"></input>
                        </Col>
                        <Col>
                            <input type="number" name="weightlimit" className="weightThreshold" pattern="[0-9]*" onChange={this.onChangeWeightLimit} value={this.state.weightLimit} placeholder="Weight Limit:"></input>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label className="labelD">Wr Min Value</label>
                        </Col>
                        <Col>
                            <label className="labelD">Wr Max Value</label>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: "30px" }}>
                        <Col>
                            <input inputMode="numeric" enterKeyHint="done" type="number" name="wrmin" className="settingsInput" pattern="[0-9]*" onChange={this.onChangeWrMin} value={this.state.wrmin} placeholder="Wr Min:"></input>
                        </Col>
                        <Col>
                            <input inputMode="numeric" enterKeyHint="done" type="number" name="wrmax" className="settingsInput" pattern="[0-9]*" onChange={this.onChangeWrMax} value={this.state.wrmax} placeholder="Wr Max:"></input>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: "30px" }}>
                        <Col>
                            <label className="labelD">Theme:</label>
                            <button className="themeToggle" onClick={this.switchTheme}>
                                Switch to {this.state.theme === 'light' ? 'Dark' : 'Light'} Mode
                            </button>
                        </Col>
                        {/*<Col>*/}
                        {/*    <label className="labelD">Load Export File:</label>*/}
                        {/*    <ButtonLoad state={this.state}></ButtonLoad>*/}
                        {/*</Col>*/}
                    </Row>
                </Container>
                <Row style={{ paddingBottom: "35px" }}></Row>
                <Row>
                    <Col></Col>
                    <Col style={{ textAlign: "center", alignSelf: "center" }}>
                        <div>
                            <ButtonSubmit state={this.state}></ButtonSubmit>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
                <Row style={{ marginTop: "100px", textAlign: "center", alignSelf: "center" }}>
                    <Col></Col>
                     <Col style={{ textAlign: "center", alignSelf: "center" }}>
                        <div style={{ borderColor: "black", borderStyle: "solid", borderWidth: "2px", padding: "15px", margin: "30px", textAlign:"center", alignSelf:"center" }}>
                            <div style={{padding:"30px"}}><h5> These functions work with the data stored on this device.  Use caution as these functions cannot be undone and <span style={{ color: "red", fontWeight:"bold" }}>DATA LOSS IS POSSIBLE.</span></h5></div>
                            <ButtonLoad state={this.state}></ButtonLoad>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
                <Row style={{ paddingBottom: "50px" }}></Row>
                {/* <div>
                    <ButtonSubmit state={this.state}></ButtonSubmit>
                    index={this.count} collection={this.fishSampleDataCollection}
                </div> */}
            </div>
        )
    }
}

export function ButtonSubmit(this: any, props: any) {
    let navigate = useNavigate();
    const data = props.state;
    function handleClick() {
        localStorage.setItem('tabletSettings', JSON.stringify(data));
        navigate("/");
    }
    return (
        <>
            <Button className="submitButton" onClick={() => handleClick()}>
                Save Settings
            </Button>
        </>
    );
};

export function BackButton(props: any) {
    let navigate = useNavigate();
    function handleClick() {
        navigate("/");
    }
    return (
        <>
            <Button className="backButton" onClick={() => handleClick()}>
                Back
            </Button>
        </>
    );
};


export function ButtonLoad(props: any) {
    let navigate = useNavigate();
    function handleClick() {
        navigate("/LoadFile");
    }
    function download(filename: string, text: string | number | boolean) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
    async function handleDownloadClick() {
        navigator.clipboard.writeText(JSON.stringify(JSON.stringify(localStorage)));
        download("FinCatchDE_Download", JSON.stringify(JSON.stringify(localStorage)));
    }
    async function handleClearClick() {
        var result = window.confirm("Are You Sure You Want To Clear The Local Storage? All Data On The Device Will Be Removed.");
        if (result) {
            var result2 = window.confirm("Are You Really Sure? This CANNOT BE UNDONE");
            if (result2) {
                await localStorage.clear();
                navigate("/");
            }
        }
    }
    return (
        <div style={{display:"flex"}}>
            <Button className="themeToggle" style={{ margin: "15px", width: "300px" }} onClick={() => handleDownloadClick()}>
                 Download Device Data
            </Button>
            <Button className="themeToggle" style={{ margin: "15px", width: "300px" }} onClick={() => handleClearClick()}>
                Clear Device Data
            </Button>
            <Button className="themeToggle" style={{ margin: "15px", width: "300px"}} onClick={() => handleClick()}>
                Import Device Data
            </Button>
        </div>
    );
};

export default tabletSettingsPage;