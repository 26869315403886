import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import 'bootstrap';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Auth0Provider } from "@auth0/auth0-react";


ReactDOM.render(
    <Auth0Provider
        domain="dev-8aepc70m.us.auth0.com"
        clientId="TfQ53dFX1Ihr9FPYM9EwCwMPu58E0Exq"
        audience="https://dev-8aepc70m.us.auth0.com/api/v2/"
        scope="read:current_user update:current_user_metadata"
    >
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Auth0Provider>,
    document.getElementById("root")

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();
reportWebVitals();
