import React from "react";
import { Stack, Card, Container, Row, Col, Form, Button } from 'react-bootstrap';
import './lightstyle.css';
import {useNavigate} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

export function SettingList(props: any) {
    return (
        <>
            <Row style={{ paddingBottom: "30px" }}>
                <Col>
                    <Button variant="secondary" className="listButtons" disabled>{props.id}</Button>
                </Col>
                <Col>
                    <Button variant="secondary" className="listValueButton" disabled>{props.value}</Button>
                    <Button className="minusButton" variant="secondary">-</Button>
                </Col>
            </Row>
        </>
    )
}


type FishSampleSettings = {
    id: any,
    date: any,
    owner: string,
    method: string,
    station: string,
    waterbodyCode: string,
    waterbodyDescript: string,
    keywords: string,
    comments: string,
    electricSetting: string,
    electricValue: string,
    electricSettingsCollection: gearSettings[],
    useCPUE: string,
    effort: any,
    duration: any,
    endTime: any,
    validStation: boolean,
};

type gearSettings = {
    id: string,
    value: string
}

type SingleSample = {
    settings: FishSampleSettings,
    speciesData: any
}

const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        background: "var(--button) !important",
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        borderColor: state.isFocused ? "blue" : "black",
        padding: "0px 0px 0px 0px",
        margin: "0px 30px",
        length: "80%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "90%",
        height: "70px",
        color: "var(--text-primary) !important",
        fontSize: "30px",
        testDecorationColor: "white",
        boxShadow: state.isFocused ? null : null,

    }),
    input: (base: any) => ({
        ...base,
        color: "var(--text-primary)"
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        color: "black",
        fontSize: "30px",
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "var(--text-primary)",
        fontWeight: 400
    }),
    menu: (base: any, state: any) => ({
        ...base,
        color: state.isSelected ? "white" : "black",
        fontSize: "30px",
        borderRadius: 0,
        marginTop: 0,
        width: "90%",
        margin: "0px 0px",
    }),
    menuList: (base: any, state: any) => ({
        ...base,
        color: state.isSelectd ? "white" : "black",
        margin: "0px 0px",
    })
};

class EditSampleSettings extends React.Component<any, FishSampleSettings> {

    settingsData: any;
    count: number;
    fishSampleIndex: number;
    fishSampleDataCollection: SingleSample[];
    fishCountData: any;
    editFishSampleIndex: any;
    editing: boolean;
    currDate: any;
    

    ownersList: any[];
    waterCodesList: any[];
    methodsList: any[];
    gearsList: any[];
    durationInput: any;

    constructor(props: any) {
        super(props);
        
        //information for dropdowns
        const ownersObj = JSON.parse(localStorage.getItem('OwnersNames') || '{}');
        const owners: string[] = [];
        Object.keys(ownersObj).map((key) => {
            owners.push(ownersObj[key] + " " + key);
        });
        const watersObj = JSON.parse(localStorage.getItem('WaterCodes') || '{}');
        const waters: string[] = [];
        Object.keys(watersObj).map((key) => {
            waters.push(key + "-" + watersObj[key]);
        });

        const methodsObj = JSON.parse(localStorage.getItem('Methods&Inputs') || '{}');
        const methods: string[] = [];
        Object.keys(methodsObj).map((key) => {
            methods.push(key);
        });

        const gearsObj = JSON.parse(localStorage.getItem('GearSettingsCodes') || '{}');
        const gears: string[] = [];
        Object.keys(gearsObj).map((key) => {
            gears.push(key + "-" + gearsObj[key]);
        });

        this.ownersList = owners;
        this.waterCodesList = waters;
        this.methodsList = methods;
        this.gearsList = gears;
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeOwner = this.onChangeOwner.bind(this);
        this.onChangeMethod = this.onChangeMethod.bind(this);
        this.onChangeStation = this.onChangeStation.bind(this);
        this.onChangeWaterCode = this.onChangeWaterCode.bind(this);
        this.onChangeWaterDescription = this.onChangeWaterDescription.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeElectricSetting = this.onChangeElectricSetting.bind(this);
        this.onChangeElectricValue = this.onChangeElectricValue.bind(this);
        this.onCheckedboxValue = this.onCheckedboxValue.bind(this);
        this.onAddButton = this.onAddButton.bind(this);
        this.onDeleteButton = this.onDeleteButton.bind(this);
        this.onChangeComments = this.onChangeComments.bind(this);
        this.onChangeKeywords = this.onChangeKeywords.bind(this);
        this.onChangeEffort = this.onChangeEffort.bind(this);
        this.onChangeDuration = this.onChangeDuration.bind(this);
        this.onChangeEndtime = this.onChangeEndtime.bind(this);

        this.setValidStation = this.setValidStation.bind(this);

        this.fishSampleIndex = JSON.parse(localStorage.getItem('fishSampleIndex') || '{}');
        this.editFishSampleIndex = JSON.parse(localStorage.getItem('editFishSampleIndex') || '{}');
        this.editing = false;
       // let date: Date = new Date();
      //  let month = date.getMonth() < 10 ? "0" + String(date.getMonth()) : String(date.getMonth());
      //  let day = date.getDay() < 10 ? "0" + String(date.getDay()) : String(date.getDay());
      //  this.currDate = String(date.getFullYear()) + "-" + month + "-" + day;
        if(localStorage.getItem('editFishSampleIndex')){
            this.count = this.editFishSampleIndex;
            this.fishSampleIndex = this.editFishSampleIndex;
            this.editing = true;
        } else
        if (localStorage.getItem('fishSampleIndex')) {
            this.count = this.fishSampleIndex;
        } else {
            this.fishSampleIndex = 0;
            this.count = 0;
        }
        this.fishSampleDataCollection = JSON.parse(localStorage.getItem('fishSample') || '{}');
        if (localStorage.getItem('fishSample')) {
            this.settingsData = this.fishSampleDataCollection[this.fishSampleIndex].settings;
            this.fishCountData = this.fishSampleDataCollection[this.fishSampleIndex].speciesData;
            this.state = {
                id: this.count,
                date: this.settingsData.date,
                owner: this.settingsData.owner,
                method: this.settingsData.method,
                station: this.settingsData.station,
                waterbodyCode: this.settingsData.waterbodyCode,
                waterbodyDescript: this.settingsData.waterbodyDescript,
                keywords: this.settingsData.keywords,
                comments: this.settingsData.comments,
                electricSetting: this.settingsData.electricSetting,
                electricValue: this.settingsData.electricValue,
                electricSettingsCollection: this.settingsData.electricSettingsCollection === undefined ? [] : this.settingsData.electricSettingsCollection,
                useCPUE: this.settingsData.useCPUE,
                effort: this.settingsData.effort,
                duration: this.settingsData.duration,
                endTime: this.settingsData.endTime,
                validStation: true
            }
        } else {
            this.state = {
                id: this.count,
                date: new Date(),
                owner: '',
                method: '',
                station: '',
                waterbodyCode: '',
                waterbodyDescript: '',
                keywords: '',
                comments: '',
                electricSetting: '',
                electricValue: '',
                electricSettingsCollection: [],
                useCPUE: '',
                effort: '',
                duration: '',
                endTime: '',
                validStation: true
            };
        }
    }

    onChangeDate(e: any) {
        this.setState({
            date: e.target.value
        })
    }

    onSubmit(e: any) {
        e.preventDefault();
        const num = this.count;
        const data = this.state;
        this.fishSampleDataCollection[num].settings = data;
        this.fishSampleDataCollection[num].speciesData = '';
        localStorage.setItem('fishSampleIndex', JSON.stringify(this.count));
        localStorage.setItem('fishSample', JSON.stringify(this.fishSampleDataCollection));
    }

    onChangeOwner = (selected: any) => {
        this.setState({
            owner: selected.value.toString()
        })
    }

    onChangeMethod = (selected: any) => {
        this.setState({
            method: selected.value.toString()
        })

        const methodsObj = JSON.parse(localStorage.getItem('Methods&Inputs') || '{}');
        Object.keys(methodsObj).forEach(key => {
            if (key === this.state.method) {
                this.durationInput = methodsObj[key].toString();
            }
        })
    }

    onChangeStation(e: any) {
        this.setState({
            station: e.target.value
        })
    }

    onChangeWaterCode = (e: any) => {
        this.setState({
            waterbodyCode: e.value.toString()
        })
    }

    onChangeWaterDescription(e: any) {
        this.setState({
            waterbodyDescript: e.target.value
        })
    }

    onChangeComments(e: any) {
        this.setState({
            comments: e.target.value
        })
    }

    onChangeKeywords(e: any) {
        this.setState({
            keywords: e.target.value
        })
    }

    onChangeElectricSetting = (e: any) => {
        this.setState({
            electricSetting: e.value.toString()
        })
    }

    onChangeElectricValue(e: any) {
        const value = e.target.value;
        if (!isNaN(+value)) {
            this.setState({
                electricValue: e.target.value
            })
        }
    }

    onCheckedboxValue(e: any) {
        this.setState({
            useCPUE: e.target.value
        })
    }

    onDeleteButton(id: any) {
        const newList = this.state.electricSettingsCollection.filter((item) => item.id !== id);
        this.setState({
            electricSettingsCollection: newList
        })
    }

    onChangeEffort(e: any){
        this.setState({
            effort: e.target.value
        })
    }

    onChangeDuration(e: any){
        this.setState({
            duration: e.target.value
        })
    }

    onChangeEndtime(e: any){
        console.log(e.target.value);
        this.setState({
            endTime: e.target.value
        })
    }

    setValidStation(e: boolean){
        this.setState({
            validStation: e
        })
    }

    onAddButton(e: any) {

        if (this.state.electricSetting !== '' && this.state.electricValue !== '') {
            const i = this.state.electricSettingsCollection.findIndex((obj => obj.id === this.state.electricSetting));
            if (i >= 0) {
                this.setState({
                    electricSettingsCollection: [
                        ...this.state.electricSettingsCollection.slice(0, i),
                        {
                            ...this.state.electricSettingsCollection[i],
                            value: this.state.electricValue,
                        },
                        ...this.state.electricSettingsCollection.slice(i + 1)
                    ]
                })
            } else {
                this.setState({
                    electricSettingsCollection: [...this.state.electricSettingsCollection, { id: this.state.electricSetting, value: this.state.electricValue }],
                    electricSetting: '',
                    electricValue: ''
                })
            }

        }

    }

    componentDidMount() {
        const tabletSettings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');
        return tabletSettings.theme;
    }

    render() {
        return (
            <div className="center-col" data-theme={this.componentDidMount()}>

                <Container fluid="md">
                <Row style={{ paddingBottom: "20px" }}></Row>
                    <Row>
                        <Col>
                        {/* <BackButton/> */}
                        </Col>
                        <Col style={{ paddingBottom: "55px" }}>
                            <h1 style={{ textAlign: "center", alignSelf: "center" }}>Sample Metadata</h1>
                        </Col>
                        <Col></Col>
                    </Row>

                    <Row>
                        <Col >
                            <label className="labelD">Date *</label>
                        </Col>
                        <Col>
                            <label className="labelD">Owner *</label>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: "35px" }}>
                        <Col>
                        <input className="numberOnlyText" type="date" name="day" onChange={this.onChangeDate} value={this.state.date}></input>
                            {/* <div>
                                <DatePicker className="calendarDate"
                                    selected={this.state.date}
                                    onChange={this.onChangeDate} //only when value has changed
                                />
                            </div> */}
                        </Col>
                        <Col>
                            <Select styles={customStyles} onChange={this.onChangeOwner} value={{ label: this.state.owner }} placeholder={"Choose Owner..."} options={this.ownersList.map((title) => { return { value: title, label: title } })} />
                        </Col>
                    </Row>

                    <Row>
                        <Col >
                            <label className="labelD">Method *</label></Col>
                        <Col>
                            <label className="labelD">Effort</label></Col>
                    </Row>

                    <Row style={{ paddingBottom: "35px" }}>
                        <Col>
                            <Select styles={customStyles} onChange={this.onChangeMethod} value={{ label: this.state.method }} placeholder={"Choose Method..."} options={this.methodsList.map((title) => { return { value: title, label: title } })} />
                        </Col>

                        <Col>
                            <input name="effortValue" className="numberOnlyText" pattern="[0-9]*" onChange={this.onChangeEffort} value={this.state.effort} placeholder="Effort Number:"></input>
                        </Col>
                    </Row>
                    {/* ///////////
                    ////////// */}
                                       <Row>
                        <Col >
                            <label className="labelD">Duration ({this.durationInput})</label>
                        </Col>
                        <Col>
                            <label className="labelD">End Time</label></Col>
                    </Row>

                    <Row style={{ paddingBottom: "35px" }}>
                    <Col>
                            <input type="number" inputMode="numeric" name="effortValue" pattern="[0-9]*" className="numberOnlyText" onChange={this.onChangeDuration} value={this.state.duration}></input>
                        </Col>

                        <Col>
                            <input type="time" inputMode="numeric" name="effortValue" pattern="[0-9]*" className="numberOnlyText"  onChange={this.onChangeEndtime} value={this.state.endTime} placeholder="End time:"></input>
                        </Col>
                    </Row>

                    
                    <Row >
                        <Col><label className="labelD">Station *</label></Col>
                        <Col><label className="labelD">Waterbody Code</label></Col>
                    </Row>
                    <Row style={{ paddingBottom: "35px" }}>
                        <Col>
                        <input inputMode="numeric" enterKeyHint="done" type="number" name="stationValue" className={this.state.validStation ? "numberOnlyText" : "numberOnlyTextInvalid"} pattern="[0-9]*" onChange={this.onChangeStation} value={this.state.station} placeholder="Station Number:"></input>
                        {this.state.validStation ? <></>  :
                        <label className="invalidLabel"> Provide a Station Number!
                        </label> }
                        </Col>
                        <Col>
                            <Select styles={customStyles} onChange={this.onChangeWaterCode} value={{ label: this.state.waterbodyCode }} placeholder = { "Choose Waterbody..."} options={this.waterCodesList.map((title) => { return { value: title, label: title } })} />
                        </Col>
                    </Row>

                    <Row>
                       
                        <Col><label className="labelD">Waterbody Description</label></Col>
                    </Row>

                    <Row style={{ paddingBottom: "35px" }}>
                     

                        <Col>
                            <Form.Group controlId="waterDescription">
                                <Form.Control className="textSettings" value={this.state.waterbodyDescript}
                                    placeholder={"Enter Waterbody Description..."} onChange={this.onChangeWaterDescription} as="textarea" rows={3}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row >
                        <Col >
                            <label className="labelD">Keywords</label></Col>
                        <Col>
                            <label className="labelD">Comments</label></Col>
                    </Row>
                    <Row style={{ paddingBottom: "35px" }}>
                        <Col>
                            <Form.Group controlId="keywordsText" >
                                <Form.Control onChange={this.onChangeKeywords} className="textSettings" value={this.state.keywords} placeholder={"Enter Keywords..."} as="textarea" rows={3} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="commentsText">
                                <Form.Control className="textSettings" value={this.state.comments} onChange={this.onChangeComments} placeholder={"Enter Comments..."} as="textarea" rows={3} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center", alignSelf: "center" }}>
                            <label className="labelCenter">Use For CPUE:</label>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "35px" }}>
                        <input defaultChecked={true} onClick={this.onCheckedboxValue} className="checkbox" type="checkbox" id="fname">
                        </input>
                    </Row>
                    <Card className="cardDivider" >
                        <Row >
                            <Col></Col>
                            <Col >
                                <h1 style={{ textAlign: "center", alignSelf: "center", paddingBottom: "35px" }}>Gear Settings</h1>
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row>
                            <Col>
                                <label className="labelD">Settings</label></Col>
                            <Col >
                                <label className="labelD">Value</label></Col>
                        </Row>
                        <Row style={{ paddingBottom: "10px" }}>
                            <Col >
                                <Select styles={customStyles} onChange={this.onChangeElectricSetting} value={{ label: this.state.electricSetting }} placeholder={"Choose Gear Setting..."} options={this.gearsList.map((title) => { return { value: title, label: title } })} />
                            </Col>

                            <Col>
                            <Stack gap={2} direction="horizontal"> 
                                <input name="settingsParamValue" className="paramValueWPlus" pattern="[0-9]*" onChange={this.onChangeElectricValue} value={this.state.electricValue} placeholder="Value: "></input>
                                <Button data-placement="top" onClick={this.onAddButton} className="plus alt">
                                </Button>
                            </Stack>
                            </Col>
                        </Row>

                        <div>
                            <ul>
                                {
                                    this.state.electricSettingsCollection.map(
                                        (setting) =>
                                            <li key={setting.id}>
                                                <Row style={{ paddingBottom: "10px" }}>
                                                <Form.Group as={Col}>
                                                    <Form.Control as="textarea" readOnly className="listButtons" value={setting.id}/>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Stack gap={2} direction="horizontal"> 
                                                        <input readOnly className="paramValueWPlus" value={setting.value}></input>
                                                        <Button data-placement="top" onClick={() => this.onDeleteButton(setting.id)} className="minus"></Button>
                                                    </Stack>
                                                    </Form.Group>
                                                </Row>
                                            </li>
                                    )
                                }
                            </ul>
                        </div>
                    </Card>
                </Container>
                <Row style={{ paddingBottom: "35px" }}></Row>
                <Row >
                {/* <Col></Col> */}
                <Col style={{ textAlign: "center", alignSelf: "center"}}>
                    <div>
                    <ButtonSubmit edit={this.editing} title={"Resume Sample"} path={"/dataEntryPage"} 
                        fishCount={this.fishCountData} state={this.state} index={this.count} 
                        collection={this.fishSampleDataCollection}
                        validStation={this.setValidStation}>
                    </ButtonSubmit>
                    <ButtonSubmit edit={this.editing} title={"Finish Sample"} path={"/"} 
                        fishCount={this.fishCountData} state={this.state} index={this.count} 
                        collection={this.fishSampleDataCollection}
                        validStation={this.setValidStation}>
                    </ButtonSubmit>
                        {/* <NewSampleButton></NewSampleButton> */}
                    </div>
                  
                    </Col>
                    {/* <Col></Col> */}
                    </Row>
                    <Row style={{ paddingBottom: "50px" }}></Row>
            </div>
        );
    }
}



export function ButtonSubmit(props: any){
    let navigate = useNavigate();
    const data = props.state;
    const num = props.index;
    const s: SingleSample = {settings: data, speciesData: props.fishCount};
    const collection: SingleSample[] = props.collection;
    function handleClick(event: any) {
        if(props.state.station === ''){ //need a station number
            props.validStation(false);
            event.preventDefault();
            event.stopPropagation();
        } else {
            collection[num] = s;
            if(props.edit && props.title === "Finish Sample"){
                localStorage.removeItem('editFishSampleIndex'); //done edting
            }
           //localStorage.setItem('fishSampleIndex', JSON.stringify(num));
            collection[num] = s;
            localStorage.setItem('fishSample', JSON.stringify(collection));
            navigate(props.path);
        }


      }
     return (
    <>
        <Button variant="secondary" className="submitButton" onClick={(event: any) => handleClick(event)}>
        {props.title}
    </Button>
    </>
    );
};

export function NewSampleButton(props: any){
    let navigate = useNavigate();
    function handleClick() {
        navigate("/sampleSettings");
      }
     return (
    <>
        <Button variant="secondary" className="submitButton" onClick={() => handleClick()}>
        Start New Sample
    </Button>
    </>
    );
};

export function BackButton(props: any){
    let navigate = useNavigate();
    function handleClick() {
        navigate("/");
      }
     return (
    <>
        <Button variant="secondary" className="backButton" onClick={() => handleClick()}>
        Back
    </Button>
    </>
    );
};


export default EditSampleSettings;