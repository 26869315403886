import React from "react";
import { Table, ListGroup, Card, Container, Row, Col, Button } from 'react-bootstrap';
import './lightstyle.css';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

type SampleErrors = {
    Sample: any[],
    Gears: any[],
    LengthFreq: any[],
    FishCount: any[],
    FishSample: any[],
    FishStructs: any[],
    FishTags: any[],
}



class ErrorLogs extends React.Component<any, SampleErrors> {
    SampleErrorCollection: SampleErrors[] = [];
    dataExists: boolean;
    samplesExists: boolean;
    fishCountExists: boolean;
    fishStructExists: boolean;
    constructor(props: any) {
        super(props);
        this.dataExists = false;
        this.samplesExists = false;
        this.fishCountExists = false;
        this.fishStructExists = false;
        if (localStorage.getItem('SampleErrors')) {
            this.SampleErrorCollection = JSON.parse(localStorage.getItem('SampleErrors') || '{}');
        }
        if (this.SampleErrorCollection[0].Sample.length > 0) {
            this.samplesExists = true;
        }
        if (this.SampleErrorCollection[0].FishCount.length > 0) {
            this.fishCountExists = true;
        }
        if (this.SampleErrorCollection[0].FishStructs.length > 0) {
            this.fishStructExists = true;
        }

    }

    componentDidMount() {
        const tabletSettings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');
        return tabletSettings.theme;
    }

    
    render() {
        return (
            <div className="center-col">
                <Card className="cardDividerPage" data-theme={this.componentDidMount()}>
                    <Container fluid="md" className="pageContainer">
                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "15px" }}></Row>
                        <Row>
                            <Col>
                                <BackButton />
                            </Col>
                            <Col lg="auto" style={{ paddingBottom: "55px" }}>
                                <ListGroup.Item className="listPrevSamplesTitle">
                                    <h1>Errors Uploading to Database</h1>
                                </ListGroup.Item>
                            </Col>
                            <Col></Col>
                        </Row>

                        {(this.fishStructExists ?
                            <><Row>
                                <Col lg="auto" style={{ paddingBottom: "55px" }}>
                                    <ListGroup.Item className="listPrevSamplesTitle">
                                        <h2>Link Table Errors</h2>
                                    </ListGroup.Item>
                                </Col>
                            </Row><Card className="cardDividerList">
                                    <Table bordered className="prevTable">
                                        <thead>
                                            <tr>
                                                <th scope="col">Sample Date</th>
                                                <th scope="col">Sample Waterbody</th>
                                                <th scope="col">Sample Method</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.SampleErrorCollection[0].FishStructs.map(
                                                (fish) =>
                                                    <tr key={fish.Uid}>
                                                        <td> {fish.LinkPurposeCode}</td>
                                                        <td>{fish.LinkCode}</td>
                                                        <td> {fish.waterbodyCode}</td>
                                                    </tr>
                                            )
                                            }

                                        </tbody>

                                    </Table>
                                </Card></>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan={5}>There are currently no samples.</td>
                                </tr>
                            </tbody>
                            )}

                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "30px" }}></Row>
                        <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "35px" }}>
                            <Col></Col>
                            <Col>
                                <ClearStorage/>
                            </Col>
                            <Col></Col>
                        </Row>

                    </Container>
                </Card>
            </div>

        );
    }

    
}

export default ErrorLogs;


export function ClearStorage(props: any) {
    let navigate = useNavigate();
    function handleClick() {
        localStorage.removeItem('SampleErrors');
        navigate("/");
    }
    return (
        <>
            <Button style={{ textAlign: "center", alignSelf: "center" }} variant="secondary" className="dataSubmitButton" onClick={() => handleClick()}>
                Submit To Database
            </Button>
        </>
    );

}

export function BackButton(props: any) {
    let navigate = useNavigate();
    function handleClick() {
        navigate("/");
    }
    return (
        <>
            <Button className="backButton" onClick={() => handleClick()}>
                Back
            </Button>
        </>
    );
};
