const Loading = () => (

    <div>
        <div style={{position:"absolute",
            top:"40%",
            width:"100%",
            textAlign:"center"}}><h1>Uploading Device Data</h1></div>
        <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>

            <div className="spinner-border spinner-grow-lg text-primary" role="status">
                <span className="sr-only"></span>
            </div>


        </div>
    </div>
);

export default Loading;