import React from 'react';


type FishSampleSettings = {
    id: any,
    date: any,
    owner: string,
    method: string,
    station: string,
    waterbodyCode: string,
    waterbodyDescript: string,
    keywords: string,
    comments: string,
    electricSetting: string,
    electricValue: string,
    effort: string,
    endTime: string,
    duration: string,
    electricSettingsCollection: gearSettings[],
    useCPUE: string
}

type FishSample = {
    code: any,
    lengths: LengthCounts[],
    totalCount: any,
    totalFemaleCount: any,
    totalMaleCount: any,
    totalImmatureCount: any,
    totalNACount: any,
    totalStructuresCount: any,
    totalWeightCount: any,
    weights: WeightCounts[],
    counts: FishCounts[]
}

type LengthCounts = {
    count: any,
}

type WeightCounts = {
    id: any,
    gender: string,
    comment: string,
    keywords: string,
    length: any,
    weigth: any,
    structures: StructureCounts[];
    tags: TagsCount[];
}

type FishCounts = {
    count: any,
    gender: string,
    comment: string
}

type StructureCounts = {
    id: any,
    purpose: string,
    type: string,
    comment: string,
    qr: string,
}

type TagsCount = {
    id: any,
    type: string,
    comment: string,
}

type FishSampleCollection = {
    fishSampleSettings: FishSampleSettings,
    fishSampleSpecies: FishSample[],
}

type gearSettings = {
    id: string,
    value: string
}



function GetSampleCollection() {
    let count = 0;
    let dataExists;
    let fullSampleCollection: FishSampleCollection[] = [];
    let sampleSettings: FishSampleSettings;
    
    let sampleNum = JSON.parse(localStorage.getItem('fishSampleIndex') || '{}');
    if (localStorage.getItem('fishSampleIndex')) {
        count = sampleNum;
    } else {
        count = 0;
    }
    let localStorageCollection = JSON.parse(localStorage.getItem('fishSample') || '{}');
    if (localStorage.getItem('fishSample')) {
        dataExists = true;
        let i = 0;
        for (i; i <= count; i++) {
            sampleSettings = localStorageCollection[i].settings;
            let speciesCount = localStorageCollection[i].speciesData.length;
            localStorage.setItem("species count", i.toString());
            let speciesCollection = localStorageCollection[i].speciesData;
            let fishSampleCollection: FishSample[] = [];
            let singleFishSample: FishSample;
            let j = 0;
            let sk = 0;
            for (j; j < speciesCount; j++) {
                let speciesKey = "";
                speciesKey = i + ',' + speciesCollection[j];
                if (localStorage.getItem(speciesKey)) {
                    singleFishSample = JSON.parse(localStorage.getItem(speciesKey) || '{}');
                    fishSampleCollection[sk] = singleFishSample;
                    sk++;
                }
                
            }

            
            
            let sampleData = {
                fishSampleSettings: sampleSettings,
                fishSampleSpecies: fishSampleCollection,
            }

            fullSampleCollection[i] = sampleData;
            //fullSampleCollection[i].fishSampleSettings = sampleSettings;
            //fullSampleCollection[i].fishSampleSpecies = fishSampleCollection;
            
        }

    }
    //localStorage.setItem("testing function", JSON.stringify(fullSampleCollection));
    return fullSampleCollection;
}

type WaterSampleSettings = {
    id: any,
    date: any,
    owner: any,
    method: any,
    station: any,
    waterbodyCode: any,
    waterbodyDescript: string,
    waterQualityComment: string,
    useCPUE: any,
    newParam: any,
    newValue: any,
    newDepth: any,
    waterCollection: ParamValue[],
    keywords: any,
    comments: any,
    effort: any
};

type wqSampleSubmit = {
    WaterBodyCode: any,
    Description: any,
    Date: Date,
    StationCode: any,
    Comments: any,
    Keywords: any,
    Owner: any,
}

type ParamValue = {
    id: any,
    value: any,
    depth: any,
    comment: any,
}

function GetWaterQualityCollections() {
    let localStorageCollection = [];
    let waterQualityCollection: WaterSampleSettings[] = [];
    let wqSampleToSubmit: wqSampleSubmit[];
    let count = 0;
    let waterQualityIndex = 0;
    let dataExists = false;

    waterQualityIndex = JSON.parse(localStorage.getItem('waterQualityIndex') || '{}');
    if (localStorage.getItem('waterQualityIndex')) {
        count = waterQualityIndex;
    } else {
        count = 0;
        waterQualityIndex = 0;
    }
    localStorageCollection = JSON.parse(localStorage.getItem('waterQualitySettings') || '{}')
    if (localStorage.getItem("waterQualitySettings")) {
        dataExists = true;
        let i = 0;
        for (i; i <= count; i++) {
            waterQualityCollection[i] = localStorageCollection[i];
        }
    }

  
    return waterQualityCollection;
}

type SpeciesCollection = {
    SpeciesName: string,
    SpeciesCode: any,
    MaxLength: any,
    WsSlope: any,
    WsIntercept: any,
}

function GetSpeciesCollection() {
    let species = [];
    let speciesCollection: SpeciesCollection[] = [];
    let count = 0;
    species = JSON.parse(localStorage.getItem('FullSpeciesInfo') || '{}')
    if (localStorage.getItem('FullSpeciesInfo')) {
        count = species.length;
        let i = 0;
        for (i; i < count; i++) {
            speciesCollection[i] = species[i];
        }
    }
    return speciesCollection;
}

export { GetSampleCollection, GetWaterQualityCollections, GetSpeciesCollection};