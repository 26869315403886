import SampleSettings from './pages/sampleSettings';
import EditSampleSettings from './pages/editSampleSettings';
import LandingPage from './pages/landingPage';
import WaterQualityInput from './pages/waterQualityInput';
import PreviousFishSamples from './pages/previousFishSamples';
import PreviousSingleFishSample from './pages/previousSingleFishSample';
import PreviousWaterSamples from './pages/previousWaterSamples';
import FishSpeciesSelection from './pages/fishSpeciesSelection';
import EditPrevLengthWeight from './pages/editPrevLengthWeight';
import CommentTagsOther from './pages/commentTagsOther';
import DataEntry from './pages/dataEntryPage';
import GeneralStats from './pages/generalStats';
import TabletSettings from './pages/tabletSettings';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppInformation from './pages/appInformation';
import AccessDatabase from './pages/accessDatabase';
import AccessSamplesDatabase from './pages/accessSamplesDatabase';
import ErrorLogs from './pages/errorLogs';
import Loading from './pages/loading';
import LoadFilePage from './pages/loadFile';

import {
    Routes,
    Route
  } from "react-router-dom";

import './App.css';


function App() {
    return (
            <Routes>
                <Route path="/waterQualityInput" element={<WaterQualityInput />} />
                <Route path="/sampleSettings" element={<SampleSettings />} />
                <Route path="/editSampleSettings" element={<EditSampleSettings />} />
                <Route path="/editPrevLengthWeight" element={<EditPrevLengthWeight />} />
                <Route path="/fishSpeciesSelection" element={<FishSpeciesSelection />} />
                <Route path="/previousWaterSamples" element={<PreviousWaterSamples />} />
                <Route path="/accessDatabase" element={<AccessDatabase />} />
                <Route path="/accessSamplesDatabase" element={<AccessSamplesDatabase />} />
                <Route path="/previousFishSamples" element={<PreviousFishSamples />} />
                <Route path="/prevFishSample" element={<PreviousSingleFishSample />} />
                <Route path="/commentTagsOther" element={<CommentTagsOther />} />
                <Route path="/dataEntryPage" element={<DataEntry/>} />
                <Route path="/generalStats" element={<GeneralStats/>} />
                <Route path="/tabletSettings" element={<TabletSettings />} />
                <Route path="/errorLogs" element={<ErrorLogs/>}/>
                <Route path="/landingPage" element={<LandingPage />} />
                <Route path="/" element={<AppInformation />} />
                <Route path="/LoadFile" element={<LoadFilePage />} />
            </Routes>
    );
}

export default App;
