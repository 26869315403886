import React from "react";
import { Modal, InputGroup, Stack, Table, Dropdown, Form, ListGroup, Card, Container, Row, Col, Button } from 'react-bootstrap';
import './lightstyle.css';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from "react";

type AddCount = {
    count: any,
    species: any,
    gender: any,
    comment: any,
    sampleTotalCount: number,
    sampleStructureCount: number,
    sampleWeightCount: number,
    collectionCount: any[],
    collectionFishCount: any[],
    showNegativeWarning: boolean
    //allFishCounts: { count: any; gender: any; species: any; }[];
}

type FishCount = {
    id: any,
    count: any,
    species: any,
    gender: any,
    comment: any,
    localStorageKey: any,
    localStorageIndex: any
}

type newCount = { count: any; gender: any; species: any; }

const tabletSettings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');

class GeneralStats extends React.Component<any, AddCount>{
    count: any;
    fishSampleIndex: number;
    editFishSampleIndex: number;
    speciesCodes: any[];
    localStorageCollection: any[];
    dataExists: boolean;
    countCollection: any[];
    fishCountCollection: any[];
    sampleCount: number;
    sampleStructure: number;
    sampleWeight: number;


    constructor(props: any) {
        super(props);
        this.onChangeCount = this.onChangeCount.bind(this);
        this.onChangeSpecies = this.onChangeSpecies.bind(this);
        this.onChangeGender = this.onChangeGender.bind(this);
        this.onChangeComment = this.onChangeComment.bind(this);
        this.onAddButton = this.onAddButton.bind(this);
        this.onChangeShowNegWarning = this.onChangeShowNegWarning.bind(this);
        this.checkNeg = this.checkNeg.bind(this);
        this.NegValueValidation = this.NegValueValidation.bind(this);

        this.dataExists = false;
        this.speciesCodes = [];
        this.countCollection = [];
        this.fishCountCollection = [];
        this.sampleCount = 0;
        this.sampleStructure = 0;
        this.sampleWeight = 0;
        this.editFishSampleIndex = 0;
        // this.fishSampleIndex = JSON.parse(localStorage.getItem('fishSampleIndex') || '{}');
        this.fishSampleIndex = JSON.parse(localStorage.getItem('fishSampleIndex') || '{}');
        this.editFishSampleIndex = JSON.parse(localStorage.getItem('editFishSampleIndex') || '{}');
        if (localStorage.getItem('editFishSampleIndex')) { //we are currently editing
            this.count = this.editFishSampleIndex;
            this.fishSampleIndex = this.editFishSampleIndex;
        }
        else if (localStorage.getItem('fishSampleIndex')) {
            this.count = this.fishSampleIndex; //if an index exists
        } else {
            this.count = 0;
        }
        // if (localStorage.getItem('fishSampleIndex')) {
        //     this.count = this.fishSampleIndex;
        // } else {
        //     this.count = 0;
        // }
        this.localStorageCollection = JSON.parse(localStorage.getItem('fishSample') || '{}');

        if (localStorage.getItem('fishSample')) {
            this.speciesCodes = this.localStorageCollection[this.fishSampleIndex].speciesData;
            if (this.speciesCodes.length > 0) {
                this.dataExists = true;
            }
            let i = 0;
            for (i; i < this.speciesCodes.length; i++) {
                let localIndex = this.count + "," + this.speciesCodes[i];
                let countsFromLocalStorage = JSON.parse(localStorage.getItem(localIndex) || '{}');
                if (localStorage.getItem(localIndex)) {
                    this.countCollection = [...this.countCollection, {
                        id: countsFromLocalStorage.code,
                        totalCount: countsFromLocalStorage.totalCount,
                        totalWeightCount: countsFromLocalStorage.totalWeightCount,
                        totalStructres: countsFromLocalStorage.totalStructuresCount
                    }];
                    this.sampleCount = this.sampleCount + Number(countsFromLocalStorage.totalCount);
                    this.sampleStructure = this.sampleStructure + Number(countsFromLocalStorage.totalStructuresCount);
                    this.sampleWeight += Number(countsFromLocalStorage.totalWeightCount);
                } else { //no counts yet
                    this.countCollection = [...this.countCollection, {
                        id: this.speciesCodes[i],
                        totalCount: 0,
                        totalWeightCount: 0,
                        totalStructres: 0
                    }];
                }
            }
        }
        this.fishCountCollection = GetFishCountCollection()!;

        this.state = {
            count: '',
            species: '',
            gender: 'n/a',
            comment: '',
            sampleTotalCount: this.sampleCount,
            sampleStructureCount: this.sampleStructure,
            sampleWeightCount: this.sampleWeight,
            collectionCount: this.countCollection,
            collectionFishCount: this.fishCountCollection,
            showNegativeWarning: false
        }

    };

    onChangeShowNegWarning(e: boolean) {
        this.setState({
            showNegativeWarning: e
        })
    }

    checkNeg() {
        if (Number(this.state.count) < 0) {
            this.setState({
                showNegativeWarning: true
            })
        } else {
            this.onAddButton();
        }

    }

    onChangeCount(e: any) {
        this.setState({
            count: e.target.value
        })
    }

    onChangeSpecies(e: any) {
        this.setState({
            species: e
        })
    }

    onChangeGender(e: any) {
        this.setState({
            gender: e
        })
    }

    onChangeComment(e: any) {
        this.setState({
            comment: e.target.value
        })
    }

    onAddButton() {
        let search = this.fishSampleIndex.toString() + "," + this.state.species.toString();
        const addAmount = Number(this.state.count);
        const i = this.state.collectionCount.findIndex((obj => obj.id === this.state.species));
        let specieCollection = JSON.parse(localStorage.getItem(search) || '{}');
        let newTot = addAmount;
        //let myCount = new FishCounts;

        //if (singleFishTagsId !== 'n/a' || singleFishTagsType !== 'n/a' || singleFishTagsComment !== 'n/a') {
        //    //const i = tagsCollection.findIndex((obj => obj.id === singleFishTagsId));
        //    tagsCollection = singleFishTags;

        //    tagsCollection.push({
        //        id: singleFishTagsId, type: singleFishTagsType,
        //        comment: singleFishTagsComment, initial: singleFishTagsInitial,
        //    });
        //}
        let myCount = {};
        if (localStorage.getItem(search)) {
            if (Number(this.state.count) !== 0) {
                myCount = {
                    count: this.state.count,
                    gender: this.state.gender,
                    comment: this.state.comment,
                    speciesIndex: search,
                    countIndex:specieCollection.counts.length 
                }
                specieCollection.counts.push(myCount);
                //update myCount to include species to use later to update state
                myCount = { ...myCount, species: specieCollection.code}
               
            }
            const tot = specieCollection.totalCount;
            newTot = newTot + Number(tot);
            specieCollection.totalCount = Number(tot) + addAmount;
            if (this.state.gender === 'Female') {
                const gen = specieCollection.totalFemaleCount;
                specieCollection.totalFemaleCount = Number(gen) + addAmount;
            }
            else if (this.state.gender === 'Male') {
                const gen = specieCollection.totalMaleCount;
                specieCollection.totalMaleCount = Number(gen) + addAmount;
            }
            else if (this.state.gender === 'Immature') {
                const gen = specieCollection.totalImmatureCount;
                specieCollection.totalImmatureCount = Number(gen) + addAmount;
            }
            else {
                const gen = specieCollection.totalNACount;
                specieCollection.totalNACount = Number(gen) + addAmount;
            }
            localStorage.setItem(search, JSON.stringify(specieCollection));
        } else {
            let lengths: any[] = []; // empty lengths
            let z = 0;
            for (z; z <= 99; z++) {
                lengths[z] = {
                    count: 0,
                };
            }

            type FishCounts = {
                count: any,
                gender: string,
                comment: string
            }

            let myCount = {
                count: this.state.count,
                gender: this.state.gender,
                comment: this.state.comment,
                species: this.state.species
            }
            const myCountArray: FishCounts[] = [];
            myCountArray.push(myCount);
            const dataString = {
                code: this.state.species,
                totalCount: addAmount,
                totalWeightCount: 0,
                totalStructuresCount: 0,
                totalFemaleCount: 0,
                totalNACount: 0,
                totalImmatureCount: 0,
                totalMaleCount: 0,
                lengths: lengths,
                weights: [],
                counts: myCountArray
            }
            if (this.state.gender === 'Female') {
                dataString.totalFemaleCount = addAmount;
            }
            else if (this.state.gender === 'Male') {
                dataString.totalMaleCount = addAmount;
            }
            else if (this.state.gender === 'Immature') {

                dataString.totalImmatureCount = addAmount;
            }
            else {

                dataString.totalNACount = addAmount;
            }
            localStorage.setItem(search, JSON.stringify(dataString));

        }

        this.state.collectionCount[i].totalCount = newTot;
        this.setState({
            count: '',
            //species: '',
            //gender: 'n/a',
            comment: '',
            collectionCount: this.state.collectionCount,
            collectionFishCount: [...this.state.collectionFishCount, myCount],
            sampleTotalCount: this.state.sampleTotalCount + addAmount,
            showNegativeWarning: false
        });

    }

    onDeleteCountButton(speciesIndex: any, countIndex: any, count: any) {
        //remove count record from local storage
        let search = speciesIndex.toString();
        let specieCollection = JSON.parse(localStorage.getItem(search) || '{}');
        //specieCollection.counts = specieCollection.counts.filter(data => data.countIndex != countIndex);
        specieCollection.counts.splice(countIndex, 1);
        localStorage.setItem(search, JSON.stringify(specieCollection));

        //remove count record from UI
        this.setState({
            collectionFishCount: GetFishCountCollection()!
        })

        //remove number of fish from summary counts
        const subAmount = Number(count);
        const i = this.state.collectionCount.findIndex((obj => obj.id === speciesIndex.toString().split(',')[1]));
        let newTot = -subAmount;
        const tot = specieCollection.totalCount;
        newTot = newTot + Number(tot);
        specieCollection.totalCount = Number(tot) - subAmount;
        if (this.state.gender === 'Female') {
            const gen = specieCollection.totalFemaleCount;
            specieCollection.totalFemaleCount = Number(gen) - subAmount;
        }
        else if (this.state.gender === 'Male') {
            const gen = specieCollection.totalMaleCount;
            specieCollection.totalMaleCount = Number(gen) - subAmount;
        }
        else if (this.state.gender === 'Immature') {
            const gen = specieCollection.totalImmatureCount;
            specieCollection.totalImmatureCount = Number(gen) - subAmount;
        }
        else {
            const gen = specieCollection.totalNACount;
            specieCollection.totalNACount = Number(gen) - subAmount;
        }
        localStorage.setItem(search, JSON.stringify(specieCollection));
        this.state.collectionCount[i].totalCount = newTot;
        this.setState({
            collectionCount: this.state.collectionCount,
            sampleTotalCount: this.state.sampleTotalCount - subAmount,
            showNegativeWarning: false
        });
}

    NegValueValidation(props: any) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Negative Value Entered.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>The amount you enetered was negative</h4>
                    <p className="popUpText">
                        Amount you entered for {this.state.species} is {this.state.count}
                    </p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ textAlign: "center", alignSelf: "left" }} className="validationButtonLeft" onClick={props.onHide}>Edit</Button>
                    <Button variant="secondary" className="validationButton" onClick={() => this.onAddButton()}>Add Count</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    componentDidMount() {
        const tabletSettings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');
        return tabletSettings.theme;
    }

    render() {
        return (
            <>
                <div className="center-col" data-theme={this.componentDidMount()}>
                    <this.NegValueValidation
                        show={this.state.showNegativeWarning}
                        onHide={() => this.onChangeShowNegWarning(false)}
                        backdrop="static"
                    />
                    {/* <Container fluid> */}
                    <Stack gap={2} className="topRow" >
                        <InputGroup>
                            <BackButton />
                            <div className="pageTitle">General Stats</div>
                        </InputGroup>
                    </Stack>
                    <div className="tableFixHead">
                        <Table bordered className="tableStyle">
                            <thead className="noScroll">
                                <tr className="noScroll">
                                    <th scope="col">Species</th>
                                    <th scope="col">Total Count</th>
                                    <th scope="col">Num Structures</th>
                                    <th scope="col">Num Weights</th>
                                </tr>
                            </thead>
                            {this.dataExists ?
                                <tbody className="generalStatsData">
                                    {!(!Array.isArray(this.state.collectionCount) || !this.state.collectionCount)
                                        &&
                                        this.state.collectionCount.map(
                                            (count, index) =>
                                                <tr key={index}>
                                                    <td>{count.id}</td>
                                                    <td>{count.totalCount}</td>
                                                    <td>{count.totalStructres}</td>
                                                    <td>{count.totalWeightCount}</td>
                                                </tr>
                                        )
                                    }
                                </tbody>
                                :
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>There are currently no samples.</td>
                                    </tr>
                                </tbody>
                            }

                            <tbody className="noScrollBottom">
                                <tr key="total">
                                    <td>Total for Sample:</td>
                                    <td>{this.state.sampleTotalCount}</td>
                                    <td> {this.state.sampleStructureCount}</td>
                                    <td>{this.state.sampleWeightCount}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <Row style={{ textAlign: "left", alignSelf: "left", padding: "10px" }} className="generalStatsLabel">
                        Add Fish Count:
                    </Row>
                    <Container fluid>
                        <Card className="genStatsAddCountContainer">
                            <Form as={Col}>
                                <Form.Group as={Container} className="formContainer">
                                    <Row>
                                        {/* <Col> */}
                                        {/*<Form.Group style={{ textAlign: "center", alignSelf: "center" }} as={Col} sm={2} controlId="mainLabel">*/}
                                        {/*    <Form.Label style={{ textAlign: "center", alignSelf: "center" }} as={Row} className="generalStatsLabel">Add Fish</Form.Label>*/}
                                        {/*    <Form.Label style={{ textAlign: "center", alignSelf: "center" }} as={Row} className="generalStatsLabel">Count</Form.Label>*/}
                                        {/*</Form.Group>*/}
                                        {/* </Col> */}
                                        <Col>
                                            <Row>
                                                <Form.Group as={Col} controlId="formCount">
                                                    <Form.Label className="generalStatsLabel">Count</Form.Label>
                                                    <Form.Control type="number" value={this.state.count} onChange={this.onChangeCount} pattern="[0-9]*" as="input" className="genStatsInput" />
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formSpecies">
                                                    <Form.Label className="generalStatsLabel">Species</Form.Label>
                                                    <Dropdown drop="down" onSelect={this.onChangeSpecies}>
                                                        <Dropdown.Toggle className="generalStatDrop" id="dropdown-button-dark-example1" variant="secondary">
                                                            <div className="genStatsDropText">{this.state.species}</div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu >
                                                            {this.dataExists && !(!Array.isArray(this.speciesCodes) || !this.speciesCodes) ? this.speciesCodes.map((code, index) => (
                                                                <Dropdown.Item key={index} eventKey={code}>{code}</Dropdown.Item>
                                                            )
                                                            )
                                                                :
                                                                <Dropdown.Item eventKey={0}>{0}</Dropdown.Item>}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGender">
                                                    <Form.Label className="generalStatsLabel">Gender</Form.Label>

                                                    <Dropdown drop="down" onSelect={this.onChangeGender}>
                                                        <Dropdown.Toggle
                                                            className="generalStatDrop" id="dropdown-button-dark-example1" variant="secondary">
                                                            <div className="genStatsDropText">
                                                                {this.state.gender}</div>
                                                            {/* <div className="genStatsCaret"></div> */}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu >
                                                            <Dropdown.Item eventKey="n/a">n/a</Dropdown.Item>
                                                            <Dropdown.Item eventKey="Immature">Immature</Dropdown.Item>
                                                            <Dropdown.Item eventKey="Male">Male</Dropdown.Item>
                                                            <Dropdown.Item eventKey="Female">Female</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Form.Group>

                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} controlId="formCount">
                                                    <Form.Label className="generalStatsLabel">Comments</Form.Label>
                                                    <Form.Control
                                                        onChange={this.onChangeComment}
                                                        value={this.state.comment}
                                                        className="genStatsTextInput"
                                                        as="textarea"
                                                    />
                                                </Form.Group>

                                            </Row>
                                        </Col>
                                        <Col sm={1} style={{ textAlign: "center", alignSelf: "center" }}>
                                            <Button onClick={this.checkNeg} style={{ textAlign: "center", alignSelf: "right" }} data-placement="top" className="plus alt">
                                            </Button>
                                        </Col>
                                    </Row>

                                </Form.Group>

                            </Form>
                            <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "10px" }}></Row>
                            <div style={{ paddingTop: "20px" }}>
                                <hr />
                                <ul>
                                    {
                                        this.state.collectionFishCount.map(
                                            (fishCount, index) =>
                                                <li key={index}>
                                                    <Row style={{ paddingBottom: "10px" }}>
                                                        <Col>
                                                            <Button variant="secondary" className="listButtons" disabled>{fishCount.count}</Button>
                                                        </Col>
                                                        <Col>
                                                            <Button variant="secondary" className="listButtons" disabled>{fishCount.species}</Button>
                                                        </Col>
                                                        <Col>
                                                            <Button variant="secondary" className="listButtons" disabled>{fishCount.gender}</Button>
                                                        </Col>
                                                        <Col>
                                                            <Button data-placement="top" onClick={() => this.onDeleteCountButton(fishCount.speciesIndex, fishCount.countIndex, fishCount.count)} className="minus"></Button>
                                                            {/*{fishCount.speciesIndex.toString()}*/}
                                                        </Col>
                                                    </Row>
                                                </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </Card>
                    </Container>

                    <Row style={{ textAlign: "center", alignSelf: "center", paddingBottom: "15px" }}></Row>
                    {/* </Card> */}
                    {/* </Container>        */}
                </div>
            </>
        );
    }
}


function GetFishCountCollection() {
    let newCounts: any[] = [];
    let count = 0;
    let allCounts: any[] = [];

    let fishSampleIndex = JSON.parse(localStorage.getItem('fishSampleIndex') || '{}');
    let localStorageCollection = JSON.parse(localStorage.getItem('fishSample') || '{}');
    let editFishSampleIndex = JSON.parse(localStorage.getItem('editFishSampleIndex') || '{}');
    if (localStorage.getItem('editFishSampleIndex')) { //we are currently editing
        count = editFishSampleIndex;
        fishSampleIndex = editFishSampleIndex;
    }
    else if (localStorage.getItem('fishSampleIndex')) {
        count = fishSampleIndex; //if an index exists
    } else {
        count = 0;
    }

    if (localStorage.getItem('fishSample')) {
        let speciesCodes = localStorageCollection[fishSampleIndex].speciesData;
        let i = 0;
        for (i; i < speciesCodes.length; i++) {
            let localIndex = count + "," + speciesCodes[i];
            let countsFromLocalStorage = JSON.parse(localStorage.getItem(localIndex) || '{}');
            if (countsFromLocalStorage.counts) {
                if (countsFromLocalStorage.counts.length > 0) {
                    let k = 0;
                    for (k; k < countsFromLocalStorage.counts.length; k++) {
                        let newCount = {
                            count: countsFromLocalStorage.counts[k].count,
                            gender: countsFromLocalStorage.counts[k].gender,
                            species: speciesCodes[i],
                            speciesIndex: localIndex,
                            countIndex: k
                        }
                        newCounts = [...newCounts, newCount];
                    }
                }
            }

                //this.allFishCounts = [...this.allFishCounts, ...this.newCounts];
        }
            return (newCounts);

    }

    //return (
    //    <ul>
    //        {
    //            allFishCounts.map(
    //                (fishCount, index) =>
    //                    <li key={index}>
    //                        <Row style={{ paddingBottom: "10px" }}>
    //                            <Col>
    //                                <Button variant="secondary" className="listButtons" disabled>{fishCount.count}</Button>
    //                            </Col>
    //                            <Col>
    //                                <Button variant="secondary" className="listButtons" disabled>{fishCount.species}</Button>
    //                            </Col>
    //                            <Col>
    //                                <Button variant="secondary" className="listButtons" disabled>{fishCount.gender}</Button>
    //                            </Col>
    //                            <Col>
    //                                {/*<Button variant="secondary" className="listValueButton" disabled>{setting.value}</Button>*/}
    //                                {/*<Button data-placement="top" onClick={() => onDeleteButton(index)} className="minus"></Button>*/}
    //                            </Col>
    //                        </Row>
    //                    </li>
    //            )
    //        }
    //    </ul>
    //)

}


export function BackButton(props: any) {
    let navigate = useNavigate();
    function handleClick() {
        navigate("/dataEntryPage");
    }
    return (
        <>
            <Button variant="secondary" className="backButton" onClick={() => handleClick()}>
                Back
            </Button>
        </>
    );
};

export default GeneralStats;