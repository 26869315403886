import React from "react";
import logo from "../resources/logo.png";
import alt_logo from "../resources/alt_logo.png";
import './lightstyle.css';
import styled from "styled-components";
import { useNavigate} from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";


const Button = styled.button`
  background: var(--button);
  font-size: 30px;
  color: var(--text-primary);
  height: 150px;
  width: 375px;
  margin: 0px 30px;
  border-radius: 5px;
  outline: 0;
  cursor: pointer;
`;

const SettingsButton = styled.button`
  background-color: var(--button);
  font-size: 15px;
  color: var(--text-primary);
  height: 50px;
  width: 150px;
  margin: 10px 50px;
  border-radius: 5px;
  outline: 0;
  cursor: pointer;
`;

const LoginButtonStyle = styled.button`
background: var(--button);
font-size: 15px;
color: var(--text-primary);
height: 50px;
width: 150px;
border-radius: 5px;
outline: 0;
cursor: pointer;
`;

type tabletSettings = {
  ageStructureLimit: any,
  weightLimit: any,
  wrmin: any,
  wrmax: any,
  theme: any
};

class LandingPage extends React.Component<any, tabletSettings> {

    tabletSettings: tabletSettings;

    constructor(props: any) {
      super(props);

        this.tabletSettings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');
        if (localStorage.getItem('tabletSettings')) {
            this.state = {
                ageStructureLimit: this.tabletSettings.ageStructureLimit,
                weightLimit: this.tabletSettings.weightLimit,
                wrmin: this.tabletSettings.wrmin,
                wrmax: this.tabletSettings.wrmax,
                theme: this.tabletSettings.theme
            }
        } else {
            this.state = {
                ageStructureLimit: '5',
                weightLimit: '10',
                wrmin: '70',
                wrmax: '150',
                theme: 'light'
            };
        }
    }

    getLastFetch() {
        if (localStorage.getItem('lastFetch')) {
            return localStorage.getItem('lastFetch');
        }
        else {
            return 'Nothing Fetched';
        }
    }

    render() {
        return (
        <section className = "home_page" id = "selectChoice" data-theme={this.state.theme}>
            <div style={{display: "flex",justifyContent: "space-between"}}>
	    	    <SettingsSelect className= "fetch_data"title={"Fetch Data"} path={"/"}/>
                <SettingsSelect className= "tablet_settings "title={"Tablet Settings"} path={"/tabletSettings"}/>
            </div>
            <div>
                <div style={{display: "flex",justifyContent: "center",alignItems: "center",paddingBottom: "55px"}}>
                        <img src={this.state.theme === 'light' ? logo : alt_logo} alt="Logo" />
                </div>
                <div style={{display: "flex",justifyContent: "center",alignItems: "center",paddingBottom: "55px"}}>
                    <ButtonSelect title={"Previous Fish Samples"} path={"/previousFishSamples"}/>
                    <ButtonSelect title="New Fish Sample"path={"/sampleSettings"}/>
                </div>
                <div style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
                    <ButtonSelect title={"Previous Water Samples"} path={"/previousWaterSamples"}/>
                    <ButtonSelect title={"New Water Sample"} path={"/waterQualityInput"}/>
                </div>
                {/*<LoginButton />*/}
                {/*<LogoutButton />*/}
            </div>
            {/*<div style={{ display: "flex", justifyContent: "left", alignItems: "end", height: '0px' }}></div>*/}
            <div style={{ position: "absolute", bottom: 0, left: 0 }}>
                    Version 7.0 - Last Fetch: {this.getLastFetch()}
            </div>

          </section>
      );
    }
  }

  const ButtonSelect = (props: any) => {
    let navigate = useNavigate();
    return (
        <div>
        <Button onClick={() => navigate(props.path)}>{props.title}</Button>
        </div>
    );
};

  const SettingsSelect = (props: any) => {
    let navigate = useNavigate();
    return (
        <div>
        <SettingsButton onClick={() => navigate(props.path)}>{props.title}</SettingsButton>
        </div>
    );
};

//const LoginButton = () => {
//    const { loginWithRedirect } = useAuth0();

//    return <LoginButtonStyle style={{ position: "absolute", bottom: 10, right: 180 }} onClick={() => loginWithRedirect()}>Log In</LoginButtonStyle>;
//};

//const LogoutButton = () => {
//    const { logout } = useAuth0();

//    return (
//        <LoginButtonStyle style={{ position: "absolute", bottom: 10, right: 20 }} onClick={() => logout({ returnTo: window.location.origin })}>
//            Log Out
//        </LoginButtonStyle>
//    );
//};
  
  export default LandingPage;
