import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {InputGroup, Card, Container, Row, Col, Form, Button} from 'react-bootstrap';
import './lightstyle.css';
import { useNavigate} from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";


type WaterSampleSettings = {
    id: any,
    date: any,
    owner: any,
    station: any,
    waterbodyCode: any,
    waterbodyDescript: string,
    waterQualityComment: string,
    useCPUE: any,
    newParam: any,
    newValue: any,
    newDepth: any,
    waterCollection: ParamValue[],
    keywords: any,
    comments: any,
    effort: any,
    validOwner: boolean,
    validStation: boolean,
    validWaterbodyCode: boolean
};

type ParamValue = {
    id: any,
    value: any,
    depth: any,
    comment: any
}

const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        background: "var(--button) !important",
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        borderColor: state.isFocused ? "blue" : "black",
        margin: "0px 30px",
        length: "80%",
        width: "90%",
        height: "70px",
        color: "var(--text-primary) !important",
        fontSize: "30px",
        testDecorationColor: "white",
        boxShadow: state.isFocused ? null : null,

    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        color: "black",
        fontSize: "30px",
    }),
    value: (base: any) => ({
        ...base,
        color: "var(--text-primary)"
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "var(--text-primary)",
        fontWeight: 400
    }),
    menu: (base: any, state: any) => ({
        ...base,
        color: state.isSelected ? "white" : "black",
        fontSize: "30px",
        borderRadius: 0,
        marginTop: 0,
        width: "90%",
        margin: "0px 30px",
    }),
    menuList: (base: any, state: any) => ({
        ...base,
        color: state.isSelectd ? "white" : "black",
        margin: "0px 30px",
    })
};

const customStylesInvalid = {
    control: (base: any, state: any) => ({
        ...base,
        background: "var(--button) !important",
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        borderWidth: "2px",
        borderColor: state.isFocused ? "blue" : "red",
        margin: "0px 30px",
        length: "80%",
        width: "90%",
        height: "70px",
        color: "var(--text-primary) !important",
        fontSize: "30px",
        testDecorationColor: "white",
        boxShadow: state.isFocused ? null : null,

    }),
    input: (base: any) => ({
        ...base,
        color: "var(--text-primary)"
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        color: "black",
        fontSize: "30px",
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "var(--text-primary)",
        fontWeight: 400
    }),
    menu: (base: any, state: any) => ({
        ...base,
        color: state.isSelected ? "white" : "black",
        fontSize: "30px",
        borderRadius: 0,
        marginTop: 0,
        width: "90%",
        margin: "0px 0px",
    }),
    menuList: (base: any, state: any) => ({
        ...base,
        color: state.isSelectd ? "white" : "black",
        margin: "0px 0px",
    })
};

class WaterQualityInput extends React.Component<any, WaterSampleSettings> {

    waterQualityCollection: WaterSampleSettings[];
    count: any;
    waterQualityIndex: any;
    editing: any;
    editIndex: any;
    lastData: any;
    ownersList: any[];
    waterCodesList: any[];
    methodsList: any[];
    wqParamsList: any[];
    currDate: any;
    theme: any = "light"; //default

    constructor(props: any) {
        super(props);

        //Informaiton for dropdowns
        const ownersObj = JSON.parse(localStorage.getItem('OwnersNames') || '{}');
        const owners: string[] = [];
        Object.keys(ownersObj).map((key) => {
            owners.push(ownersObj[key] + " " + key);
        });
        const watersObj = JSON.parse(localStorage.getItem('WaterCodes') || '{}');
        const waters: string[] = [];
        Object.keys(watersObj).map((key) => {
            waters.push(key + "-" + watersObj[key]);
        });
        const methodsObj = JSON.parse(localStorage.getItem('MethodCodes') || '{}');
        const methods: string[] = [];
        Object.keys(methodsObj).map((key) => {
            methods.push(key + "-" + methodsObj[key]);
        });
        const parametersObj = JSON.parse(localStorage.getItem('WQParameters') || '{}');
        const params: string[] = [];
        Object.keys(parametersObj).map((key) => {
            params.push(parametersObj[key]);
        });
        this.ownersList = owners;
        this.methodsList = methods;
        this.waterCodesList = waters;
        this.wqParamsList = params;

        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeOwner = this.onChangeOwner.bind(this);
        this.onChangeStation = this.onChangeStation.bind(this);
        this.onChangeWaterCode = this.onChangeWaterCode.bind(this);
        this.onChangeWaterDescription = this.onChangeWaterDescription.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onWaterQualityParamValue = this.onWaterQualityParamValue.bind(this);
        this.onCheckedboxValue = this.onCheckedboxValue.bind(this);
        this.onAddButton = this.onAddButton.bind(this);
        this.onDeleteButton = this.onDeleteButton.bind(this);
        this.onCurrentParamChange = this.onCurrentParamChange.bind(this);
        this.onChangeWaterComment = this.onChangeWaterComment.bind(this);
        this.onChangeComments = this.onChangeComments.bind(this);
        this.onChangeKeywords = this.onChangeKeywords.bind(this);
        this.onChangeEffort = this.onChangeEffort.bind(this);
        this.onCurrentDepthChange = this.onCurrentDepthChange.bind(this);
        this.setValidOwner = this.setValidOwner.bind(this);
        this.setValidStation = this.setValidStation.bind(this);
        this.setValidWaterbodyCode = this.setValidWaterbodyCode.bind(this);
        this.onCurrentDepthChange = this.onCurrentDepthChange.bind(this);

        let date: Date = new Date();
        //months are 0 indexed
        let month = date.getMonth() < 9 ? "0" + String(date.getMonth()+1) : String(date.getMonth()+1);
        let day = date.getDate() < 10 ? "0" + String(date.getDate()) : String(date.getDate());
        this.currDate = String(date.getFullYear()) + "-" + month + "-" + day;
        this.editing = false;
        this.editIndex = JSON.parse(localStorage.getItem('editWaterQualityIndex') || '{}');
        this.waterQualityIndex = JSON.parse(localStorage.getItem('waterQualityIndex') || '{}');
        if (localStorage.getItem('editWaterQualityIndex')) { //if this exists, then we are editing water info
            this.editing = true;
            this.count = parseInt(this.editIndex);
        } 
        else if (localStorage.getItem('waterQualityIndex')) {
            this.count = parseInt(this.waterQualityIndex) + 1;
        } else {
            this.waterQualityIndex = 0;
            this.count = 0;
        }
        this.waterQualityCollection = JSON.parse(localStorage.getItem('waterQualitySettings') || '{}');
        
        if (localStorage.getItem('tabletSettings')) {
            let settings = JSON.parse(localStorage.getItem('tabletSettings') || '{}');
            this.theme = settings.theme;
        }

        if (localStorage.getItem('waterQualitySettings')) {
            if(this.editing){
                this.lastData = this.waterQualityCollection[this.count];
                this.state = {
                    id: this.count,
                    date: this.lastData.date,
                    owner: this.lastData.owner,
                    station: this.lastData.station,
                    waterbodyCode: this.lastData.waterbodyCode,
                    waterbodyDescript: this.lastData.waterbodyDescript,
                    waterQualityComment: this.lastData.waterQualityComment,
                    useCPUE: this.lastData.useCPUE,
                    newParam: '',
                    newValue: '',
                    newDepth: '',
                    waterCollection: this.lastData.waterCollection,
                    keywords: this.lastData.keywords,
                    comments: this.lastData.comments,
                    effort: this.lastData.effort,
                    validOwner: true,
                    validStation: true,
                    validWaterbodyCode: true
                };
            } else {
                this.lastData = this.waterQualityCollection[this.waterQualityIndex];
                this.state = {
                    id: this.count,
                    date: this.currDate,
                    owner: this.lastData.owner,
                    station: '',
                    waterbodyCode: this.lastData.waterbodyCode,
                    waterbodyDescript: '',
                    waterQualityComment: '',
                    useCPUE: this.lastData.useCPUE,
                    newParam: '',
                    newValue: '',
                    newDepth: '',
                    waterCollection: [],
                    keywords: '',
                    comments: '',
                    effort: '',
                    validOwner: true,
                    validStation: true,
                    validWaterbodyCode: true
                };
            }
        } else {
            this.state = {
                id: 0,
                date: this.currDate,
                owner: '',
                station: '',
                waterbodyCode: '',
                waterbodyDescript: '',
                waterQualityComment: '',
                useCPUE: '',
                newParam: '',
                newValue: '',
                newDepth: '',
                waterCollection: [],
                keywords: '',
                comments: '',
                effort: '',
                validOwner: true,
                validStation: true,
                validWaterbodyCode: true
            };
        }
    }

    onChangeDate(e: any) {
        this.setState({
            date: e.target.value
        })
    }

    onSubmit(e: any) {
       // e.preventDefault();
        if(this.editing){
           // const num = this.editIndex;
            this.waterQualityCollection[this.editIndex] = this.state;
            localStorage.removeItem('editWaterQualityIndex'); //done edting
            localStorage.setItem('waterQualitySettings', JSON.stringify(this.waterQualityCollection));           
        } else {
            const num = this.count;
            const data = this.state;
            this.waterQualityCollection[num] = data;
            localStorage.setItem('waterQualityIndex', JSON.stringify(this.count));
            localStorage.setItem('waterQualitySettings', JSON.stringify(this.waterQualityCollection));
        }   
    }

    onChangeOwner(e: any) {
        this.setState({
            owner: e.value
        })
    }

    onChangeStation(e: any) {
        this.setState({
            station: e.target.value
        })
    }

    onChangeWaterCode(e: any) {
        this.setState({
            waterbodyCode: e.value
        })
    }

    onWaterQualityParamValue(e: any) {
        this.setState({
            newValue: e.target.value
        })
    }

    onCurrentParamChange(e: any) {
        this.setState({
            newParam: e.value
        })
    }

    onCurrentDepthChange(e: any) {
        this.setState({
            newDepth: e.target.value
        })
    }


    onChangeWaterDescription(e: any) {
        this.setState({
            waterbodyDescript: e.target.value
        })
    }

    onChangeWaterComment(e: any) {
        this.setState({
            waterQualityComment: e.target.value
        })
    }

    onCheckedboxValue(e: any) {
        this.setState({
            useCPUE: e.target.value
        })
    }

    onDeleteButton(id: any) {
        const newList = this.state.waterCollection.filter((item, index) => index !== id);
        this.setState({
            waterCollection: newList
        })
    }

    onChangeComments(e: any) {
        this.setState({
            comments: e.target.value
        })
    }

    onChangeKeywords(e: any) {
        this.setState({
            keywords: e.target.value
        })
    }

    onChangeEffort(e: any){
        this.setState({
            effort: e.target.value
        })
    }

    setValidStation(e: boolean){
        this.setState({
            validStation: e
        })
    }

    setValidOwner(e: boolean){
        this.setState({
            validOwner: e
        })
    }

    setValidWaterbodyCode(e: boolean){
        this.setState({
            validWaterbodyCode: e
        })
    }

    onAddButton(e: any) {
        if (this.state.newParam !== '' && this.state.newValue !== '') {
            // const i = this.state.waterCollection.findIndex((obj => obj.id === this.state.newParam));
            // if (i >= 0) {
            //     this.setState({
            //         waterCollection: [
            //             ...this.state.waterCollection.slice(0, i),
            //             {
            //                 ...this.state.waterCollection[i],
            //                 value: this.state.newValue,
            //                 comment: this.state.waterQualityComment
            //             },
            //             ...this.state.waterCollection.slice(i + 1)
            //         ],
            //         newParam: '',
            //         newValue: '',
            //     })
            // } else {
                this.setState({
                    waterCollection: [...this.state.waterCollection, { id: this.state.newParam, value: this.state.newValue, depth: this.state.newDepth, comment: this.state.waterQualityComment }],
                    newParam: '',
                    newValue: '',
                    newDepth: '',
                    waterQualityComment: '',
                })
           // }
        }
    }

    render() {
        return (
            <div className="center-col" data-theme={this.theme}>
                <Container fluid="lg">
                <Row style={{ paddingBottom: "20px" }}></Row>
                    <Row>
                    {this.editing ? <Col></Col>
                    :
                        <Col>                    
                            <BackButton />                    
                        </Col>
                    }  
                        <Col style={{ paddingBottom: "50px" }}>
                            <h1 style={{ textAlign: "center", alignSelf: "center" }}>Water Quality</h1>
                        </Col>
                        <Col></Col>
                    </Row>

                    <Row style={{ paddingBottom: "40px" }}>
                        <Col>
                        <label className="labelD">Date *</label>
                        <input id="dateinput" className="numberOnlyText" type="date" name="dateinput" onChange={this.onChangeDate} value={this.state.date}></input>
                        </Col>

                        <Col>
                            <label className="labelD">Owner *</label>
                            <div className="dropheight">
                                <Select styles={this.state.validOwner ? customStyles : customStylesInvalid} onChange={this.onChangeOwner} 
                                placeholder={this.state.owner === "" ? "Choose Owner...": this.state.owner}  options={this.ownersList.map((title) => { return { value: title, label: title } })} />
                            </div>
                            {this.state.validOwner ? <></>  :
                        <label className="invalidLabel"> Provide an Owner!
                        </label> }
                        </Col>
                    </Row>

                    <Row style={{ paddingBottom: "40px" }}>
                    <Col>
                    <label className="labelD">Station *</label>
                        <input inputMode="numeric" enterKeyHint="done" type="number" name="stationValue" className={this.state.validStation ? "numberOnlyText" : "numberOnlyTextInvalid"} pattern="[0-9]*" onChange={this.onChangeStation} value={this.state.station} placeholder="Station Number:"></input>
                        {this.state.validStation ? <></>  :
                        <label className="invalidLabel"> Provide a Station Number!
                        </label> }
                    </Col>
                    <Col></Col>

                    </Row>

                    <Row style={{ alignContent: "ceneter", paddingBottom: "40px" }}>
                        <Col>
                        <label className="labelD">Waterbody Code *</label>
                            <Select styles={this.state.validWaterbodyCode ? customStyles : customStylesInvalid} onChange={this.onChangeWaterCode} 
                            placeholder={this.state.waterbodyCode === '' ? "Choose Waterbody Code..." : this.state.waterbodyCode} 
                            options={this.waterCodesList.map((title) => { return { value: title, label: title } })} />
                            {this.state.validWaterbodyCode ? <></>  :
                        <label className="invalidLabel"> Provide a Waterbody Code!
                        </label> }
                        </Col>

                        <Col>
                        <label className="labelD">Waterbody Description</label>
                            <Form.Group controlId="waterDescription">
                                <Form.Control className="textSettings" value={this.state.waterbodyDescript} 
                                    placeholder={"Enter Waterbody Description..."} onChange={this.onChangeWaterDescription} as="textarea" rows={3} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row >
                        <Col >
                            <label className="labelD">Keywords</label></Col>
                        <Col>
                            <label className="labelD">Comments</label></Col>
                    </Row>
                    <Row style={{ paddingBottom: "35px" }}>
                        <Col>
                            <Form.Group controlId="keywordsText">
                                <Form.Control className="textSettings" value={this.state.keywords} placeholder={"Enter Keywords..."} 
                                onChange={this.onChangeKeywords} as="textarea" rows={3} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="commentsText">
                                <Form.Control className="textSettings" value={this.state.comments} placeholder={"Enter Comments..."} 
                                onChange={this.onChangeComments} as="textarea" rows={3} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: "40px" }}>
                    </Row>
                    <Card className="cardDivider">
                        <Row >
                            <Col >
                                <h1 style={{ textAlign: "center", alignSelf: "center", paddingBottom: "40px" }}>Water Quality Input</h1>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className="labelD">Parameter</label></Col>
                            <Col className="smallColWidth">
                                <label className="labelD">Depth</label></Col>
                                <Col className="smallColWidth">
                                <label className="labelD">Value</label></Col>
                              
                        </Row>

                        <Row style={{ paddingBottom: "5px" }}>
                            <Col>
                                <Select styles={customStyles} onChange={this.onCurrentParamChange} 
                                value={this.state.newParam}
                                placeholder={this.state.newParam === '' ? "Choose Parameter..." : this.state.newParam} 
                                options={this.wqParamsList.map((title) => { return { value: title, label: title } })} />
                            </Col>
                            <Col className="smallColWidth">
                                <div>
                                    <input inputMode="numeric" enterKeyHint="done" type="number" name="depthValue" className="numberOnlyTextSmall" pattern="[0-9]*" onChange={this.onCurrentDepthChange} value={this.state.newDepth} placeholder="Depth:"></input>

                                </div>
                            </Col>
                            <Col className="smallColWidth">
                                <div>
                                    <input inputMode="numeric" enterKeyHint="done" type="number" name="settingsParam" className="numberOnlyTextSmall" pattern="[0-9]*" onChange={this.onWaterQualityParamValue} value={this.state.newValue} placeholder="Value:"></input>

                                </div>
                            </Col>
                        </Row>
                        <Row  style={{ paddingBottom: "5px" }}>
                            <Col style={{ textAlign: "center", alignSelf: "center" }}>
                            <div>
                            <InputGroup>
                             {/* <input className="textSettingsComment" placeholder={"Enter WaterQuality Comment..."} value={this.state.waterQualityComment} onChange={this.onChangeWaterComment}/>    */}
                             <Form.Label className="labelD">Water Quality Comment:</Form.Label>
                             <Form.Control onChange={this.onChangeWaterComment} className="textSettingsComment" 
                             placeholder={"Enter WaterQuality Comment..."} value={this.state.waterQualityComment} 
                             as="textarea" rows={3} />   
                            
                                 <Button data-placement="top" onClick={this.onAddButton} className="plus alt">
                                </Button>
                             </InputGroup>
                             </div>
                            </Col>
                        </Row>
                    
                        <div>
                            <ul>
                                {
                                    this.state.waterCollection.map(
                                        (setting, index) =>
                                            <li key={index}>
                                                    <hr/>
                                                <Row style={{ paddingBottom: "10px" }}>
                                                    <Col>
                                                        <Form.Control as="textarea" readOnly className="listValueButton" value={setting.id}/>
                                                    </Col>
                                                    <Col className="smallColWidth">
                                                        <Form.Control as="textarea" readOnly className="listValueButton" value={"Depth: " + setting.depth}/>
                                                    </Col>
                                                    <Col className="smallColWidth">
                                                        <Form.Control as="textarea" readOnly className="listValueButton" value={"Value:" + setting.value}/>  
                                                    </Col>
                                                    </Row>
                                                    <Row>
                                                    <Col>
                                                    <InputGroup>
                                                        <Form.Control as="textarea" rows={3} readOnly className="listButtonsComment" value={setting.comment}></Form.Control>
                                                        <Button onClick={() => this.onDeleteButton(index)} className="minus"></Button>
                                                    </InputGroup>
                                                    </Col>
                                                </Row>

                                            </li>
                                    )
                                }
                            </ul>
                        </div>

                        <Row style={{ paddingBottom: "40px" }}>
                        </Row>
                    </Card>
                    <Row style={{ paddingBottom: "50px" }}></Row>
                </Container>
                <Row>
                <Col></Col>
                <Col style={{ textAlign: "center", alignSelf: "center"}}>
                    <div>
                        <ButtonSubmit edit={this.editing} state={this.state} 
                        validOwner={this.setValidOwner}
                        validStation={this.setValidStation}
                        validWater={this.setValidWaterbodyCode}
                        index={this.count} 
                        collection={this.waterQualityCollection}></ButtonSubmit>
                    </div>
                    </Col>
                    <Col></Col>
                    </Row>
                    <Row style={{ paddingBottom: "50px" }}></Row>
            </div>
        );
    }
}

export function ButtonSubmit(props: any){
    let navigate = useNavigate();
    let valid : boolean = true;

    const data = { id: props.state.id,
        date: props.state.date,
        owner: props.state.owner,
        station: props.state.station,
        waterbodyCode: props.state.waterbodyCode,
        waterbodyDescript: props.state.waterbodyDescript,
        waterQualityComment: props.state.waterQualityComment,
        useCPUE: props.state.useCPUE,
        newParam: props.state.newParam,
        newValue: props.state.newValue,
        newDepth: props.state.newDepth,
        waterCollection: props.state.waterCollection,
        keywords: props.state.keywords,
        comments: props.state.comments,
        effort: props.state.effort
    }
    const edit = props.edit;
    const num = props.index;
    const collection =props.collection;
    function handleClick() {
        if(props.state.owner === ''){
            valid = false;
            props.validOwner(false);
        }
        if(props.state.station === ''){
            valid = false;
            props.validStation(false);
        }
        if(props.state.waterbodyCode === ''){
            valid = false;
            props.validWater(false);
        }
        if(valid){
            if(edit){
                collection[num] = data;
                localStorage.removeItem('editWaterQualityIndex'); //done edting
                localStorage.setItem('waterQualitySettings', JSON.stringify(collection));           
            } else {
                collection[num] = data;
                localStorage.setItem('waterQualityIndex', JSON.stringify(num));
                localStorage.setItem('waterQualitySettings', JSON.stringify(collection));
            }
            navigate("/");
        }
      }
     return (
    <>
        <Button variant="secondary" className="submitButton" onClick={() => handleClick()}>
        Save
    </Button>
    </>
    );
};

export function BackButton(props: any){
    let navigate = useNavigate();
    function handleClick() {
        navigate("/");
      }
     return (
    <>
        <Button variant="secondary" className="backButton" onClick={() => handleClick()}>
        Back
    </Button>
    </>
    );
};

export default WaterQualityInput;