import React from "react";
import './dataEntryPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './lightstyle.css';
import { Navigate } from 'react-router-dom';


type AppInformationState = {
    error: string,
    speciesLoaded: boolean,
    watersLoaded: boolean,
    ownersLoaded: boolean,
    methodsLoaded: boolean,
    gearsLoaded: boolean,
    parametersLoaded: boolean,
    lengthValsLoaded: boolean,
    tagsLoaded: boolean,
    methodInputsLoaded: boolean,
    fullSpeciesLoaded: boolean,
    structuresLoaded: boolean,
    purposeLoaded: boolean,
}

/*
type SpeciesInfo = {
    speciesCode: string,
    speciesName: string,
    maxLength: string,
}
*/

class appInformation extends React.Component<any, AppInformationState>{

    constructor(props: any) {
        super(props);
        this.state = {
            error: " ",
            speciesLoaded: false,
            watersLoaded: false,
            ownersLoaded: false,
            methodsLoaded: false,
            gearsLoaded: false,
            parametersLoaded: false,
            lengthValsLoaded: false,
            tagsLoaded: false,
            methodInputsLoaded: false,
            fullSpeciesLoaded: false,
            structuresLoaded: false,
            purposeLoaded: false,
        };

        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    //componentWillUnmount() {
       
    //  }
    async fetchData() {
        //access autho

        fetch("api/Codes", {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.text()).then((result) => {
            localStorage.setItem("SpeciesNames", result);
            this.setState({ speciesLoaded: true });
        },
            (error) => {

                console.log("Error fetching Species Names and Codes");
                this.setState({ speciesLoaded: true });
            }
        );

        fetch("api/GetWaterCodes", {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.text()).then((result) => {
            localStorage.setItem("WaterCodes", result);
            this.setState({ watersLoaded: true });
        },
            (error) => {

                console.log("Error fetching WaterBodyCodes");
                this.setState({ watersLoaded: true });
            }
        );


        fetch("api/GetOwners", {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.text()).then((result) => {
            localStorage.setItem("OwnersNames", result);
            this.setState({ ownersLoaded: true });
        },
        (error) => {

                console.log("Error fetching OwnersNames");
                this.setState({ ownersLoaded: true });
        }
        );
        fetch("api/GetOwnerID", {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.text()).then((result) => {
            localStorage.setItem("OwnersID", result);
            this.setState({ ownersLoaded: true });
        },
            (error) => {

                console.log("Error fetching OwnersNames");
                this.setState({ ownersLoaded: true });
            }
        );

        fetch("api/GetMethods", {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.text()).then((result) => {
            localStorage.setItem("MethodCodes", result);
            this.setState({ methodsLoaded: true });
        },
            (error) => {

                console.log("Error fetching Methods");
                this.setState({ methodsLoaded: true });
            }
        );

        fetch("api/GetGearSettings", {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.text()).then((result) => {
            localStorage.setItem("GearSettingsCodes", result);
            this.setState({ gearsLoaded: true });
        },
            (error) => {

                console.log("Error fetching gear settings");
                this.setState({ gearsLoaded: true });
            }

        );
        fetch("api/GetWQParameters", {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.text()).then((result) => {
            localStorage.setItem("WQParameters", result);
            this.setState({ parametersLoaded: true });
        },
            (error) => {

                console.log("Error fetching water quality parameters");
                this.setState({ parametersLoaded: true });
            }
        );        


        fetch("api/GetTagMarkTypes", {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.text()).then((result) => {
            localStorage.setItem("tagMarkTypes", result);
            this.setState({ tagsLoaded: true });
        },
            (error) => {

                console.log("Error fetching tag mark types");
                this.setState({ tagsLoaded: true });
            }
        );
        
        fetch("api/GetAllMethods", {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.text()).then((result) => {
            localStorage.setItem("Methods&Inputs", result);
            this.setState({ methodInputsLoaded: true });
        },
            (error) => {

                console.log("Error fetching method inputs");
                this.setState({ methodInputsLoaded: true });
            }
        );
        fetch("api/GetWsValidations", {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.text()).then((result) => {
            localStorage.setItem("FullSpeciesInfo", result);
            this.setState({ fullSpeciesLoaded: true });
        },
            (error) => {

                console.log("Error fetching full species inputs");
                this.setState({ fullSpeciesLoaded: true });
            }
        );
        fetch("api/GetAgeStructureTypes", {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.text()).then((result) => {
            localStorage.setItem("AgeStructureTypes", result);
            this.setState({ structuresLoaded: true });
        },
            (error) => {
                console.log("Error fetching full structure types");
                this.setState({ structuresLoaded: true });
            }
        );
        fetch("api/GetAgeStructurePurpose", {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.text()).then((result) => {
            localStorage.setItem("AgeStructurePurpose", result);
            this.setState({ purposeLoaded: true });
        },
            (error) => {
                console.log("Error fetching age structure purpose");
                this.setState({ purposeLoaded: true });
            }
        );

    }
    render() {
        var myDate = new Date();
        var online = navigator.onLine;
        if (online) {
            fetch('https://www.google.com/', {
                mode: 'no-cors',
            }).catch(() => {
                online = false;
            })
        }
        if (!online)
            return (<Navigate to="/landingPage" />);
        localStorage.setItem('lastFetch', myDate.toDateString() + " " + myDate.toTimeString());
        return (<Navigate to="/landingPage" />);
    }
}

export default appInformation;
